import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { loginUser } from "../../../../../redux/actions/authActions";
import { getSchoolDetails } from "../../../../../redux/actions/schoolsActions";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import validate from "validate.js";
import ResetPasswordModal from "../../ResetPasswordModal";

const constraints = (t) => ({
  name: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  password: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "48%",
    marginTop: theme.spacing(1),
    float: "left",
    marginRight: "10px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  emailDomain: {
    display: "flex",
    flexDirection: "row",
    marginTop: "0 !important",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  textField1: {
    marginTop: theme.spacing(2),
    marginRight: "10px",
  },
  titleName: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center",
    marginBottom: "72px",
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const { school, dispatch, history } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const { t } = useTranslation("common");

  const schema = constraints(t);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      name: "",
      password: "",
    },
    touched: {},
    errors: {},
    flag: true,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSignIn = (event) => {
    event.preventDefault();
    // debugger
    const { values } = formState;
    const email = `${values.name}${getWithStudentsSchoolDomain()}`;
    const data = { email: email, password: values.password };
    dispatch(loginUser({ user: data, history }));
  };

  const getWithStudentsSchoolDomain = () => {
    if (school.dataDetails && school.dataDetails.emailDomain) {
      return school.dataDetails.emailDomain;
    }
    return "";
  };

  const handleOnBlur = (event) => {
    // setFormState((formState) => ({
    //   ...formState,
    //   touched: {
    //     ...formState.touched,
    //     [event.target.name]: true,
    //   },
    // }));
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <div>
      <form className={classes.form} onSubmit={handleSignIn}>
        <Typography className={classes.titleName} variant="h1" gutterBottom>
          {t("login.form.title-partner")}
        </Typography>
        <div className={classes.emailDomain}>
          <TextField
            className={classes.textField}
            error={hasError("name")}
            fullWidth
            helperText={hasError("name") ? formState.errors.name[0] : null}
            margin="normal"
            label={t("login-school.form.inputs.email")}
            name="name"
            onChange={handleChange}
            type="text"
            value={formState.values.name}
            variant="outlined"
            autoFocus
            onBlur={handleOnBlur}
          />
        </div>
        <TextField
          className={classes.textField}
          error={hasError("password")}
          fullWidth
          helperText={hasError("password") ? formState.errors.password[0] : null}
          margin="normal"
          label={t("login-school.form.inputs.password")}
          name="password"
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          value={formState.values.password || ""}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onBlur={handleOnBlur}
        />
        <Button
          className={classes.submit}
          color="primary"
          disabled={!formState.isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t("login-school.form.connectBtn")}
        </Button>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={handleOpen}
        >
        {t("login-school.form.forgotpassword")}
        </Button>
      </form>
      <ResetPasswordModal open={open} setOpen={setOpen} />
  </div>
  );
};

const mapStateToProps = (state) => ({
  school: state.school,
});

export default connect(mapStateToProps)(Login);
