import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {useDispatch} from "react-redux";
import {createMultimediaInsuranceContractApi} from "../../redux/actions/InsuranceActions";
import { useHistory } from "react-router";
import LocalStorageService from "../../services/LocalStorageService";
import { updateCurrentContracts } from "../../redux/actions/contractsActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
}));

export const PaymentRedirectMultimedia = (props) => {
  const classes = useStyles();
  const history = useHistory(); 
  const [result, setResult] = useState(null);
  const [callApi, setCallApi] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!callApi){
      processUrl();
      setCallApi(true);
    }
  });

  const processUrl = () => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
        const service = LocalStorageService.getService();
        let multimediaContract = service.getMultimediaContract();
        multimediaContract = { ...multimediaContract, sessionStripe: query.get("session_id")}
        service.setMultimediaContract( multimediaContract );
        dispatch(updateCurrentContracts());
        dispatch(createMultimediaInsuranceContractApi({data: multimediaContract, history}));

    } else {
      history.push("/insurance/housing");
    }
  };

  return <div className={classes.root}>{result && result}</div>;
};
