import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography, useMediaQuery} from "@material-ui/core";
import { useSelector } from "react-redux";
import {Roles} from "../../common/Roles";

const useStyles = makeStyles((theme) => ({
  contentBox: {
    width:"100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    textAlign: "center",
    borderRadius: "25px",
    minHeight: "250px",
    [theme.breakpoints.between("xs", "md")]: {
      height: "200px",
      minHeight: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      "& h4":{
        fontSize: "14px",
      }
    },
  },
  contentFront: {
    width:"100%",
    height: "100%",
    background: "#2F6472",
    color: "white",
    zIndex: "100",
    position: "relative",
    transition: "transform .5s",
    padding: "30px",
  },
  contentBack: {
    width:"100%",
    height: "100%",
    background: "#8ECECE",
    color: "white",
    zIndex: "101",
    position: "relative",
    transition: "transform .5s",
    padding: "2%",
    display: "flex",
    overflowX: "hidden",
    overflowY: "auto",
  },
  centerBloc: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    "& svg":{
      fontSize: "3rem",
    },
    "& img":{
      fontSize: "3rem",
      width: "3.4rem",
      height: "3rem",
    }
  },
  textContent:{
    margin: "auto",
    color: "white",
  }
  //#8ECECE
}));

const RockerSwitch = ({Icon, title, text, index, backColor, isUnlockMouseOver }) => {
  const classes = useStyles();
  const [topFrontValue, setTopFrontValue] = useState("0%");
  const [frontColor] = useState("#2F6472");
  const [stateClick, setStateClick] = useState(false);
  const isMobile = useMediaQuery('(max-width:1280px)');
  const currentUser = useSelector((state) => state.auth.data);

  const showBackBox = () =>{
    setTopFrontValue("-100%");
  }
  const hiddenBackBox = () =>{
    setTopFrontValue("0%");
  }

  const onChangeView = () =>{
    if(topFrontValue === "0%" && (currentUser?.roles[0] === Roles.STUDENT_CLASSIC.value || isUnlockMouseOver)){
        showBackBox();
    }else{
        hiddenBackBox();
    }
    setStateClick(true);
  }
  const onChangeViewOnMouse = () =>{
    if(!isMobile && (currentUser?.roles[0] === Roles.STUDENT_CLASSIC.value || isUnlockMouseOver)){
      if(topFrontValue === "0%"){
        showBackBox();
      }else{
        hiddenBackBox();
      }
    }
  }
  return (
    <div className={classes.contentBox} onClick={onChangeView} onMouseOver={onChangeViewOnMouse} onMouseOut={onChangeViewOnMouse}>
        <div className={classes.contentFront} style={{background: frontColor, transform: "translateX(0) translateY("+ topFrontValue +")"}}>
          <div className={classes.centerBloc}>
            <Icon/>
            <Typography
                className={classes.wrapIcon}
                style={{textTransform: 'uppercase'}}
                variant="h4">
                {title} 
            </Typography>
          </div>
        </div>
        <div className={classes.contentBack} style={{background: backColor, transform: "translateX(0) translateY("+ topFrontValue +")"}}>
            <Typography
              className={classes.textContent}
              color="textPrimary"
              variant="h6">
              {text}
            </Typography>
        </div>
    </div>
  );
}

export default RockerSwitch;
