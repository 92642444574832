import * as actionTypes from './actionTypes'

export const appLoading = () => ({
  type: actionTypes.APP_LOADING,
})

export const appRedirect = () => ({
  type: actionTypes.APP_REDIRECT,
})

export const appSuccess = () => ({
  type: actionTypes.APP_SUCCESS,
})

export const appError = () => ({
  type: actionTypes.APP_ERROR,
})

export const appSuccessMessage = (messageText) => ({
  type: actionTypes.APP_SUCCESS_MESSAGE,
  payload: {active: true, message: messageText},
})

export const appLoad = () => (dispatch) => {
  dispatch(appLoading())
    .then((response) => {
      dispatch(appSuccess())
    })
    .catch((error) => {
      dispatch(appError(error))
    })
}
