import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Modal, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router"
import LocalStorageService from "../../services/LocalStorageService";
import PropTypes from 'prop-types';

const constraints = (t) => ({
  lastname: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  firstname: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  countryNationality: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  countryResidence: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  phone: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  mail: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  address: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  reason: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  hostOrganization: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
});


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 16px 0 16px',
    flexGrow: 1,
    marginLeft: '6%',
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  container: {
    display: 'flex',
    padding: '50px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  categoryTitle: {
    textTransform: 'inherit',
    color: '#2F6472',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  textName: {
    textTransform: 'uppercase',
    color: '#2F6472',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    padding: "20px",
  },
  textStudcorp: {
    textTransform: 'inherit',
    color: '#2F6472',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    padding: "20px",
    fontStyle: "italic",
  },
  styleBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    background: 'white',
    boxShadow: "24",
    p: "4",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80%",
    },
  },
}));

export const SuccessPage = ({ handleClose, open }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const service = LocalStorageService.getService();
  const currentUser = service.getUser();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={[classes.noPadding, classes.styleBox]} >
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <Typography variant="h2" className={classes.textName}>
                Merci {currentUser.name} !
              </Typography>
              <Typography variant="h4" className={classes.categoryTitle}>
                Ton formulaire est bien envoyé, tu vas recevoir ton attestation dans quelques heures par mail et elle sera disponible sur ton espace ;)
              </Typography>
              <Typography variant="h4" className={classes.textStudcorp}>
                L'équipe STUDCORP te souhaite une bonne aventure !
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>

  );
};

SuccessPage.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};