import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import { createBrowserHistory } from 'history';
import validate from 'validate.js';
import { validators } from './common';
import './App.css';
import { AppRouter } from "./AppRouter";
import {store} from "./Store";
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Loading from './common/Loading';
import 'react-perfect-scrollbar/dist/css/styles.css'
import './index.css'

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators
};

export function App() {

  return (
    <Provider store={ store }>
      <ThemeProvider theme={ theme }>
        <CssBaseline />
          <Router history={ browserHistory }>
            <AppRouter/>
          </Router>
      </ThemeProvider>
      <ReduxToastr timeOut={40000} />
      <Loading />
    </Provider>
  );
}
