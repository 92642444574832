import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Document } from "./Document";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  yearList: {
    marginTop: "5%",
  },
  yearLine: {
    backgroundColor: "#707070",
    height: "2px",
  },
  test: {
    width: "450px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    borderBottom: "1px solid",
  },
}));

export const DocumentsComponent = (props) => {
  const { documents, year } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const {t} = useTranslation("common");

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Grid container>
      <FormControl className={classes.formControl}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <Grid container className={classes.test}>
            <ListItem button className={classes.yearList} onClick={handleClick}>
              <ListItemText primary={t("docs.year") + " " + year} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Grid>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {documents.map((doc, i) => (
                <Document document={doc} key={i} />
              ))}
            </List>
          </Collapse>
        </List>
      </FormControl>
    </Grid>
  );
};
