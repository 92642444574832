import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

export const NotFound = () => {
  const classes = useStyles();
  const {t} = useTranslation('common');

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1">
          {t('404.title')}
        </Typography>
        <Typography variant="subtitle2">
          {t('404.subtitle')}
        </Typography>
        <img alt="Under development" className={classes.image} src="/images/undraw_page_not_found_su7k.svg"/>
      </div>
    </div>
  );
};

