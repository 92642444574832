import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {SchoolsToolbar} from "./components/SchoolsToolbar";
import {SchoolsTable} from "./components/SchoolsTable";
import {connect} from "react-redux";
import PropTypes, {string} from 'prop-types';
import { listSchoolsSuccess, listSchools} from "../../redux/actions/schoolsActions";
import { useHistory } from "react-router"
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    marginTop: "50px",
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SchoolsPage = (props) => {

  const classes = useStyles();

  const { schools, dispatch } = props;

  const history = useHistory();

  const {t} = useTranslation('common');

  useEffect(() => {
      dispatch(listSchools())
     }, []);

  const handleDelete = (schoolId) => {
    //  console.log("Delete schoolId: ", schoolId);
  }

  const handleEdit = (schoolId) => {
      // console.log("edit schoolId: ", schoolId);
      history.push(`/schools/${schoolId}`)
  }

    return (
    <div className={classes.root}>
        <SchoolsToolbar />
        <div className={classes.content}>
            {schools.data && schools.data.length && schools.data.length > 0 ?
            <SchoolsTable
                schools={schools.data}
                handleDelete={handleDelete}
                handleEdit={handleEdit} /> :
                <Typography variant="title">
                    {t('schoolManager.list.empty')}
                </Typography>
            }
         </div>
    </div>
  );
 };

SchoolsPage.propTypes = {
    listSchool: PropTypes.func,
    schools: PropTypes.object,
    history: PropTypes.object
};

const mapStateToProps = (state) => ({
    schools: state.school,
});

export default connect(mapStateToProps)(SchoolsPage);

