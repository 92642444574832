import React, { useState, useEffect } from 'react';
import { useRouteMatch, NavLink as RouterLink } from 'react-router-dom';
import { loginUser } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { getSchoolDetails } from "../../redux/actions/schoolsActions";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import validate from 'validate.js';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import { Toolbar } from '@material-ui/core';
import Footer from "../../components/layouts/Main/components/Footer";
import ResetPasswordModal from "./Components/ResetPasswordModal"
import Lang from "../../Lang";

const constraints = (t) => ({
  name: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  password: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  }
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paperC : {
    padding: "5%",
    marginTop: "40px",
    boxShadow: "13px 8px 15px 1px rgba(101,168,130,0.5)"
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.green,
    fontWeight: 'bold'
  },
  subtitle: {
    color: theme.palette.orange,
    fontWeight: 'bold'
  },
  titleName: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center"
  },
  subtitleName: {
    marginTop: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center"
  },
  emailDomain: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  textField1: {
    marginTop: theme.spacing(2),
    marginRight: "10px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: "18px"
  },
  logo: {
    height: "60px"
  }
}));

const SignInBySchool = props => {

  const { school, dispatch, history } = props;

  const classes = useStyles();

  const match = useRouteMatch();

  const {t} = useTranslation('common');

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const schema = constraints(t);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    flag: true
  });

  const processUrl = () => {
    if (formState.flag) {
      if (match.params) {
        const {schoolName} = match.params;
        dispatch(getSchoolDetails({name: schoolName, history}));
        formState.flag = false;
        setFormState(formState);
      } else {
        // history.push('/not-found');
      }
    }
  }

  useEffect(() => {
    processUrl();
    const errors = validate(formState.values, schema, {fullMessages: false});

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };
  // Méthode pour aller chercher le domaine de l'école
  // const getWithStudentsSchoolDomain = () => {
  //   if(school.dataDetails && school.dataDetails.emailDomain) {
  //     return school.dataDetails.emailDomain;
  //   }
  //   return  '';
  // }

  const handleSignIn = event => {
    event.preventDefault();
    //debugger
    const { values } = formState;
    // const email = `${values.name}${getWithStudentsSchoolDomain()}`;
    const email = `${values.name}`;
    const data = { email: email, password: values.password};
    dispatch(loginUser({ user: data, history }))
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const getWithSchoolName = () => {
    if (school.dataDetails && school.dataDetails.name) {
      return  ' X ' + school.dataDetails.name;
    }
    return  '';
  }

  const getWithStudentsSchoolName = () => {
    if (school.dataDetails && school.dataDetails.studentsSchoolName) {
      return  ' ' + school.dataDetails.studentsSchoolName;
    }
    return '';
  }

  return (
    <Container component="main" maxWidth="lg">
      <div style={{position: "absolute",right: "3px", top: "3px"}}>
        <Lang />
      </div>
      <Toolbar className={classes.headerContainer}>
        <RouterLink to="/">
          <img className={classes.logo}
                alt="logo"
                src="/images/logos/logo-blue.png"
          />
        </RouterLink>
        <div>
          <Typography className={classes.title}
                      variant="h1" >
                      {t('app.name')}{getWithSchoolName()}
          </Typography>
          <Typography className={classes.subtitle}
                      variant="h3"
                      gutterBottom>
                      {t('login-school.subtitle')}
          </Typography>
        </div>
        <div className={classes.space}/>
      </Toolbar>
      <div className={classes.paper}>
        <Paper elevation={5} className={classes.paperC}>
          <Typography className={classes.titleName}
                      variant="h1"
                      gutterBottom>
                      {t('login-school.form.title')}
                      {getWithStudentsSchoolName()}
          </Typography>
          <Typography className={classes.subtitleName}
                      variant="h1"
                      gutterBottom>
                      {t('login-school.form.subtitle')}
          </Typography>
          <form className={classes.form}  onSubmit={handleSignIn} >
            <div className={classes.emailDomain}>
              <TextField className={classes.textField1} error={hasError('name')}  fullWidth
                          helperText={ hasError('name') ? formState.errors.name[0] : null }
                          margin="normal"
                          label={t('login-school.form.inputs.email')}
                          name="name"
                          onChange={handleChange}
                          type="text"
                          value={ formState.values.name }
                          variant="outlined"
                          autoFocus/>
              {/* <TextField className={classes.textField} fullWidth
                          margin="normal"
                          label=""
                          name="domain"
                          type="text"
                          value={ getWithStudentsSchoolDomain()}
                          variant="outlined"
                          contentEditable={false} /> */}
            </div>
            <TextField className={classes.textField}  error={hasError('password')} fullWidth
                        helperText={ hasError('password') ? formState.errors.password[0] : null }
                        margin="normal"
                        label={t('login-school.form.inputs.password')}
                        name="password"
                        onChange={handleChange}
                        type={showPassword ? "text" : "password"}
                        value={formState.values.password || ''}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
            />
            <Button className={classes.submit} color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained" >
                    {t('login-school.form.connectBtn')}
            </Button>
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={handleOpen}
            >
            {t("login-school.form.forgotpassword")}
            </Button>
          </form>
          <ResetPasswordModal open={open} setOpen={setOpen} />
        </Paper>
      </div>
      <Footer/>
    </Container>
  );
};

SignInBySchool.propTypes = {
  schoolDetails: PropTypes.func,
  school: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  school: state.school,
});

export default connect(mapStateToProps)(SignInBySchool);
