import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Divider, Drawer, IconButton, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Profile, SidebarNav } from './components';
import { RouterPathApp} from '../../../../routers/RoutesPathApp';
import { useSelector, useDispatch} from 'react-redux';
import { validateAccessUser } from '../../../../../services/utils';
import LocalStorageService from "../../../../../services/LocalStorageService";
import InputIcon from '@material-ui/icons/Input';
import Grid from '@material-ui/core/Grid';
import { redirectAccountStripeManagement } from "../../../../../services/utils";
import { logoutUser } from '../../../../../redux/actions/authActions';
import { getSchoolByStudent } from "../../../../../redux/actions/schoolsActions";
import { updateCurrentContracts } from "../../../../../redux/actions/contractsActions";

import {
  offerList,
} from "../../../../../redux/actions/offersActions";
import { AccountCircle, Home, Laptop } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 266,
    [theme.breakpoints.up('xl')]: {
      height: '100%'
    }
  },
  buttonProfil: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "17px",
      textAlign: "left",
      "& svg": {
        marginRight: "12px",
      }
    },
  },
  signOutElement: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      position: "relative",
      bottom: "20px",
      fontSize: "17px", 
    },
},
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: '16px 0px 16px 0px',
    height: '2px',
    color: 'black',
    backgroundColor: '#707070',
  },
  divider2: {
    marginTop: '24px',
    marginBottom: '44px',
    height: '2px',
    color: 'black',
    backgroundColor: '#707070',
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  logoContainer: {
    width: '100%',
    textAlign: 'center'
  },
  logo: {
    height: '94px',
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const contracts = useSelector((state) => state.contracts);

  const classes = useStyles();
  const dispatch = useDispatch();

  const {t} = useTranslation('common');

  const currentUser = LocalStorageService.getUser();
  const userRole = currentUser.roles;
  const handleLogout = () => {
    dispatch(logoutUser());
  }
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true
  });
  const offerAvailableFilter = (item) => {
    if (item.keyText.includes("insurance") && currentUser.roles[0] === "ROLE_STUDENT_PARTNER") {
      if (item.keyText.includes("insurance") && JSON.parse(currentUser.offers).includes(item.value)) {
        return true;
      };
    }else{
      return true;
    };
  };

  useEffect(() => {
    dispatch(updateCurrentContracts());
  }, []);

  const redirectAccountManagement = (typeContract) => {
    redirectAccountStripeManagement({typeContract: typeContract}, dispatch);
  }

  const getPages = () => {
    let pages = [];
    Object.keys(RouterPathApp).map((key) => {
      RouterPathApp[key].title = t(RouterPathApp[key].keyText);
      if (validateAccessUser(RouterPathApp[key],currentUser.roles[0])) {
        if (RouterPathApp[key].items) {
          let items = [];
          RouterPathApp[key].items.forEach(item => {
            if (item.roles.some(role => role === currentUser.roles[0])) {
              if(offerAvailableFilter(item)){
                item.title = t(item.keyText);
                items.push(item);
              }
            };
          });
          RouterPathApp[key].items = items;
        }
        pages.push(RouterPathApp[key]);
      }
    });
    return pages;
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.logoContainer}>
          <RouterLink to="/">
            <img className={classes.logo}
              alt="logo"
              src="/images/logos/logo-blue.png"
            />
          </RouterLink>
        </div>
        <Divider className={classes.divider} />
        <Profile />
        {userRole != "ROLE_ADMIN" && <Divider className={classes.divider2} />}
        <SidebarNav className={classes.nav} pages={getPages()} />
        {!isDesktop ? (  
        <div>
          <Divider className={classes.divider} />
          <Grid container spacing={0}>
            <Grid item xs={12} style={{textAlign: 'center', marginBottom: "8px", fontWeight: "bold", fontSize: "20px", marginTop: "18px"}}>
              {t("navbar.notification.welcome")}{currentUser.name} !
            </Grid>
            {contracts.hasCurrentHomeContract && (
              <Grid item xs={12}>
                <IconButton
                  className={classes.buttonProfil}
                  onClick={() => redirectAccountManagement("home")}>
                <Home fontSize="small" /> {' ' + t("navbar.Information_about_my_payments_Home")}
                </IconButton>
              </Grid>
              )
            }
            {contracts.hasCurrentMultimediaContract && (
              <Grid item xs={12}>
                <IconButton
                  className={classes.buttonProfil}
                  onClick={() => redirectAccountManagement("multimedia")}>
                <Laptop fontSize="small" /> {' ' + t("navbar.Information_about_my_payments_Multimedia")}
                </IconButton>
              </Grid>
              )
            }
            <Grid item xs={12}>
              <IconButton
                className={classes.buttonProfil}
                style={{color: "#d80000"}}
                onClick={handleLogout}>
              <InputIcon /> {' ' + t("navbar.disconnection")}
              </IconButton>
            </Grid>
          </Grid>
          </div> 
          ) : null
          }
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
