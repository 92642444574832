import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import FlightIcon from "@material-ui/icons/Flight";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from '@material-ui/icons/Add';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Link as RouterLink } from 'react-router-dom';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router"
import PropTypes, { string } from 'prop-types';

import { PartnerShipLevel } from "../Schools/utils/PartnerShipLevel";
import {downloadContract, getPublicKey, listCountries} from "../../redux/actions/InsuranceActions";
import validate from "validate.js";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import {Roles} from "../../common/Roles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import { getSchoolByStudent } from "../../redux/actions/schoolsActions";


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 55px 0 55px',
    flexGrow: 1,
    backgroundColor: "white",
    height: "450px",
  },
  container: {
     display: 'flex',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly'
  },
  categoryTitle : {
     textTransform: 'uppercase',
     color :'#8ECECE',
     fontWeight: 'bold',
  },
  contentContainer: {
     marginTop: '6px',
     [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "0px!important",
      paddingTop: "10px!important",
      paddingBottom: "10px!important",
    },
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
  },
  planeIcon: {
    color :'#6eaff5',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    transform: 'rotate(45deg)',
    width: '40px',
    height: '40px'
   },
   locationIcon: {
    color :'#2F6472',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    width: '40px',
    height: '40px'
   },
   multimediaIcon: {
    color :'#8ECECE',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    width: '40px',
    height: '40px'
   },
   plusIcon: {
     color :'#23009A',
     marginRight: '1%'
   },

   wrapIcon : {
    verticalAlign: 'middle',
    display: 'inline-flex',
    fontWeight: 'bold',
    color: 'black'
   },
   selectInsurance: {
    width: '65%',
    top: '-20px',
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
    },
   },
   linkDownLoad: {
     width: '100%',
     display: 'flex',
     fontWeight: '700',
     textAlign: 'center',
     fontSize: '20px',
     letterSpacing: '0px',
     color: '#000000',
     opacity: '1',
     cursor: 'pointer'
   },
  linkDownloadDisabled: {
    width: '100%',
    display: 'flex',
    fontWeight: '700',
    textAlign: 'center',
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#8d8b8b',
    opacity: '1',
  },
   linkPlus: {
     width: '100%',
     display: 'flex',
     color: '#23009A',
     marginTop:'60px'
   },
   textPlus: {
    fontSize: '15px',
    marginTop: '8px'
   },
   paragraphContainer: {
    display: 'flex',
    width: '100%',
    height: '80%'
   },
   contentParagraphs: {
     display: 'flex',
     width: '100%',
     height: '100%',
     marginTop: '70px',
     flexDirection: 'row',
   },
   contentParagraphsColumn: {
     display: 'flex',
     width: '50%',
     height: '100%',
     flexDirection: 'column',
   },
   logo: {
     width: '80px',
     height: '25px',
     marginRight: '5px'
   },
   logobg: {
     width: "83px",
     minHeight: "44px",
     backgroundSize: "contain",
     backgroundRepeat: "no-repeat",
     backgroundPosition: "center left",
     marginTop: "3px",
     marginRight: "10px"
   },
   line1: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: '5px',
    marginBottom: '5px'
   },
   subtitle: {
     display: 'flex',
     color: 'black',
     fontWeight: '700',
     fontSize: '15px',
     lineHeight: '18px',
     alignSelf: "flex-end"
   },
   paragraph: {
     textAlign: 'left',
     font: 'normal normal normal',
     letterSpacing: '0px',
     color: '#000000',
     opacity: '1',
     fontSize: '13px'
   },
  textInput: {
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '1'
  },
  payContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: '20px'
  },
  priceText: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '30px',
    alignItems: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px',
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "0px!important",
      fontSize: "13px",
    },
},
  payButton: {
    width: '240px',
    color: '#2F6472',
    backgroundColor: 'white',
    fontSize: "20px",
    fontWeight: "bold"
  },
  dialogContent: {
    width: '500px'
  },
  dialogTitle: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '30px',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: '1',
    margin: '20px'
  },
  dialogSquare: {
    height: '200px',
    margin: '20px',
    padding: '10px'
  },
  dialogSquareContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '5px'
  },
  dialogContentCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogItemContent: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '15px',
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '20px'
  },
  dialogSubTitle: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '16px',
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '10px'
  },
  itemResponsivePickAndGo: {
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "8px",
      borderTop: "solid #8ECECE",    
      "& h4": {
        fontoSize: "26px",
      },
    },
  },
  buttonLink: {
    textAlign: 'center',
    "& a": {
      padding: "23px",
      background: "#28A745",
      color: "white",
      fontSize: "22px",
      fontWeight: "400",
    }
  },
  estimationPrice: {
    fontSize: "23px",
    color: "#28A745",
    marginLeft: "5px",
  }
}));

export const HealthMobilityEstimationPage = (props) => {

  const classes = useStyles();

  const countries = {
    data: [
      { name: "Afrique du sud", zone: 2 },
      { name: "Alaska", zone: 3 },
      { name: "Albanie", zone: 2 },
      { name: "Algérie", zone: 2 },
      { name: "Allemagne", zone: 1 },
      { name: "Andorre", zone: 2 },
      { name: "Angola", zone: 2 },
      { name: "Anguilla", zone: 2 },
      { name: "Antarctique", zone: 2 },
      { name: "Antigua et Barbuda", zone: 2 },
      { name: "Arabie Saoudite", zone: 2 },
      { name: "Argentine", zone: 2 },
      { name: "Arménie", zone: 2 },
      { name: "Aruba", zone: 2 },
      { name: "Australie", zone: 2 },
      { name: "Autriche", zone: 1 },
      { name: "Azerbaïdjan", zone: 2 },
      { name: "Bahamas", zone: 3 },
      { name: "Bahreïn", zone: 2 },
      { name: "Bangladesh", zone: 2 },
      { name: "Barbade", zone: 2 },
      { name: "Belgique", zone: 1 },
      { name: "Bélize", zone: 2 },
      { name: "Bénin", zone: 2 },
      { name: "Bhoutan", zone: 2 },
      { name: "Biélorussie", zone: 2 },
      { name: "Birmanie", zone: 2 },
      { name: "Bolivie", zone: 2 },
      { name: "Bonaire", zone: 2 },
      { name: "Bosnie-Herzégovine", zone: 2 },
      { name: "Botswana", zone: 2 },
      { name: "Brésil", zone: 2 },
      { name: "Brunéi", zone: 2 },
      { name: "Bulgarie", zone: 1 },
      { name: "Burkina Faso", zone: 2 },
      { name: "Burundi", zone: 2 },
      { name: "Cambodge", zone: 2 },
      { name: "Cameroun", zone: 2 },
      { name: "Canada", zone: 3 },
      { name: "Canal de Panama", zone: 2 },
      { name: "Cap Vert", zone: 2 },
      { name: "Centre-Afrique", zone: 2 },
      { name: "Chili", zone: 2 },
      { name: "Chine", zone: 2 },
      { name: "Chypre", zone: 1 },
      { name: "Colombie", zone: 2 },
      { name: "Comoros", zone: 2 },
      { name: "Congo", zone: 2 },
      { name: "Corée du Sud", zone: 2 },
      { name: "Costa Rica", zone: 2 },
      { name: "Côte d'Ivoire", zone: 2 },
      { name: "Croatie", zone: 1 },
      { name: "Cuba", zone: 2 },
      { name: "Curaçao", zone: 2 },
      { name: "Danemark", zone: 1 },
      { name: "Djibouti", zone: 2 },
      { name: "Dominique", zone: 2 },
      { name: "Egypte", zone: 2 },
      { name: "El Salvador", zone: 2 },
      { name: "Emirats arabes unis", zone: 2 },
      { name: "Equateur", zone: 2 },
      { name: "Erythrée", zone: 2 },
      { name: "Espagne", zone: 1 },
      { name: "Estonie", zone: 1 },
      { name: "Etats fédérés de Micronésie", zone: 2 },
      { name: "Etats-Unis", zone: 3 },
      { name: "Ethiopie", zone: 2 },
      { name: "Fidji", zone: 2 },
      { name: "Finlande", zone: 1 },
      { name: "France", zone: 4},
      { name: "Gabon", zone: 2 },
      { name: "Gambie", zone: 2 },
      { name: "Georgie", zone: 2 },
      { name: "Ghana", zone: 2 },
      { name: "Gibraltar", zone: 2 },
      { name: "Grèce", zone: 1 },
      { name: "Grenade", zone: 2 },
      { name: "Groenland", zone: 2 },
      { name: "Guadeloupe", zone: 1 },
      { name: "Guam", zone: 2 },
      { name: "Guatémala", zone: 2 },
      { name: "Guinée", zone: 2 },
      { name: "Guinée Bissau", zone: 2 },
      { name: "Guinée Equatoriale", zone: 2 },
      { name: "Guyana", zone: 2 },
      { name: "Guyane Française", zone: 1 },
      { name: "Haïti", zone: 2 },
      { name: "Hawaï", zone: 3 },
      { name: "Honduras", zone: 2 },
      { name: "Hong Kong", zone: 3 },
      { name: "Hongrie", zone: 1 },
      { name: "Iles Bermudes", zone: 2 },
      { name: "Iles Caïmans", zone: 2 },
      { name: "Iles Cook", zone: 2 },
      { name: "Iles des Açores", zone: 2 },
      { name: "Iles Falkland", zone: 2 },
      { name: "Iles Mariannes du Nord", zone: 2 },
      { name: "Iles Marshall", zone: 2 },
      { name: "Iles Palau", zone: 2 },
      { name: "Iles Salomon", zone: 2 },
      { name: "Iles Turques et Caiques", zone: 2 },
      { name: "Iles Vierges Américaines", zone: 2 },
      { name: "Iles Vierges Britanniques", zone: 2 },
      { name: "Inde", zone: 2 },
      { name: "Indonésie", zone: 2 },
      { name: "Irak", zone: 2 },
      { name: "Iran", zone: 2 },
      { name: "Irlande", zone: 1 },
      { name: "Islande", zone: 2 },
      { name: "Israël", zone: 2 },
      { name: "Italie", zone: 1 },
      { name: "Jamaïque", zone: 2 },
      { name: "Japon", zone: 3 },
      { name: "Jersey", zone: 2 },
      { name: "Jordanie", zone: 2 },
      { name: "Kazakhstan", zone: 2 },
      { name: "Kénya", zone: 2 },
      { name: "Kirghizstan", zone: 2 },
      { name: "Kiribati", zone: 2 },
      { name: "Koweït", zone: 2 },
      { name: "Laos", zone: 2 },
      { name: "Lésotho", zone: 2 },
      { name: "Lettonie", zone: 1 },
      { name: "Liban", zone: 2 },
      { name: "Libéria", zone: 2 },
      { name: "Liechtenstein", zone: 2 },
      { name: "Lituanie", zone: 1 },
      { name: "Luxembourg", zone: 1 },
      { name: "Macao", zone: 2 },
      { name: "Macédoine", zone: 2 },
      { name: "Madagascar", zone: 2 },
      { name: "Madère", zone: 1 },
      { name: "Malaisie", zone: 2 },
      { name: "Malawi", zone: 2 },
      { name: "Maldives", zone: 2 },
      { name: "Mali", zone: 2 },
      { name: "Malte", zone: 1 },
      { name: "Maroc", zone: 2 },
      { name: "Martinique", zone: 1 },
      { name: "Maurice", zone: 2 },
      { name: "Mauritanie", zone: 2 },
      { name: "Mayotte", zone: 1 },
      { name: "Mexique", zone: 2 },
      { name: "Moldavie", zone: 2 },
      { name: "Monaco", zone: 2 },
      { name: "Mongolie", zone: 2 },
      { name: "Monténegro", zone: 2 },
      { name: "Montserrat", zone: 2 },
      { name: "Mozambique", zone: 2 },
      { name: "Namibie", zone: 2 },
      { name: "Nauru", zone: 2 },
      { name: "Népal", zone: 2 },
      { name: "Neutre Irak-Arabie Saoud.", zone: 2 },
      { name: "Nicaragua", zone: 2 },
      { name: "Niger", zone: 2 },
      { name: "Nigéria", zone: 2 },
      { name: "Niue", zone: 2 },
      { name: "Norvège", zone: 1 },
      { name: "Nouvelle Calédonie", zone: 1 },
      { name: "Nouvelle Zélande", zone: 2 },
      { name: "Oman", zone: 2 },
      { name: "Ouganda", zone: 2 },
      { name: "Ouzbékistan", zone: 2 },
      { name: "Pakistan", zone: 2 },
      { name: "Panama", zone: 2 },
      { name: "Papouasie-Nouvelle-Guinée", zone: 2 },
      { name: "Paraguay", zone: 2 },
      { name: "Pays-Bas", zone: 1 },
      { name: "Pérou", zone: 2 },
      { name: "Philippines", zone: 2 },
      { name: "Pologne", zone: 1 },
      { name: "Polynésie Française", zone: 1 },
      { name: "Porto Rico", zone: 2 },
      { name: "Portugal", zone: 1 },
      { name: "Qatar", zone: 2 },
      { name: "République démocratique du Congo", zone: 2 },
      { name: "République Dominicaine", zone: 2 },
      { name: "République Tchèque", zone: 1 },
      { name: "Réunion", zone: 1 },
      { name: "Roumanie", zone: 1 },
      { name: "Royaume-Uni", zone: 2 },
      { name: "Russie", zone: 2 },
      { name: "Rwanda", zone: 2 },
      { name: "Sahara Occidental", zone: 2 },
      { name: "Saint Kitts et Nevis", zone: 2 },
      { name: "Saint-Barthelemy", zone: 1 },
      { name: "Saint-Martin", zone: 1 },
      { name: "Saint-Vincent", zone: 2 },
      { name: "Sainte-Lucie", zone: 2 },
      { name: "Samoa Orientale", zone: 2 },
      { name: "Sao Tomé", zone: 2 },
      { name: "Sénégal", zone: 2 },
      { name: "Serbie", zone: 2 },
      { name: "Seychelles", zone: 2 },
      { name: "Sierra Léone", zone: 2 },
      { name: "Singapour", zone: 3 },
      { name: "Slovaquie", zone: 1 },
      { name: "Slovénie", zone: 1 },
      { name: "Sri Lanka", zone: 2 },
      { name: "Suède", zone: 1 },
      { name: "Suisse", zone: 3 },
      { name: "Surinam", zone: 2 },
      { name: "Swaziland", zone: 2 },
      { name: "Tadjikistan", zone: 2 },
      { name: "Taïwan", zone: 2 },
      { name: "Tanzanie", zone: 2 },
      { name: "Tchad", zone: 2 },
      { name: "Thaïlande", zone: 2 },
      { name: "Togo", zone: 2 },
      { name: "Tonga", zone: 2 },
      { name: "Trinidad et Tobago", zone: 2 },
      { name: "Tunisie", zone: 2 },
      { name: "Turkménistan", zone: 2 },
      { name: "Turks et Caicos", zone: 2 },
      { name: "Turquie", zone: 2 },
      { name: "Tuvalu", zone: 2 },
      { name: "Ukraine", zone: 2 },
      { name: "Uruguay", zone: 2 },
      { name: "Vanuatu", zone: 2 },
      { name: "Vatican", zone: 2 },
      { name: "Vénézuela", zone: 2 },
      { name: "Vietnam", zone: 2 },
      { name: "Zambie", zone: 2 },
      { name: "Zimbabwé", zone: 2 },
    ]
  }
  const {dispatch} = props;

  const {t} = useTranslation('common');


  const history = useHistory();

  const [value, setValue] = useState([new Date(), new Date()]);

  const onChange = (event) => {
    setValue(event);
  }

  const [countrySelected, setCountry] = useState('');

  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCountry(event.target.value);
    getPriceBySelectionCountryAndDateRange(event.target.value);
  };

  useEffect(() => {
    if (!(countries.data && countries.data.length)) {
      dispatch(listCountries());
    }
  }, []);

  const enabledByConditional = () => {
    return ((countrySelected !== '') && (value !== null && value[0] !== undefined && value[1] !== undefined));
  }

  const getPriceZone = (data) => {
    if(data.zone == 1) {
      if(data.month == 1) {
        setPrice(27);
      } else if (data.month == 2) {
        setPrice(55);
      } else if (data.month == 3) {
        setPrice(80);
      } else if (data.month == 4) {
        setPrice(105);
      } else if (data.month == 5) {
        setPrice(125);
      } else if (data.month == 6) {
        setPrice(140);
      } else if (data.month == 7) {
        setPrice(150);
      } else if (data.month == 8) {
        setPrice(165);
      } else if (data.month == 9) {
        setPrice(189);
      } else if (data.month == 10) {
        setPrice(190);
      } else if (data.month == 11) {
        setPrice(199);
      } else if (data.month == 12) {
        setPrice(220);
      }
    }
    if(data.zone == 2) {
      if(data.month == 1) {
        setPrice(38);
      } else if (data.month == 2) {
        setPrice(76);
      } else if (data.month == 3) {
        setPrice(111);
      } else if (data.month == 4) {
        setPrice(144);
      } else if (data.month == 5) {
        setPrice(180);
      } else if (data.month == 6) {
        setPrice(195);
      } else if (data.month == 7) {
        setPrice(225);
      } else if (data.month == 8) {
        setPrice(255);
      } else if (data.month == 9) {
        setPrice(285);
      } else if (data.month == 10) {
        setPrice(320);
      } else if (data.month == 11) {
        setPrice(350);
      } else if (data.month == 12) {
        setPrice(380);
      }
    }
    if(data.zone == 3) {
      if(data.month == 1) {
        setPrice(51);
      } else if (data.month == 2) {
        setPrice(98);
      } else if (data.month == 3) {
        setPrice(147);
      } else if (data.month == 4) {
        setPrice(192);
      } else if (data.month == 5) {
        setPrice(236);
      } else if (data.month == 6) {
        setPrice(282);
      } else if (data.month == 7) {
        setPrice(329);
      } else if (data.month == 8) {
        setPrice(395);
      } else if (data.month == 9) {
        setPrice(425);
      } else if (data.month == 10) {
        setPrice(460);
      } else if (data.month == 11) {
        setPrice(499);
      } else if (data.month == 12) {
        setPrice(530);
      }
    }  
    if(data.zone == 4) {
      if(data.month == 1) {
        setPrice(49);
      } else if (data.month == 2) {
        setPrice(98);
      } else if (data.month == 3) {
        setPrice(147);
      } else if (data.month == 4) {
        setPrice(196);
      } else if (data.month == 5) {
        setPrice(245);
      } else if (data.month == 6) {
        setPrice(294);
      } else if (data.month == 7) {
        setPrice(343);
      } else if (data.month == 8) {
        setPrice(392);
      } else if (data.month == 9) {
        setPrice(372);
      } else if (data.month == 10) {
        setPrice(408);
      } else if (data.month == 11) {
        setPrice(444);
      } else if (data.month == 12) {
        setPrice(480);
      }
    } 
  }

  const getPriceBySelectionCountryAndDateRange = (selected) => {

    const selection = selected === '' ? countrySelected : selected;

    if ( selection !== '' && (value !== null && value[0] !== undefined && value[1] !== undefined)) {
        const countryObj = countries.data.find( country => country.name === selection );
        let monthsCount = moment( value[1]).diff(moment( value[0]), 'months', true);
        monthsCount =  parseInt(monthsCount) + 1;
        getPriceZone({ zone: countryObj.zone, month: monthsCount, history });
      }
  }


  useEffect(() => {
    getPriceBySelectionCountryAndDateRange('');
  }, [value]);


  return (
      <div className={classes.root}>
        <Grid container justify="left" alignItems="center" spacing={8}>
            <Grid item xs={12} sm={6} align="left" className={classes.contentContainer}>
              <div className={classes.itemContent}>
                <Typography  className={classes.wrapIcon} variant="h4">
                  <FlightIcon className={classes.planeIcon} />
                </Typography>
                  <DateRangePicker className={classes.textInput}
                    onChange={onChange}
                    value={value}
                    format={'dd/MM/yyyy'}
                    rangeDivider= {t('insurance.health-mobility.rangeDivider')}
                    locale={'fr'}
                    calendarIcon={null}
                    minDate={new Date()}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear()+1))}
                    />
              </div>

              <div className={classes.itemContent}>
                <Typography
                  className={classes.wrapIcon}
                  style={{textTransform: 'uppercase'}}
                  variant="h4">
                  <LocationOnIcon className={classes.locationIcon} />
                </Typography>
                <FormControl className={classes.selectInsurance}>
                    <InputLabel htmlFor="grouped-select" style={{fontSize: "20px"}}>{t('insurance.health-mobility.selectCountry')}</InputLabel>
                    <Select defaultValue="" id="grouped-select" className={classes.textInput}
                          name="countriesSelect"
                          value={countrySelected}
                          onChange={handleChange}>
                          { countries.data && countries.data.length && countries.data.length > 0 ?
                            countries.data.map( (country, index) => (
                            <MenuItem key={index} value={country.name}>
                              {country.name}
                            </MenuItem>
                            )) :  <MenuItem> not country </MenuItem>
                          }
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} align="left" className={classes.contentContainer}>
              <div className={classes.payContainer}>
                <div className={classes.priceText}>
                {t('insurance.cost')} : <span className={classes.estimationPrice} >{ price == null ? "" : price + "€"} </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} align="left" className={classes.buttonLink}>
              <RouterLink target="_blank" to="/login/classic" >
                {t('insurance.go')}
              </RouterLink>
            </Grid>
       </Grid>
      </div>
  );
 };

 HealthMobilityEstimationPage.propTypes = {
    history: PropTypes.object,
    listCountries: PropTypes.func,
    getSchoolByStudent: PropTypes.func,
    countries: PropTypes.object,
    school: PropTypes.object,
};

const mapStateToProps = (state) => ({
  countries: state.insurance,
  school: state.school
});

export default connect(mapStateToProps)(HealthMobilityEstimationPage);
