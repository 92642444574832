import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { loginUser } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";

import {useTranslation} from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import Footer from "../../components/layouts/Main/components/Footer";

const constraints = (t) => ({
  email: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
    email: true
  },
  password: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  }
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      textAlign: "center",
      marginTop: "18px"
    },
    logo: {
      height: "60px"
}
}));

export const SignIn = props => {
  const { history } = props;

  const classes = useStyles();
  const dispatch = useDispatch()

  const {t} = useTranslation('common');
  const schema = constraints(t);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    const { values } = formState
    dispatch(loginUser({ user: values, history }))
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
      <Container component="main" maxWidth="lg">
          <Toolbar className={classes.headerContainer}>
            <RouterLink to="/">
              <img className={classes.logo}
                   alt="logo"
                   src="/images/logos/logo-blue.png"
              />
            </RouterLink>
          </Toolbar>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar} >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h3" margin="normal">
              {t('login.form.title')}
            </Typography>
            <Typography component="h1" variant="h5" margin="normal">
              {t('login.form.subtitle')}
            </Typography>

              <form className={classes.form}  onSubmit={handleSignIn} >

                <TextField className={classes.textField} error={hasError('email')}  fullWidth
                  helperText={ hasError('email') ? formState.errors.email[0] : null }
                  margin="normal"
                  label={t('login.form.inputs.email')}
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                  autoFocus
                />
                <TextField className={classes.textField}  error={hasError('password')} fullWidth
                  helperText={ hasError('password') ? formState.errors.password[0] : null }
                  margin="normal"
                  label={t('login.form.inputs.password')}
                  name="password"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  value={formState.values.password || ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button  className={classes.submit} color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained" >
                  {t('login.form.connectBtn')}
                </Button>
                {/* <Typography color="textSecondary" variant="body1" >
                  Vous n'avez pas de compte ?{' '}
                  <Link component={RouterLink} to="" variant="h6">
                    S'inscrire
                  </Link>
                </Typography> */}
              </form>
        </div>
        </Container>
        <Footer/>
      </Container>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};
