import * as actionTypes from '../actions/actionTypes'

const initialState = {
   students: [],
}

export const studentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STUDENT_LIST_SUCCESS:
            const students = action.payload
            return { ...state, students }
        case actionTypes.STUDENT_LIST_FAILED:
            return { ...state, students: [], error: action.payload }
        default:
            return state
    }
}

