import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
// import { SearchInput } from '../../../../components';
import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

export const SchoolsToolbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();
  const {t} = useTranslation('common');

  const handleClick = () => {
    history.push('/schools/create');
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/*<Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>*/}
        <Button color="primary" variant="contained" onClick={ handleClick } >
          {t('schoolManager.addSchool')}
        </Button>
      </div>
      {/*<div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search school"
        />
      </div>*/}
    </div>
  );
};

SchoolsToolbar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object
};


