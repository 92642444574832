import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardMedia, Button } from '@material-ui/core';
import LaptopIcon from '@material-ui/icons/Laptop';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import Avatar from '@material-ui/core/Avatar';
import LocalStorageService from "../../../../services/LocalStorageService";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 250,
    margin: 10,
    borderRadius: 0
  },
  media: {
    height: 160,
    opacity: 0.6
  },
  content: {
    padding: '0 16px',
    textAlign: 'center'
  },
  avatar: {
    backgroundColor: '#fff',
    position: 'relative',
    top: -17,
    left: 90
  },
  text: {
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'uppercase',
    padding: '25px 0 30px 0'
  },
  CardContent:{
    width: "285px",
    [theme.breakpoints.down("md")]: {
      width: "26%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .MuiButton-label":{
      height: "95%",
    }
  }
}));

const getCardIcon = () => ({
  smartphone: <SmartphoneIcon />,
  laptop: <LaptopIcon />,
});

export const CardLink = props => {
  const { text, image,onClick, color, icon, displayForCS } = props;
  const classes = useStyles();
  const currentUser = LocalStorageService.getUser();

  return (
    <Button className={classes.CardContent} onClick={onClick} style={{display: /*currentUser && currentUser.roles.length > 0 && currentUser.roles[0] == "ROLE_STUDENT_CLASSIC" ? displayForCS : */ "block" }} >
      <Card className={classes.root} style={{boxShadow: "15px 5px 50px -15px"+color, display: "block", height: "95%"}} >
        <CardMedia className={classes.media} image={image} />
        <CardContent className={classes.content}>
          <Avatar className={classes.avatar} style={{color: color}}>
            {getCardIcon()[icon]}
          </Avatar>
          <Typography className={classes.text} style={{color: color}}>
            {text}
          </Typography>
        </CardContent>
      </Card>
    </Button>
  );
};
