import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { Language } from './Language';
import LocalStorageService from "./services/LocalStorageService";

const Lang = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);
    const service = LocalStorageService.getService();


    let changeLanguage = (event) => {
        let language = event.target.value;
        
        switch (language) {
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN);
                service.setLanguage(Language.EN);
                break;
            case Language.CH:
                setLang(Language.CH);
                i18n.changeLanguage(Language.CH);
                service.setLanguage(Language.CH);
                break;
            case Language.AR:
                setLang(Language.AR);
                i18n.changeLanguage(Language.AR);
                service.setLanguage(Language.AR);
                break;
            case Language.FR:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                service.setLanguage(Language.FR);
                break;
            default:
                break;
        }
    }

    useEffect(() => {

        if(service.getLanguage() === null){
            switch (true) {
                case window.navigator.language.includes("en"):
                    setLang(Language.EN);
                    i18n.changeLanguage(Language.EN);
                    break;
                case window.navigator.language.includes("cn"):
                    setLang(Language.CH);
                    i18n.changeLanguage(Language.CH);
                    break;
                case window.navigator.language.includes("ar"):
                    setLang(Language.AR);
                    i18n.changeLanguage(Language.AR);
                    break;
                case window.navigator.language.includes("fr"):
                    setLang(Language.FR);
                    i18n.changeLanguage(Language.FR);
                    break;
                case window.navigator.language.includes("EN"):
                    setLang(Language.EN);
                    i18n.changeLanguage(Language.EN);
                    break;
                case window.navigator.language.includes("CN"):
                    setLang(Language.CH);
                    i18n.changeLanguage(Language.CH);
                    break;
                case window.navigator.language.includes("AR"):
                    setLang(Language.AR);
                    i18n.changeLanguage(Language.AR);
                    break;
                case window.navigator.language.includes("FR"):
                    setLang(Language.FR);
                    i18n.changeLanguage(Language.FR);
                    break;
                default:
                    break;
            }
        }else{
            switch (service.getLanguage()) {
                case "en":
                    setLang(Language.EN);
                    i18n.changeLanguage(Language.EN);
                    break;
                case "ch":
                    setLang(Language.CH);
                    i18n.changeLanguage(Language.CH);
                    break;
                case "ar":
                    setLang(Language.AR);
                    i18n.changeLanguage(Language.AR);
                    break;
                case "fr":
                    setLang(Language.FR);
                    i18n.changeLanguage(Language.FR);
                    break;
                case"EN":
                    setLang(Language.EN);
                    i18n.changeLanguage(Language.EN);
                    break;
                case "CN":
                    setLang(Language.CH);
                    i18n.changeLanguage(Language.CH);
                    break;
                case "AR":
                    setLang(Language.AR);
                    i18n.changeLanguage(Language.AR);
                    break;
                case "FR":
                    setLang(Language.FR);
                    i18n.changeLanguage(Language.FR);
                    break;
                default:
                    break;
            }
        }
      }, []);
 
    return (
        <div >
            <div>
                <select value={lang} name="language" onChange={changeLanguage}>
                    <option value={Language.FR}>Francais</option>
                    <option value={Language.EN}>English</option>
                    <option value={Language.CH}>中国人</option>
                    <option value={Language.AR}>العربية</option>
                </select>
            </div>
        </div>
    )
}
 
export default Lang;