import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/styles";
import { Typography, Grid, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import validate from "validate.js";
import MenuItem from "@material-ui/core/MenuItem";
import AutoCompleteAddress from "../../components/AutoCompleteAddress/AutoCompleteAddressHomePath";
import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import thief from "../../assets/images/thief-white.png";
import SecurityIcon from '@material-ui/icons/Security';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import PersonIcon from '@material-ui/icons/Person';
import BugReportIcon from '@material-ui/icons/BugReport';
import storm from "../../assets/images/storm-white.png";
import OpacityIcon from '@material-ui/icons/Opacity';
import window from "../../assets/images/window-white.png";
import LocalStorageService from "../../services/LocalStorageService";
import { isAuthenticated, redirectPaymentPageHome } from "../../services/utils";
import {
  getPriceHomeInsurance,
  createAccountApi,
  createHouseInsuranceContractApi, finishDigitalFirm
} from "../../redux/actions/InsuranceActions";
import tableau_garanties from "../../assets/pdfs/tableau_garanties.pdf";
import tableau_garanties_15 from "../../assets/pdfs/tableau_garanties_15.pdf";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import RockerSwitch from "../../components/RockerSwitch/rockerSwitch";
import MultimediaOption from "../MultimediaInsurance/MultimediaInsuranceOption";
import HeaderHouseInsurance from "./components/HeaderHouseInsurance";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import moment from "moment";



const contraintsStep1 = (t) => ({
  address: {
    presence: { allowEmpty: false, message: t("validation.field.required") }
  }
})
const IconWindow = (props) => (
  <img src={window} alt="window" width="30" height="30" {...props} />

);

const IconStorm = (props) => (
  <img src={storm} alt="storm" width="30" height="30" {...props} />

);

const IconThief = (props) => (
  <img src={thief} alt="thief" width="30" height="30" {...props} />

);

const constraints = (t) => ({
  mainAccount: {
    firstName: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
    lastName: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
    email: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
      format: {
        pattern: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
        message: t("validation.field.email.incorrect"),
      },
    },
    password: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
      length: {
        minimum: 8,
        message: t("validation.field.password.minimun"),
      },
      format: {
        pattern: "^[ A-Za-z0-9_!?()@.\/#&+-]*$",
        message: t("validation.field.password.incorrect"),
      },
    },
    passwordConfirm: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
      equality: {
        attribute: "password",
        message: t("validation.field.password.must.match"),
        comparator: function (v1, v2) {
          return v1 === v2;
        },
      },
    },
    acceptTerm: {
      presence: {
        message: t("validation.field.required"),
      },
      inclusion: {
        within: [true],
        message: "^You need accept term",
      },
    },
    gender: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
    birthday: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
  },
  address: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  rooms: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  roomates: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  roomatesInfos: {
    firstName: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
    lastName: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
    birthday: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
    civility: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  root2: {
    padding: "20px"
  },
  paper: {
    marginTop: theme.spacing(0),
    // display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  paperC: {
    marginTop: "40px",
  },
  stepTitle: {
    textTransform: "uppercase",
    color: "#2F6472",
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      fontSize: "18px",
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  stepOneSutitle: {
    color: "#2F6472",
    fontStyle: "italic",
  },
  square: {
    border: "1px solid #2F6472",
  },
  icon: {
    width: 35,
    height: 35,
  },
  squareContainer: {
    width: 128,
    height: 128,
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    paddingTop: "10px"
  },
  break: {
    flexBasis: "100%",
    height: 0
  },
  squareLineTwo: {
    marginTop: "0.7%",
  },
  formText: {
    color: "#2F6472",
  },
  priceContainer: {
    marginTop: "2%",
  },
  incidentTable: {
    marginTop: "2%",
  },
  accountCreationOneLineContainer: {
    marginTop: "10px"
  },
  stepFourDivider: {
    backgroundColor: "#2F6472",
    height: "5px",
    marginTop: "20px",
    marginBottom: "20px"
  },
  categoryTitle: {
    color: "#2F6472",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginBottom: "45px",
  },
  formItem: {
    marginTop: "10px"
  },
  formCTA: {
    marginTop: "20px"
  },
  error: {
    color: "red"
  },
  stepSubtitle: {
    fontSize: "22px"
  },
  stepTwoSubtitle: {
    color: "#2F6472",
    fontStyle: "italic",
    textAlign: "center",
    width: "100%",
    marginTop: "10px"
  },
  step2Grid: {
    width: "100%"
  },
  step2Submit: {
    width: "48%"
  },
  step2Submit2: {
    width: "48%",
    marginLeft: "2%"
  },
  step2SubmitContainer: {
    marginTop: "20px"
  },
  datePickerInline: {
    marginTop: "20px",
    marginLeft: "10px !important",
    "& .MuiTextField-root": {
      margin: 0
    },
    "& .MuiInputBase-root": {
      padding: 0,
      margin: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10
      },
      "& .MuiInputBase-input": {
        padding: 15,
        paddingLeft: 0
      }
    }
  },
  datePickerContainer: {
    margin: "0px !important"
  },
  image: {
    width: "60px"
  },
  guaranteeTooltip: {
    fontSize: "11px !important"
  },
  ShowPricePerRoomate: {
    display: "block"
  },
  HidePricePerRoomate: {
    display: "none"
  },
  submitstep2: {
    width: "100%"
  },
  roomateFormContainer: {
    marginTop: "20px"
  },
  infoOnRoomateTitle: {
    marginBottom: "10px"
  },
  textFieldStep3: {
    marginTop: "10px"
  },
  submitStep3: {
    marginTop: "20px"
  },
  accountCreationContainer: {
    marginTop: "10px"
  },
  accountCreationGender: {
    display: "block",
    paddingTop: "16px",
    "& svg": {
      fontSize: "16px",
    }
  },
  helperError: {
    marginTop: "-10px",
    marginLeft: "14px",
  },
  containerStepHouseInsurance: {
    padding: "2%",
  }

}));



export const HouseInsurance = (props) => {

  const classes = useStyles();
  const { history } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation("common");

  const schema = constraints(t);

  const location = useLocation();

  useEffect(() => {
    setStep(location.search && location.search.split("=").length > 0 &&
      !isNaN(parseInt(location.search.split("=")[1])) ?
      checkIfEnableStep5() ? 5 : (parseInt(location.search.split("=")[1]) > data.maxStep ? data.maxStep :
        parseInt(location.search.split("=")[1])) : 1);
  }, [location]);

  const checkIfEnableStep5 = () => {
    const service = LocalStorageService.getService();
    return (parseInt(location.search.split("=")[1])) === 5 && isAuthenticated() && service.getHouseContract() !== undefined;
  }

  const currentUser = LocalStorageService.getUser();

  const [data, setData] = useState({
    stepOne: {
      rooms: 0,
      roommates: 0,
    },
    address: {},
    haveHomeContract: false,
    coverDate: new Date(),
    stepTwo: {
      paymentMethod: "mensuel",
      price: 0,
      pricePerRoomate: 0,
    },
    mainAccount: {
      firstName: "",
      lastName: "",
      birthDate: new Date(),
      phoneNumber: "",
      gender: "",
      email: "",
      password: "",
      passwordConfirm: "",
      acceptTerm: false,
    },
    roommatesInfos: {},
    maxStep: 1
  });

  const [step, setStep] = useState(location.search && location.search.split("=").length > 0 &&
    !isNaN(parseInt(location.search.split("=")[1])) ? (parseInt(location.search.split("=")[1]) > data.maxStep ?
      data.maxStep : parseInt(location.search.split("=")[1])) : 1);

  const insurancePrice = useSelector((state) => state.houseInsurance);

  const [isConnected, setIsconnected] = useState(false);

  useEffect(() => {
    if (!currentUser.email) {
      const data = isAuthenticated();
      if (!data) {
        setIsconnected(false);
      }
    } else {
      setIsconnected(true);
    }
  }, []);



  function goNextStep(twoStep) {
    history.push({
      pathname: location.pathname,
      search: '?step=' + (step + 1),
      state: { detail: step - 1 }
    });

    if (step === 5) return;
    if (twoStep) {
      setStep((step) => step + 2);
    } else {
      setStep((step) => step + 1);
    }
  }

  function getPrice(paymentType, roomNumber) {
    dispatch(
      getPriceHomeInsurance({ paymentMethod: paymentType, rooms: roomNumber })
    );
  }
  function updateData(type, newData) {

    setData((data) => {
      return { ...data, [type]: newData };
    });
  }
  return (
    <div className={classes.root}>
      {step !== 5 ? (
        <>
          <Grid item xs={12} align="center" justify="center" alignItems="center">
            <Typography variant="h2" className={classes.categoryTitle}>
              {t("insurance.housing.title")}
            </Typography>
          </Grid>
          <Grid container spacing={4}>
            <Container component="main" maxWidth="lg">
              <div className={classes.paper}>
                <Paper elevation={5} className={classes.paperC}>
                  {step === 1 && (
                    <>
                      <HeaderHouseInsurance title={t("insurance.housing.step1.title")} />
                      <div className={classes.containerStepHouseInsurance}>
                        <StepOne
                          data={data.stepOne}
                          address={data.address}
                          update={updateData}
                          goNext={goNextStep}
                          style={classes}
                          getPrice={getPrice}
                        />
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <HeaderHouseInsurance title={t("insurance.housing.step2.title")} />
                      <div className={classes.containerStepHouseInsurance}>
                        <StepTwo
                          data={data.stepTwo}
                          update={updateData}
                          goNext={goNextStep}
                          style={classes}
                          contract={data.haveHomeContract}
                          dataStepOne={data.stepOne}
                          converDate={data.converDate}
                          connected={isConnected}
                          getPrice={getPrice}
                          insurancePrice={insurancePrice}
                          totalPrice={insurancePrice.data.unit_amount / 100}
                        />
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      {data.stepOne.roommates === 1 ? <HeaderHouseInsurance title={t("insurance.housing.step3.titleOption")} /> : <HeaderHouseInsurance title={t("insurance.housing.step3.title")} />}
                      <div className={classes.containerStepHouseInsurance}>
                        <StepThree
                          data={data}
                          update={updateData}
                          connected={isConnected}
                          style={classes}
                        />
                      </div>
                    </>
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>
        </>
      ) : (
        <StepFive update={updateData} data={data} />
      )}
    </div>
  );
};

function StepOne({ data, update, goNext, style, getPrice }) {

  const classes = useStyles();

  const dispatch = useDispatch();

  const { t } = useTranslation("common");

  const schema = contraintsStep1(t);


  const [helperAddress, setHelperAddress] = useState('');

  const [address, setAddress] = useState({});
  const [newData, setNewData] = useState({
    rooms: 1,
    roommates: 1,
    address: {},
  });
  const handleRooms = (event) => {
    setNewData((data) => ({
      ...data,
      rooms: event.target.value,
    }));
  };
  const handleRoommates = (event) => {
    setNewData((data) => ({
      ...data,
      roommates: event.target.value,
    }));
  };

  return (
    <Grid item xs={12}>
      <form
        className={style.form}
        onSubmit={(e) => {
          newData.address = address;

          if (newData.address && newData.address.street) {
            setHelperAddress('');
            update("stepOne", newData);
            update("maxStep", 2);
            update("address", newData.address);
            getPrice("mensuel", newData.rooms);
            goNext(false);
          } else {
            setHelperAddress(t("validation.field.required"));
            e.preventDefault();
          }
        }}
      >
        <AutoCompleteAddress setAddress={setAddress} limitFrenchCountry={true} />
        <FormHelperText className={[classes.error, classes.helperError]}>{helperAddress}</FormHelperText>
        <Grid item xs={12} container className={style.formItem}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {t("insurance.housing.step1.numberOfRooms")}
          </InputLabel>
          <FormControl className={style.formControl}>
            <Select
              value={newData.rooms}
              onChange={handleRooms}
              displayEmpty
              required
              className={style.selectEmpty}
              inputProps={{ "aria-label": "Rooms" }}
              autoWidth={true}
            >
              <MenuItem value={1}>{t("insurance.housing.step1.rooms.1")}</MenuItem>
              <MenuItem value={2}>{t("insurance.housing.step1.rooms.2")}</MenuItem>
              <MenuItem value={3}>{t("insurance.housing.step1.rooms.3")}</MenuItem>
              <MenuItem value={4}>{t("insurance.housing.step1.rooms.4")}</MenuItem>
              <MenuItem value={5}>{t("insurance.housing.step1.rooms.5")}</MenuItem>
              <MenuItem value={6}>{t("insurance.housing.step1.rooms.6")}</MenuItem>
              <MenuItem disabled value={7}>{t("insurance.housing.step1.rooms.7")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} container className={style.formItem}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {t("insurance.housing.step1.numberOfPersons")}
          </InputLabel>
          <FormControl className={style.formControl}>
            <Select
              value={newData.roommates}
              onChange={handleRoommates}
              displayEmpty
              required
              className={style.selectEmpty}
              inputProps={{ "aria-label": "Year" }}
              autoWidth={true}
            >
              <MenuItem value={1}>{t("insurance.housing.step1.persons.1")}</MenuItem>
              <MenuItem value={2}>{t("insurance.housing.step1.persons.2")}</MenuItem>
              <MenuItem value={3}>{t("insurance.housing.step1.persons.3")}</MenuItem>
              <MenuItem value={4}>{t("insurance.housing.step1.persons.4")}</MenuItem>
              <MenuItem value={5}>{t("insurance.housing.step1.persons.5")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} container className={style.formCTA} >
          <Typography variant="subtitle2" className={style.stepOneSutitle}>
            {t("insurance.housing.step1.insureYourHouseQuickly")}
          </Typography>
        </Grid>

        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
        >
          {t("insurance.housing.step1.getQuote")}
        </Button>
      </form>
    </Grid>
  );
}

function StepTwo({
  data,
  update,
  goNext,
  style,
  contract,
  dataStepOne,
  coverDate,
  connected,
  getPrice,
  insurancePrice,
  totalPrice,
}) {
  const { t } = useTranslation("common");

  const guarantedBoard = [
    {
      icon: PersonIcon,
      title: t("insurance.housing.step2.details.1.title"),
      text: t("insurance.housing.step2.details.1.description")
    },
    {
      icon: WhatshotIcon,
      title: t("insurance.housing.step2.details.2.title"),
      text: t("insurance.housing.step2.details.2.description")
    },
    {
      icon: BugReportIcon,
      title: t("insurance.housing.step2.details.3.title"),
      text: t("insurance.housing.step2.details.3.description")
    },
    {
      icon: IconStorm,
      title: t("insurance.housing.step2.details.4.title"),
      text: t("insurance.housing.step2.details.4.description")
    },
    {
      icon: SecurityIcon,
      title: t("insurance.housing.step2.details.5.title"),
      text: t("insurance.housing.step2.details.5.description")
    },
    {
      icon: OpacityIcon,
      title: t("insurance.housing.step2.details.6.title"),
      text: t("insurance.housing.step2.details.6.description")
    },
    {
      icon: IconThief,
      title: t("insurance.housing.step2.details.7.title"),
      text: t("insurance.housing.step2.details.7.description")
    },
    {
      icon: IconWindow,
      title: t("insurance.housing.step2.details.8.title"),
      text: t("insurance.housing.step2.details.8.description")
    }
  ];

  const [newData, setNewData] = useState({
    paymentMethod: "mensuel",
    price: totalPrice,
    pricePerRoomate: totalPrice / dataStepOne.roommates,
  });

  const [selectedDate, setSelectedDate] = useState(() => {
    if (contract) {
      const date = new Date();
      return date.setDate(date.getDate() + 45);
    } else {
      return Date.now();
    }
  });

  const [robberyPrice, setRobberyPrice] = useState(() => {
    if (dataStepOne.rooms >= 1 && dataStepOne.rooms <= 3) {
      return "6000";
    } else {
      return "15000";
    }
  });

  const [downloableFile, setDownloableFile] = useState({
    file: {},
    fileName: "",
  });

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePaymentMethod = (event) => {
    event.persist();
    getPrice(event.target.value, dataStepOne.rooms);
    setNewData((data) => ({
      ...data,
      paymentMethod: event.target.value,
    }));
  };

  useEffect(() => {
    if (dataStepOne.rooms >= 1 && dataStepOne.rooms <= 3) {

      setDownloableFile({
        file: tableau_garanties,
        fileName: "tableau_garanties.pdf",
      });
    } else if (dataStepOne.rooms >= 4) {
      setDownloableFile({
        file: tableau_garanties_15,
        fileName: "tableau_garanties_15.pdf",
      });
    }
  }, []);

  useEffect(() => {
    setNewData((data) => ({
      ...newData,
      price: totalPrice,
      pricePerRoomate: totalPrice / dataStepOne.roommates,
    }));
  }, [insurancePrice]);

  useEffect(() => {
    update("stepTwo", newData);
  }, [newData]);

  const openTable = (event) => {
    event.preventDefault();
  };

  return (
    <Grid item xs={12}>
      <form
        className={style.form}
        onSubmit={() => {
          if (connected && dataStepOne.roommates === 1) {
            update("maxStep", 4);
            update("price", insurancePrice);
            update("coverDate", selectedDate);
            update("stepTwo", newData);
            goNext(false);
          } else {
            update("maxStep", 4);
            update("price", insurancePrice);
            update("coverDate", selectedDate);
            update("stepTwo", newData);
            goNext(false);
          }
        }}
      >
        <Grid item xs={12} container direction="row" justifyContent="left">
          <MuiPickersUtilsProvider utils={DateFnsUtils} className="datePickerContainer">
            <Grid container justifyContent="left" className={style.formItem}>

              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                {t("insurance.housing.step2.startLabel")}
              </InputLabel>

              <FormControl className={style.formControl}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  className="datePickerInline"
                  style={{ margin: "0px !important" }}
                  id="date-picker-inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  disablePast
                  minDate={selectedDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                /></FormControl>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="left" className={style.formItem}>


          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {t("insurance.housing.step2.paymentMethodLabel")}
          </InputLabel>
          <FormControl className={style.formControl}>
            <RadioGroup
              aria-label="paymentMethod"
              name="paymentMethod"
              value={newData.paymentMethod}
              onChange={handlePaymentMethod}
              row
            >
              <FormControlLabel
                value="mensuel"
                control={<Radio />}
                label="Mensuel"
              />
              <FormControlLabel
                value="annuel"
                control={<Radio />}
                label="Annuel"
              />
            </RadioGroup></FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={style.root2}>
            <Link href={downloableFile.file} download={downloableFile.fileName}>
              {t("insurance.housing.step2.consultGuaranteeTable")}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid container spacing={2}>
            {guarantedBoard.map((guaranted, index) => {
              return (
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <RockerSwitch Icon={guaranted.icon} title={guaranted.title} text={guaranted.text} index={index} backColor={"#8ecece"} isUnlockMouseOver={true}></RockerSwitch>
                </Grid>
              )
            }
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={style.priceContainer}
        >
          <Grid item xs={12} style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={6} style={{ textAlign: "right", paddingRight: "15px" }}>
                <Typography >
                  <Link target="_blank" href={'/document/ipid_habitation.pdf'} download="ipid.pdf">
                    {'fiche ipid.pdf'}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left", paddingLeft: "15px" }}>
                <Typography >
                  <Link target="_blank" href={'/document/CG_MaMaison_habitation.pdf'} download="conditions_generales.pdf">
                    {'conditions générales.pdf'}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Button
            className={[style.submit, style.submitstep2]}
            color="primary"
            size="large"
            variant="outlined"
            type="submit"
          >
            {dataStepOne.roommates > 1 ? t("insurance.housing.step2.next") : t("insurance.housing.pay")}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}


function StepMultimediaOption({
  data,
  update,
  goNext,
  style,
  contract,
  dataStepOne,
  coverDate,
  connected,
  getPrice,
  insurancePrice,
  totalPrice,
}) {
  const { t } = useTranslation("common");

  const [newData, setNewData] = useState({
  });

  return (
    <Grid item xs={12}>
      <Typography variant="h2" className={style.stepTitle}>
        {t("insurance.housing.stepMultimediaOption.title")}
      </Typography>
      <form
        className={style.form}
        onSubmit={() => {
          if (connected && dataStepOne.roommates === 1) {
            update("maxStep", 4);
            update("stepTwo", newData);
            goNext(false);
          } else {
            update("maxStep", 4);
            update("stepTwo", newData);
            goNext(false);
          }
        }}
      >
        <Grid item xs={12} container direction="row" justifyContent="left">
          TEST
        </Grid>
      </form>
    </Grid>
  );
}

const constraints2 = (t) => ({
  emailUser: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
    format: {
      pattern: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
      message: t('validation.field.email.incorrect')
    }
  },
  password: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
    format: {
      pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      message: t('validation.field.password.incorrect')
    }
  },
  passwordConfirm: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
    equality: {
      attribute: "password",
      message: t("validation.field.password.must.match"),
      comparator: function (v1, v2) {
        return v1 === v2;
      },
    },
  }
});

function StepThree({ update, data, connected, style }) {

  const { t } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = LocalStorageService.getUser();
  const multimediaInsurance = useSelector((state) => state.multimediaInsurance.dataDetails);
  const insurancePrice = useSelector((state) => state.houseInsurance);

  let roomatesInfos = [];
  for (let index = 0; index < data.stepOne.roommates - 1; index++) {

    roomatesInfos.push({
      name: "",
      lastName: "",
      birthday: "",
      civility: "M"
    })
  }

  const [newData, setNewData] = useState({
    civility: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    phoneNumber: "",
    acceptTerm: false,
    roomatesInfos: roomatesInfos
  });

  const handleMainAccount = (event) => {
    setNewData((data) => ({
      ...data,
      [event.target.name]: event.target.name === "acceptTerm" ? event.target.checked : event.target.value,
    }));

  };

  const handleRoomatesInfos = (event) => {
    var items = newData.roomatesInfos;
    items[event.target.id][event.target.name] = event.target.value;

    setNewData((data) => ({
      ...data,
      roomatesInfos: items
    }));

  };

  const handleMainAccountDateChange2 = (date) => {
    setNewData((data) => ({
      ...data,
      birthDate: date,
    }));
  };

  const handleRoomateDateChange2 = (dateR, index) => {
    const dateRoomate = new Date(dateR);
  
    var items = [...newData.roomatesInfos];
    items[index].birthday = dateRoomate;
    setNewData((data) => ({
      ...data,
      roomatesInfos: items
    }));
  };

  const handleRoomateCivility = (event, index) => {
    var items = newData.roomatesInfos;

    items[index]["civility"] = event.target.value;
    setNewData((data) => ({
      ...data,
      roomatesInfos: items
    }));
  };

  const [helperCivility, setHelperCivility] = useState('');
  const [helperFirstName, setHelperFirstName] = useState('');
  const [helperLastName, setHelperLastName] = useState('');
  const [helperBirthDate, setHelperBirthDate] = useState('');
  const [helperPhoneNumber, setHelperPhoneNumber] = useState('');
  const [helperEmail, setHelperEmail] = useState('');
  const [helperPassword, setHelperPassword] = useState('');
  const [helperPasswordConfirmation, setHelperPasswordConfirmation] = useState('');
  const [helperAcceptTerm, setHelperAcceptTerm] = useState('');

  const [helperRoomates, setHelperRoomates] = useState(newData.roomatesInfos.map((el) => {
    return {
      name: "",
      lastName: "",
      birthDate: ""
    }
  }));


  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateForm() {
    let isValid = true;

    if (!connected) {

      if (newData.civility === "") {
        setHelperCivility(t("validation.field.required"));
        isValid = false;
      } else {
        setHelperCivility('');
      }

      if (newData.firstName === "") {
        setHelperFirstName(t("validation.field.required"));
        isValid = false;
      } else {
        setHelperFirstName('');
      }

      if (newData.lastName === "") {
        setHelperLastName(t("validation.field.required"));
        isValid = false;
      } else {
        setHelperLastName('');
      }

      if (newData.birthDate === "") {
        setHelperBirthDate(t("validation.field.required"));
        isValid = false;
      } else {
        setHelperBirthDate('');
      }

      if (newData.phoneNumber === "") {
        setHelperPhoneNumber(t("validation.field.required"));
        isValid = false;
      } else {
        setHelperPhoneNumber('');
      }

      if (formState.values.emailUser === "") {
        isValid = false;
      } else {
        setHelperEmail('');
      }

      if (formState.values.password === "") {
        isValid = false;
      } else {
        setHelperPassword('');
      }

      if (formState.values.passwordConfirmation === "") {
        isValid = false;
      } else {
        setHelperPasswordConfirmation('');
      }
      if (newData.acceptTerm === "" || newData.acceptTerm === false) {
        setHelperAcceptTerm(t("validation.field.required"));
        isValid = false;
      } else {
        setHelperAcceptTerm('');
      }

    }
    if (newData.roomatesInfos.length > 0) {
      for (let i = 0; i < newData.roomatesInfos.length; i++) {

        let items = [...helperRoomates];
        for (let i = 0; i < items.length; i++) {

          let item = { ...items[i] };

          if (newData.roomatesInfos[i].name === "") {
            item.name = t("validation.field.required");
            isValid = false;
          }
          if (newData.roomatesInfos[i].lastName === "") {
            item.lastName = t("validation.field.required");
            isValid = false;
          }
          if (newData.roomatesInfos[i].lastName === "") {
            item.birthDate = t("validation.field.required");
            isValid = false;
          }
          if (newData.roomatesInfos[i].civility === "") {
            item.civility = t("validation.field.required");
            isValid = false;
          }
          items[i] = item;
          setHelperRoomates(items);
        }
      }
    }

    return isValid
  }

  const schema = constraints2(t);

  const [helperAcceptTerm2, setHelperAcceptTerm2] = useState('');

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: "",
      passwordConfirm: "",
      emailUser: ""
    },
    touched: {},
    errors: {},
    flag: true,
  });

  const hasError = (field) => {
    if (formState.errors[field]) {
      if (formState.touched[field]) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChangeField = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const createAccount = () => {
    dispatch(
      createAccountApi({
        fields: {
          "user": {
            "name": newData.firstName,
            "lastName": newData.lastName,
            "email": formState.values.emailUser,
            "password": formState.values.password
          },
          "civility": newData.civility,
          "birthday": newData.birthDate.toLocaleDateString("FR"),
          "phoneNumber": newData.phoneNumber,
          "address": data.address
        }, callback: () => {
          createHouseInsuranceContract();
        }
      }));
  }
  const createHouseInsuranceContract = () => {
    data.roommatesInfos = newData.roomatesInfos;

    dispatch(createHouseInsuranceContractApi({
      fields: {
        "startDate": moment(data.coverDate).format('DD/MM/YYYY'),
        "address": data.address,
        "numberOfPersons": data.stepOne.roommates,
        "numberOfRooms": data.stepOne.rooms,
        "optionPayment": data.stepTwo.paymentMethod,
        "price": data.price.data.unit_amount,
        "priceId": data.price.data.id,
        "roommates": data.roommatesInfos,
        "addMultimediaInsurance": multimediaInsurance ? multimediaInsurance.active ? multimediaInsurance : null : null,
      }, callback: () => {
        const currentUser = LocalStorageService.getUser();
        redirectPaymentPageHome({ priceId: data.price.data.id, email: currentUser.email, startDate: moment(data.coverDate).format('DD/MM/YYYY'), additionalPriceId: multimediaInsurance ? multimediaInsurance.active ? multimediaInsurance.product : null : null, });
      }
    }));
  }

  return (
    <Grid container xs={12} spacing={1} justifyContent='center'>
      {data.stepOne.roommates === 1 ?
        <Typography variant="h2" className={style.stepTitle}>
          {t("insurance.housing.stepMultimediaOption.title")}
        </Typography>
        :
        <>
          <Grid item xs={12} className={style.accountCreationContainer} >

            <Typography className={style.formText} variant="h6">
              {t("insurance.housing.roomatesForm.mainAccountInfoTitle")}
            </Typography>
            <TextField
              className={style.textField} style={{ marginTop: '10px' }}
              fullWidth
              label={t("insurance.housing.accountCreation.firstName")}
              name="firstName"
              type="text"
              value={currentUser.name}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12} className={style.accountCreationContainer} >
            <TextField
              className={style.textField}
              fullWidth
              label={t("insurance.housing.accountCreation.lastName")}
              name="lastName"
              type="text"
              value={currentUser.lastName}
              variant="outlined"
              disabled
            />
          </Grid>
        </>
      }

      <form id="formStep3"
        className={style.form}
        onSubmit={async (e) => {
          e.preventDefault();
          setFormState((formState) => ({
            ...formState,
            touched: {
              emailUser: true,
              password: true,
              passwordConfirm: true
            },
          }));

          if (validateForm()) {
            if (!connected) {
              createAccount()
            } else {
              createHouseInsuranceContract();
            }
          } else {
            console.log("form error");
          }
        }}>
        <div className={style.mainAccountForm} style={{ display: connected ? "none" : "block" }}>
          <Typography className={style.formText} variant="h6">
            {t("insurance.housing.roomatesForm.mainAccountInfoTitle")}
          </Typography>
          <Grid item xs={12} className={style.accountCreationContainer}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              {t("insurance.housing.accountCreation.civility")}
            </InputLabel>
            <RadioGroup
              aria-label={t("insurance.housing.accountCreation.civility")}
              name="civility"
              value={newData.civility || ""}
              onChange={handleMainAccount}
              className={style.accountCreationGender}>
              <FormControlLabel value="M" control={<Radio />} label={t("insurance.housing.accountCreation.civilityMale")} />
              <FormControlLabel value="Mme" control={<Radio />} label={t("insurance.housing.accountCreation.civilityFemelle")} />
              <FormHelperText className={style.error}>{helperCivility}</FormHelperText>
            </RadioGroup>

            <TextField
              className={style.textField} style={{ marginTop: '10px' }}
              fullWidth
              label={t("insurance.housing.accountCreation.firstName")}
              name="firstName"
              onChange={handleMainAccount}
              type="text"
              value={newData.firstName || ""}
              variant="outlined"
            />
            <FormHelperText className={style.error}>{helperFirstName}</FormHelperText>

            <div className={style.accountCreationOneLineContainer}>
              <TextField
                className={style.textField}
                fullWidth
                label={t("insurance.housing.accountCreation.lastName")}
                name="lastName"
                onChange={handleMainAccount}
                type="text"
                value={newData.lastName || ""}
                variant="outlined"
              />
              <FormHelperText className={style.error}>{helperLastName}</FormHelperText>
            </div>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label={t("insurance.housing.accountCreation.birthDate")}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="dense"
                value={newData.birthDate}
                onChange={handleMainAccountDateChange2}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <FormHelperText className={style.error}>{helperBirthDate}</FormHelperText>
            </MuiPickersUtilsProvider>
            <div className={style.accountCreationOneLineContainer}>
              <TextField
                className={style.textField}
                fullWidth
                label={t("insurance.housing.accountCreation.phoneNumber")}
                name="phoneNumber"
                onChange={handleMainAccount}
                type="tel"
                value={newData.phoneNumber || ""}
                variant="outlined"
              />
              <FormHelperText className={style.error}>{helperPhoneNumber}</FormHelperText>
            </div>
            <div className={style.accountCreationOneLineContainer}>
              <TextField
                className={style.textField}
                fullWidth
                label={t("insurance.housing.accountCreation.emailAddress")}
                name="emailUser"
                onChange={handleChangeField}
                type="text"
                value={formState.values.emailUser || ""}
                variant="outlined"
                error={hasError("emailUser")}
                helperText={hasError("emailUser") ? formState.errors.emailUser[0] : null}
              />
              <FormHelperText className={style.error}>{helperEmail}</FormHelperText>
            </div>
            <div className={style.accountCreationOneLineContainer}>
              <TextField
                className={style.textField}
                fullWidth
                label={t("insurance.housing.accountCreation.password")}
                name="password"
                onChange={handleChangeField}
                type="password"
                value={formState.values.password || ""}
                variant="outlined"
                error={hasError("password")}
                helperText={hasError("password") ? formState.errors.password[0] : null}
              />
              <FormHelperText className={style.error}>{helperPassword}</FormHelperText>
            </div>
            <div className={style.accountCreationOneLineContainer}>
              <TextField
                className={style.textField}
                fullWidth
                label={t("insurance.housing.accountCreation.passwordConfirmation")}
                name="passwordConfirm"
                onChange={handleChangeField}
                type="password"
                value={formState.values.passwordConfirm || ""}
                variant="outlined"
                error={hasError("passwordConfirm")}
                helperText={hasError("passwordConfirm") ? formState.errors.passwordConfirm[0] : null}
              />
              <FormHelperText className={style.error}>{helperPasswordConfirmation}</FormHelperText>
            </div>
            <div style={{ fontSize: "14px", marginTop: "10px" }}>
              <Checkbox
                checked={newData.acceptTerm}
                onChange={handleMainAccount}
                name="acceptTerm"
                color="primary"
              />
              {t("insurance.housing.accountCreation.legalInfo")}
            </div>
            <FormHelperText className={style.error}>{helperAcceptTerm}</FormHelperText>
          </Grid>
        </div>
        {data.stepOne.roommates === 1 &&
          <>
            <MultimediaOption interval={insurancePrice ? insurancePrice.data.recurring.interval : "month"}/>
            {multimediaInsurance && multimediaInsurance.active &&
              <>
                <Grid item xs={12} align="left" justify="left" alignItems="left" className={classes.contentContainer}>
                  <div>
                    <p>
                      <Checkbox
                        checked={formState.values.acceptTerm2}
                        onChange={handleChange}
                        name="acceptTerm2"
                        color="primary"
                      />
                      {t("insurance.multimedia.acceptTerm2")}</p>
                    <FormHelperText className={classes.error}>{helperAcceptTerm2}</FormHelperText>
                  </div>
                </Grid>
                <Grid item xs={12} align="left" justify="left" alignItems="left" className={classes.contentContainer}>
                  <div>
                    <p>
                      <Checkbox
                        checked={formState.values.acceptTerm}
                        onChange={handleChange}
                        name="acceptTerm"
                        color="primary"
                      />
                      {t("insurance.multimedia.acceptTerm")}</p>
                    <FormHelperText className={classes.error}>{helperAcceptTerm}</FormHelperText>
                  </div>
                </Grid>
              </>
            }


          </>}
        {newData.roomatesInfos.map((value, i) => {
          return (
            <Grid
              item
              xs={12}
              key={i}
            >

              {i === 0 ? <Divider className={style.stepFourDivider} style={{ display: connected ? "none" : "block" }} /> : ""}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid className={style.roomateFormContainer}>
                  <div >
                    <Typography className={style.formText} variant="h6">
                      {(data.stepOne.roommates - 1) > 1 ? t("insurance.housing.roomatesForm.infoOnRoomates", { number: i + 1 }) : t("insurance.housing.roomatesForm.infoOnRoomate")}
                    </Typography>
                    <FormControl className={style.formControl}>
                      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        {t("insurance.housing.accountCreation.civility")}
                      </InputLabel>
                      <RadioGroup
                        aria-label={t("insurance.housing.accountCreation.civility")}
                        id={i}
                        name="civility"
                        value={newData.roomatesInfos[i].civility || ""}
                        onChange={(event) => handleRoomateCivility(event, i)}
                        className={style.accountCreationGender}>
                        <FormControlLabel value="M" control={<Radio />} label={t("insurance.housing.accountCreation.civilityMale")} />
                        <FormControlLabel value="Mme" control={<Radio />} label={t("insurance.housing.accountCreation.civilityFemelle")} />
                        <FormHelperText className={style.error}>{helperRoomates[i].civility}</FormHelperText>
                      </RadioGroup>
                      <TextField
                        className={style.textFieldStep3}
                        fullWidth
                        id={i}
                        label={(data.stepOne.roommates - 1) > 1 ? t("insurance.housing.roomatesForm.firstNameMany", { number: i + 1 }) : t("insurance.housing.roomatesForm.firstName")}
                        name={"name"}
                        onChange={handleRoomatesInfos}
                        type="text"
                        value={newData.roomatesInfos[i].name || ""}
                        variant="outlined"
                      />
                      <FormHelperText className={style.error}>{helperRoomates[i].name}</FormHelperText>
                    </FormControl>
                    <TextField
                      className={style.textFieldStep3}
                      fullWidth
                      id={i}
                      label={(data.stepOne.roommates - 1) > 1 ? t("insurance.housing.roomatesForm.lastNameMany", { number: i + 1 }) : t("insurance.housing.roomatesForm.lastName")}
                      name={"lastName"}
                      onChange={handleRoomatesInfos}
                      type="text"
                      value={newData.roomatesInfos[i].lastName || ""}
                      variant="outlined"
                    />
                    <FormHelperText className={style.error}>{helperRoomates[i].lastName}</FormHelperText>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                      <DatePicker
                        label={(data.stepOne.roommates - 1) > 1 ? t("insurance.housing.roomatesForm.birthDateMany", { number: i + 1 }) : t("insurance.housing.roomatesForm.birthDate")}
                        id={i}
                        name={"birthday"}
                        value={(newData.roomatesInfos[i].birthday)}
                        onChange={(dateR) => handleRoomateDateChange2(dateR, i)}
                        format="DD/MM/YYYY"
                      />
                      <FormHelperText className={style.error}>{helperRoomates[i].birthDate}</FormHelperText>
                    </LocalizationProvider>
                  </div>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          )
        })}
        <Grid item xs={12} container>
          <Grid item xs={12} justify="center" alignItems="center" className={style.formCTA} >

          </Grid>
          {data.stepOne.roommates === 1 && multimediaInsurance && multimediaInsurance.active ?
            <Button
              disabled={formState.values.acceptTerm2 && formState.values.acceptTerm ? false : true}
              className={classes.submitStep3}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
            >
              Payer
            </Button>
            :
            <Button
              className={classes.submitStep3}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
            >
              Payer
            </Button>
          }
        </Grid>
      </form>
    </Grid>
  )
}
function StepFive({ data, update }) {

  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const history = useHistory();

  const service = LocalStorageService.getService();

  const dataFirma = service.getHouseContract();

  useEffect(() => {
    dispatch(finishDigitalFirm({ fields: { contractId: dataFirma.contractId, certificateId: dataFirma.certificateId, addMultimediaInsurance: typeof dataFirma.additionalMultimediaContractId === "undefined" ? null : dataFirma.additionalMultimediaContractId }, history }));
  }, [dataFirma]);

  return (
    <div style={{ display: 'flex', width: '100%', height: '550px' }}>
    </div>
  )
}
dayjs.locale('fr'); 

