import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import Box from "@material-ui/core/Box";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecundary" align="center">
      {'Copyright © '}
      <Link color="inherit" target="_blank" href="https://studcorp.com/">
        STUDCORP
      </Link>
      {' '}{new Date().getFullYear()}{'.'}
    </Typography>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    marginTop: "0px"
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root} mt={8}>
      <Copyright />
    </Box>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
