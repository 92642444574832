import axios from 'axios';
import LocalStorageService from '../services/LocalStorageService';

export const publicUrl = process.env.REACT_APP_BACKEND_URL;
const service = LocalStorageService.getService();

export const instance = axios.create();

instance.defaults.baseURL = `${publicUrl}/api`;
instance.defaults.headers.common.Accept =
  'application/json, application/octet-stream, image/jpeg, image/jpg, image/png, text/plain; application/pdf;';
instance.defaults.headers.common['Content-Type'] = 'application/json, multipart/form-data';
instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.post['Access-Control-Allow-Methods'] = '*';

instance.interceptors.request.use(
  (config) => {
    const data = config.data;    
      if(isServicePrivate(config.url)) {
          const token = service.getAccessToken();
          config.headers.Authorization = `Bearer ${token}`;
      }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(null, (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 
    && error.response.data?.message === "Votre compte n'a pas été vérifié. Veuillez confirmer votre email via l'email envoyé lors de votre inscription.") {
    return Promise.reject(error);
  }

    console.log(error.response.data?.message);
  if (error.response && error.response.status === 401 
    && error.response.data?.message === "L'identifiant ou le mot de passe est incorrect. Veuillez réessayer.") {
    return Promise.reject(error);
  }

  if (originalRequest && originalRequest.url !== "/security/token/refresh" && error.response && error.response.status === 401) {
    const refresh_token = service.getRefreshToken();
    return instance.post(`/security/token/refresh`, {"refresh_token": refresh_token})
    .then((response) => {
      if (response.data.token) {
        service.setToken({ token: response.data.token, refresh_token: refresh_token});
        return instance.request(error.config);
      }
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
      
  }

  return Promise.reject(error);
});

const isServicePrivate = (url) => {
  return !(url.match('security')) && !(url.startsWith('/payments/price/home_insurance')) && !(url.startsWith('/forgot_password/'))
}
// TODO interceptor to response. Check 40X status
