import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useScript } from "../../helpers";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  component: {
    padding: "5px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  addressInput: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;

const AutoCompleteAddress = (props) => {
  let autocomplete;
  let address1Field;

  const classes = useStyles();
  const { t } = useTranslation("common");

  const { setAddress, limitFrenchCountry } = props;
  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&libraries=places`
  );

  const [address, setAddressState] = useState({
    street: "",
    complement: "",
    postalCode: "",
    city: "",
    country: "",
  });

  useEffect(() => {
    if (status === "ready") initAutocomplete();
  }, [status]);

  const clear = () => {
    initAutocomplete();
    address1Field.focus();
  };

  function initAutocomplete() {
    address1Field = document.querySelector("#street-address");

    if (status === "ready") {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: limitFrenchCountry && { country: ["fr"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      autocomplete.addListener("place_changed", fillInAddress);
    }
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let streetAndNumber = "";
    let postcode = "";
    let country = "";
    let city = "";
    let complement = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number":
          streetAndNumber += component.long_name + " ";
          break;
        case "route":
          streetAndNumber += component.short_name;
          break;
        case "postal_code":
          postcode = component.long_name;
          break;
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.short_name;
          break;
        default:
          break;
      }
    }

    const updatedAddress = {
      ...address,
      street: streetAndNumber + complement,
      postalCode: postcode,
      city: city,
      country: country,
      complement: complement,
    };

    setAddressState(updatedAddress);
    if (setAddress) setAddress(updatedAddress);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddressState((prevState) => ({
      ...prevState,
      [name]: value,
    })); 
    
    if (setAddress) setAddress({ ...address, [name]: value });
  };

  return (
    <div>
      <input name="autocomplete" id="autocomplete" type="hidden" />
      <TextField
        id="street-address"
        name="street"
        label={t("confirmation-pass.form.streetAddress")}
        variant="outlined"
        className={classes.addressInput}
        value={address.street}
        onChange={handleChange}
      />
      {/* <TextField
        id="complement-address"
        name="complement"
        label={t("confirmation-pass.form.complementAddress")}
        variant="outlined"
        className={classes.addressInput}
        value={address.complement}
        onChange={handleChange}
      /> */}
      <TextField
        id="postal-code"
        name="postalCode"
        label={t("confirmation-pass.form.postalCode")}
        variant="outlined"
        className={classes.addressInput}
        value={address.postalCode}
        onChange={handleChange}
      />
      <TextField
        id="city"
        name="city"
        label={t("confirmation-pass.form.city")}
        variant="outlined"
        className={classes.addressInput}
        value={address.city}
        onChange={handleChange}
      />
      <TextField
        id="country"
        name="country"
        label={t("confirmation-pass.form.country")}
        variant="outlined"
        className={classes.addressInput}
        value={address.country}
        onChange={handleChange}
      />
    </div>
  );
};

export default AutoCompleteAddress;
