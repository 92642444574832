import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validate from "validate.js";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Chip, MenuItem, Input } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { PartnerShipLevel } from "../../utils/PartnerShipLevel";
import { PartnerLevelHealthMobility } from "../../utils/PartnerLevelHealthMobility"
import { ListOffer } from "../../utils/ListOffer";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  addSchools,
  editSchools,
} from "../../../../redux/actions/schoolsActions";
import {
  offerList,
} from "../../../../redux/actions/offersActions";

import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { toastr } from "react-redux-toastr";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const constraints = (t) => ({
  name: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  studentsSchoolName: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  partnershipLevel: {
    presence: { selected: false, message: t("validation.field.required") },
  },
  emailDomain: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  partnerLevelHealthMobility: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  insuranceForForeign: {},
  selectedFiles: {},
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "65%",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  formControl: {
    margin: theme.spacing(2, 0, 3),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: " 100%",
  },
  formGrid: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: "2%",
  },
  formField: {
    display: "flex",
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "50%",
  },
  selectMultiple: {
    "& .Mui-selected":{
      backgroundColor: "rgb(48 100 114)",
      color: "white",
    },
    "& .MuiChip-root":{
      backgroundColor: "rgb(48 100 114)",
      color: "white",
    }
  },
}));

export const SchoolForm = (props) => {
  const history = useHistory();

  const match = useRouteMatch();

  const classes = useStyles();

  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const schema = constraints(t);
  const offers = useSelector((state) => state.offersInsurance.offers);

  const schoolFormInit = {
    emailDomain: "",
    id: null,
    name: "",
    partnershipLevel: "",
    managementOffer: [],
    path: "",
    studentsSchoolName: "",
    insurer: "",
    partnerLevelHealthMobility:"",
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    flag: true,
  });

  const schools = useSelector((state) => state.school.data);

  const getDataByEdit = async () => {
    if (match.params.id && formState.flag) {
      const { id } = match.params;
      formState.values = schools.find((item) => item.id === parseInt(id));
      formState.flag = false;
      setFormState(formState);
    }
  };

  useEffect(() => {
    getDataByEdit();
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (match.params.id && window.location.pathname !== "/schools/create") {
      const { id } = match.params;
      const currentSchool = schools.find((item) => item.id === parseInt(id));
      if(currentSchool.partnershipLevel === "partner"){
        dispatch(offerList({ idSchool: id}));
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== "/schools/create") {
      const { id } = match.params;
      const currentSchool = schools.find((item) => item.id === parseInt(id));
      let offerSelected = [];
      if(currentSchool.partnershipLevel === "partner"){
        offers.map((offerValue) => {
          offerSelected.push(ListOffer.find((item) => item.value === offerValue.offer).value);
        })
      }
      setFormState((formState) => ({
        ...formState,
        values : {
          ...formState.values,
          managementOffer: offerSelected,
        }}));
    }
  }, [offers]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.type === "file"
            ? event.target.files
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleAddSchool = (event) => {
    event.preventDefault();
    if (!match.params.id) {
      const allFileFill =
        hasGaranteesFile() & hasCertificateFile() & hasSelectInsurance();
      if (!allFileFill) {
        return;
      }
    }
    const { values } = formState;
    let formData = new FormData();
    
    if (values.partnershipLevel === "inclusive") {
      formData.append("insurer", values.insurer);
      if (values.selectedFiles) {
        formData.append("guaranteesFile", values.selectedFiles[0]);
      }
      values.insuranceForForeign = values.insuranceForForeign
        ? values.insuranceForForeign
        : false;
      formData.append("insuranceForForeign", values.insuranceForForeign);

      if (values.certificateFiles) {
        formData.append("certificateFile", values.certificateFiles[0]);
      }
    }
    formData.append("name", values.name);
    formData.append("studentsSchoolName", values.studentsSchoolName);
    formData.append("partnershipLevel", values.partnershipLevel);
    formData.append("partnerLevelHealthMobility", values.partnerLevelHealthMobility);
    formData.append("emailDomain", values.emailDomain);

    values.managementOffer.map((offer) => {
      formData.append("listOffer[]", offer);
    })
    if (match.params.id) {
      dispatch(editSchools({ id: values.id, school: formData, history }));
    } else {
      dispatch(addSchools({ school: formData, history }));
    }
  };

  const handleBack = () => {
    history.push("/schools");
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const hasCertificateFile = () => {
    const { values } = formState;
    if (values.partnershipLevel === "inclusive") {
      const { values } = formState;
      if (!values.certificateFiles) {
        toastr.error("", `sélectionner ${t("schoolManager.form.certificate")}`);

        return false;
      }
      return true;
    }
    return true;
  };

  const hasSelectInsurance = () => {
    const { values } = formState;
    if (values.partnershipLevel === "inclusive") {
      const { values } = formState;
      if (!values.insurer) {
        toastr.error("", `Achevée ${t("schoolManager.form.insurance-label")}`);

        return false;
      }
      return true;
    }
    return true;
  };

  const hasGaranteesFile = () => {
    const { values } = formState;
    if (values.partnershipLevel === "inclusive") {
      const { values } = formState;
      if (!values.selectedFiles) {
        toastr.error(
          "",
          `sélectionner ${t("schoolManager.form.guaranteesTable")}`
        );

        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <Typography component="h1" variant="h3" margin="normal">
          {t("schoolManager.create.title")}
        </Typography>

        <form className={classes.form} onSubmit={handleAddSchool}>
          <div className={classes.formContainer}>
            <div className={classes.formField}>
              <div className={classes.formGrid}>
                <TextField
                  className={classes.textField}
                  error={hasError("name")}
                  helperText={
                    hasError("name") ? formState.errors.name[0] : null
                  }
                  margin="normal"
                  label={t("schoolManager.form.name")}
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name}
                  variant="outlined"
                  autoFocus
                />

                <TextField
                  className={classes.textField}
                  error={hasError("name")}
                  helperText={
                    hasError("studentsSchoolName")
                      ? formState.errors.studentsSchoolName[0]
                      : null
                  }
                  margin="normal"
                  label={t("schoolManager.form.studentsSchoolName")}
                  name="studentsSchoolName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.studentsSchoolName || ""}
                  variant="outlined"
                />

                <TextField
                  className={classes.textField}
                  error={hasError("emailDomain")}
                  helperText={
                    hasError("emailDomain")
                      ? formState.errors.emailDomain[0]
                      : null
                  }
                  margin="normal"
                  label={t("schoolManager.form.emailDomain")}
                  name="emailDomain"
                  onChange={handleChange}
                  type="domain"
                  value={formState.values.emailDomain || ""}
                  variant="outlined"
                />
              </div>
              <div className={classes.formGrid}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">
                    {t("schoolManager.form.partnershipLevel")}
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    name="partnershipLevel"
                    value={formState.values.partnershipLevel || ""}
                    onChange={handleChange}
                  >
                    {Object.keys(PartnerShipLevel).map((key, i) => (
                      <MenuItem key={i} value={PartnerShipLevel[key].value}>
                        {PartnerShipLevel[key].text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                  <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="grouped-select">
                    {t("Level")}
                  </InputLabel>
                  <Select
                    defaultValue={formState.values.PartnerLevelHealthMobility}
                    id="grouped-select"
                    name="partnerLevelHealthMobility"
                    value={formState.values.partnerLevelHealthMobility}
                    onChange={handleChange}
                  >
                     {Object.keys(PartnerLevelHealthMobility).map((key, i) => (
                      <MenuItem key={i} value={PartnerLevelHealthMobility[key].value}>
                        {PartnerLevelHealthMobility[key].text}
                      </MenuItem>
                    ))}
                     {console.log("formState.values.PartnerLevelHealthMobility", formState.values.PartnerLevelHealthMobility)}
                     {console.log(PartnerLevelHealthMobility)}

                  </Select>
                </FormControl>
                <FormControl className={classes.formControl} disabled={
                      formState.values.partnershipLevel !==
                      PartnerShipLevel.INCLUSIVE1.value
                    }>
                  <InputLabel htmlFor="grouped-select">
                    {t("schoolManager.form.selectedOffer")}
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    className={classes.selectMultiple}
                    multiple
                    value={formState.values.managementOffer || []}
                    name="managementOffer"
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {ListOffer.map((offer) => (
                      <MenuItem key={offer.value} value={offer.value}>
                        {offer.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormLabel
                  component="legend"
                  disabled={
                    formState.values.partnershipLevel !==
                    PartnerShipLevel.INCLUSIVE.value
                  }
                >
                  {t("schoolManager.form.insurance-label")}
                </FormLabel>
                <RadioGroup
                  aria-label="Assurance santé/mobilité"
                  name="insurer"
                  value={formState.values.insurer || ""}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Mutuaide"
                    control={<Radio />}
                    label={t("schoolManager.form.insurance-option1")}
                    disabled={
                      formState.values.partnershipLevel !==
                      PartnerShipLevel.INCLUSIVE.value
                    }
                  />
                  <FormControlLabel
                    value="Kinousassur"
                    control={<Radio />}
                    label={t("schoolManager.form.insurance-option2")}
                    disabled={
                      formState.values.partnershipLevel !==
                      PartnerShipLevel.INCLUSIVE.value
                    }
                  />
                </RadioGroup>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.insuranceForForeign || false}
                      onChange={handleChange}
                      name="insuranceForForeign"
                      color="primary"
                      disabled={
                        formState.values.partnershipLevel !==
                        PartnerShipLevel.INCLUSIVE.value
                      }
                    />
                  }
                  label={t("schoolManager.form.insuranceForForeign")}
                />

                <div style={{ marginTop: 20 }}>
                  <label htmlFor="btn-upload">
                    <input
                      id="btn-upload"
                      name="selectedFiles"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChange}
                      disabled={
                        formState.values.partnershipLevel !==
                        PartnerShipLevel.INCLUSIVE.value
                      }
                    />
                    <Button
                      className="btn-choose"
                      variant="outlined"
                      component="span"
                      disabled={
                        formState.values.partnershipLevel !==
                        PartnerShipLevel.INCLUSIVE.value
                      }
                    >
                      {t("schoolManager.form.guaranteesTable")}
                    </Button>
                  </label>
                  <div style={{ marginTop: 20 }}>
                    {formState.values.selectedFiles &&
                    formState.values.selectedFiles.length > 0
                      ? formState.values.selectedFiles[0].name
                      : null}
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <label htmlFor="btn-upload-img">
                    <input
                      accept="image/*"
                      id="btn-upload-img"
                      name="certificateFiles"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChange}
                      disabled={
                        formState.values.partnershipLevel !==
                        PartnerShipLevel.INCLUSIVE.value
                      }
                    />
                    <Button
                      className="btn-choose"
                      variant="outlined"
                      component="span"
                      disabled={
                        formState.values.partnershipLevel !==
                        PartnerShipLevel.INCLUSIVE.value
                      }
                    >
                      {t("schoolManager.form.certificate")}
                    </Button>
                  </label>
                  <div style={{ marginTop: 20 }}>
                    {formState.values.certificateFiles &&
                    formState.values.certificateFiles.length > 0
                      ? formState.values.certificateFiles[0].name
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <Button
              className={classes.submit}
              color="primary"
              disabled={!formState.isValid}
              size="large"
              type="submit"
              variant="contained"
            >
              {match.params.id
                ? t("schoolManager.form.updateBtn")
                : t("schoolManager.form.createBtn")}
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};
