import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { loginUser } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { getSchoolDetails } from "../../redux/actions/schoolsActions";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import validate from 'validate.js';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import { Toolbar } from '@material-ui/core';
import Footer from "../../components/layouts/Main/components/Footer";
import {resettingPassword} from "../../redux/actions/studentsActions";
import { useDispatch } from "react-redux";

const constraints = (t) => ({
  password: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
    format: {
      pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      message: t('validation.field.password.incorrect')
    }
  },
  passwordConfirm: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
    equality: {
      attribute: "password",
      message: t("validation.field.password.must.match"),
      comparator: function (v1, v2) {
        return v1 === v2;
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paperC : {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "90%",
    },
    padding: "5%",
    marginTop: "40px",
    width: "40%",
    boxShadow: "13px 8px 15px 1px rgba(101,168,130,0.5)"
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.green,
    fontWeight: 'bold'
  },
  subtitle: {
    color: theme.palette.orange,
    fontWeight: 'bold'
  },
  titleName: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center"
  },
  subtitleName: {
    marginTop: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center"
  },
  emailDomain: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  textField1: {
    marginTop: theme.spacing(2),
    marginRight: "10px"
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: "18px"
  },
  logo: {
    height: "60px"
  }
}));

export const ResetPassword = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);


  const processUrl = () => {
    return query.get("token");
  };

  const classes = useStyles();

  const {t} = useTranslation('common');

  const schema = constraints(t);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: "",
      passwordConfirm: "",
    },
    touched: {},
    errors: {},
    flag: true,
  });


  useEffect(() => {
    const errors = validate(formState.values, schema, {fullMessages: false});

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field) => {
    if (formState.errors[field]) {
      if (formState.touched[field]) {
        return true;
      }
    }
    return false;
  }
  const handleResettingPassword = (event) => {

    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      touched: {
        password: true,
      },
    })); 
    const {values} = formState;
    dispatch(resettingPassword({password: values.password, token: processUrl()}, history));
  };

  return (
    <Container component="main" maxWidth="xl">
      <Toolbar className={classes.headerContainer}>
        <RouterLink to="/">
          <img
            className={classes.logo}
            alt="logo"
            src="/images/logos/logo-blue.png"
          />
        </RouterLink>
        <div>
          <Typography className={classes.title} variant="h1">
            {t("app.name")}
          </Typography>
          <Typography className={classes.subtitle} variant="h3" gutterBottom>
            {t("login-school.subtitle")}
          </Typography>
        </div>
        <div className={classes.space} />
      </Toolbar>
      <div className={classes.paper}>
        <Paper elevation={5} className={classes.paperC}>
          <Typography className={classes.subtitleName}
                      variant="h1"
                      gutterBottom>
                      {t('login.form.reset-password')}
          </Typography>
          <form className={classes.form} onSubmit={handleResettingPassword} >
          <TextField
            className={classes.textField}
            error={hasError("password")}
            fullWidth
            helperText={hasError("password") ? formState.errors.password[0] : null}
            margin="normal"
            label={t("login.form.new-password")}
            name="password"
            onChange={handleChange}
            type="password"
            value={formState.values.password || ""}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError("passwordConfirm")}
            fullWidth
            helperText={
              hasError("passwordConfirm")
                ? formState.errors.passwordConfirm[0]
                : null
            }
            margin="normal"
            label={t("registration-partner.form.inputs.confirmPassword")}
            name="passwordConfirm"
            onChange={handleChange}
            type="password"
            value={formState.values.passwordConfirm || ""}
            variant="outlined"
          />
            <Button className={classes.submit} color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained" >
                    {t('login.form.reset-password')}
            </Button>
          </form>
        </Paper>
      </div>
      <Footer/>
    </Container>
  );
};

export default ResetPassword;
