import React, { useEffect, useState } from "react";
import { Switch, Redirect, BrowserRouter } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from "./components/layouts";
import {
  Home as HomeView,
  UserList as UserListView,
  SchoolRouter as SchoolListView,
  SchoolStudentsForm as StudentsView,
  StudentList as StudentListView,
  SignUp as SignUpView,
  ValidateRegisterIs,
  ValidateRegisterPs,
  ValidateRegisterCs,
  ConfirmationRegisterIs as confirmationRegisterIsView,
  SignIn as SignInView,
  SignInBySchoolSwitch as SignInBySchoolView,
  NotFound as NotFoundView,
  Dashboard as DashboardView,
  Contact as ContactView,
  Documents as DocumentsView,
  DeclareClaim as DeclareClaimView,
  HealthMobilityCertificate as HealthMobilityCertificateView,
  InsuranceRouter as InsuranceView,
  ClaimsMonitoring as ClaimsMonitoringView,
  PaymentRedirect as PaymentRedirectionView,
  PaymentRedirectHome as PaymentRedirectionHomeView,
  PaymentRedirectMultimedia as PaymentRedirectionMultimediaView,
  HouseInsurance as HouseInsuranceView,
  MultimediaInsurance as MultimediaInsuranceView,
  RedirectHealthMutual as RedirectHealthMutualView,
  SignView as SignSellAndSignView

} from "./views";

import HealthMobilityEstimationPage from "./views/InsuranceEstimation/HealthMobilityEstimationPage";
import HabitationEstimationPage from "./views/InsuranceEstimation/HabitationEstimationPage";

import ResetPassword from "./views/ResetPassword/ResetPassword";

import LocalStorageService from "./services/LocalStorageService";
import { isAuthenticated } from "./services/utils";

export const AppRouter = () => {
  const currentUser = LocalStorageService.getUser();
  const [isConnected, setIsconnected] = useState(false);

  useEffect(() => {
    if (!currentUser.email) {
      const data = isAuthenticated();
      if (!data) {
        setIsconnected(false);
      }
    }else{
      setIsconnected(true);
    }
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <PrivateRoute
          component={HomeView}
          exact
          layout={MainLayout}
          path="/home"
        />
        <PrivateRoute
          component={DashboardView}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
        <PrivateRoute
          component={UserListView}
          exact
          layout={MainLayout}
          path="/users"
        />
        <PrivateRoute
          component={SchoolListView}
          exact
          layout={MainLayout}
          path="/schools"
        />
        <PrivateRoute
          component={StudentsView}
          exact
          layout={MainLayout}
          path="/student/create"
        />
        <PrivateRoute
          component={StudentListView}
          exact
          layout={MainLayout}
          path="/student/list"
        />
        <PrivateRoute
          component={ContactView}
          exact
          layout={MainLayout}
          image={"/images/contact.jpg"}
          imageStyle={"diag"}
          direction={'left'}
          path="/contact"
        />
        <PrivateRoute
          component={DeclareClaimView}
          exact
          layout={MainLayout}
          path="/claims/declare"
        />
        <PrivateRoute
          component={DocumentsView}
          exact
          layout={MainLayout}
          path="/documents"
        />
        <PrivateRoute
          component={HouseInsuranceView}
          exact
          layout={MainLayout}
          path="/insurance/housing"
        />
        <PublicRoute
          component={HouseInsuranceView}
          exact
          layout={MinimalLayout}
          path="/insurance/housing/not-connected"
        />
        <PrivateRoute
          component={ InsuranceView }
          exact
          layout={ MainLayout}
          image={'/images/travel_picture_1.jpg'}
          imageStyle= {'diag'}
          direction={'left'}
          path="/insurance/health-mobility" />
        <PrivateRoute
          component={ MultimediaInsuranceView }
          exact
          layout={ MainLayout}
          direction={'right'}
          path="/insurance/multimedia" />
        <PublicRoute
          component={ HealthMobilityCertificateView }
          exact
          layout={MainLayout}
          path="/insurance/health-mobility/certificate"
        />
        <PublicRoute
          component={ RedirectHealthMutualView }
          exact
          layout={MainLayout}
          path="/insurance/health-mutual"
        />       
        <PublicRoute
          component={SignSellAndSignView}
          exact
          layout={MinimalLayout}
          path="/insurance/sign"
        />
        <PublicRoute
          component={ HealthMobilityEstimationPage }
          exact
          layout={MinimalLayout}
          path="/estimation/health-mobility"
        />
        <PublicRoute
          component={ HabitationEstimationPage }
          exact
          layout={MinimalLayout}
          path="/estimation/habitation"
        />
        <PublicRoute
          component={ ResetPassword }
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/forgot-password"
        />
        <PublicRoute
          component={ PaymentRedirectionView }
          exact
          layout={MainLayout}
          path="/checkout"
        />
        <PublicRoute
          component={ PaymentRedirectionHomeView }
          exact
          layout={MainLayout}
          path="/home_checkout"
        />
        <PublicRoute
          component={ PaymentRedirectionMultimediaView }
          exact
          layout={MainLayout}
          path="/multimedia_checkout"
        />      
        <PublicRoute
          component={SignInView}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/login"
        />
        <PublicRoute
          component={SignInBySchoolView}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/login/:schoolName"
        />
        <PublicRoute
          component={SignUpView}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/register"
        />
        <PublicRoute
          component={ValidateRegisterIs}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/confirmation_is"
        />
        <PublicRoute
          component={ValidateRegisterPs}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/confirmation_ps"
        />
        <PublicRoute
          component={ValidateRegisterCs}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/confirmation_cs"
        />
        <PublicRoute
          component={confirmationRegisterIsView}
          exact
          layout={MinimalLayout}
          image={"/images/home_picture1.jpg"}
          path="/register_is"
        />
        <PrivateRoute
          component={ClaimsMonitoringView}
          exact
          layout={MainLayout}
          path="/claims/my-claims"
        />
        <PublicRoute
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <Redirect to="/home" />
      </Switch>
    </BrowserRouter>
  );
};
