/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
  },
  subitem: {
    paddingLeft: '10px'
  },
  button: {
    color: 'black',
    padding: '6px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 'bold',
    fontSize: '24px',
    '&:hover': {
      background: 'transparent',
    }
  },
  button_subitem: {
    color: 'black',
    padding: '4px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 'bold',
    fontSize: '15px',
    '&:hover': {
      background: 'transparent',
    }
  },
  active: {
    color: theme.palette.orange,
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const [itemState, setItemState] = useState({});

  const handleClick = (item) => {
    setItemState(prevState => (
      { [item]: !prevState[item] }
    ))
  }

  const handler = (children, subitems) => {
    return children.map(item => {
      if (!item.items) {
        return (
          <div key={ item.title }>
            <ListItem
              button
              className={subitems ? classes.subitem : classes.item}
              disableGutters
              key={ item.title }>
              <Button
                activeClassName={classes.active}
                className={subitems ? classes.button_subitem : classes.button}
                component={CustomRouterLink}
                to={item.href}>
                {item.title}
              </Button>
            </ListItem>
          </div>
        )
      }
      return (
        <div key={ item.title }>
          <ListItem
            button
            className={classes.item}
            disableGutters
            onClick={ () => handleClick(item.title) }>
            <ListItemText primary={ item.title } />
            { itemState[ item.title ] ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse
            in={ itemState[ item.title ] }
            timeout="auto"
            unmountOnExit>
            { handler(item.items, true) }
          </Collapse>
        </div>
      )
    })
  };

  return (
    <List
      {...rest}
      className={clsx(className)}
    >
      { handler(pages, false) }
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
