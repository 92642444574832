import { toastr } from 'react-redux-toastr'
import * as actionTypes from './actionTypes'
import { appLoading, appSuccess, appError } from './appActions'
import {contact} from '../../api/contactApi'

export const defaultReceiver = process.env.REACT_APP_DEFAULT_MAIL_RECEIVER;

export const contactForm = (payload) => (dispatch) => {
    const data  = {
        recipient: defaultReceiver,
        subject: payload.values.subject,
        body: payload.values.body
     };
    dispatch(appLoading())
    contact(data)
     .then((response) => {
       dispatch(appSuccess());
       toastr.success('INFO', 'L\'email a bien été envoyé.');
     })
     .catch((error) => {
       dispatch(appError());
       if(error && error.response && error.response.data){
             toastr.error('ERROR', error.response.data.message+ '. ' +error.message);
             console.log('Error: ', error.response.data.message+ '. ' +error.message);
         } else {
             toastr.error('ERROR', 'Une erreur est survenue lors de la connexion au serveur.');
             console.log('Error: ', 'error connected to server');
         }
     })
 }