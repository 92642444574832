import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {Grid, Typography} from "@material-ui/core";
import FlightIcon from "@material-ui/icons/Flight";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from '@material-ui/icons/Add';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Link as RouterLink } from 'react-router-dom';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router"
import PropTypes, { string } from 'prop-types';

import { PartnerShipLevel } from "../Schools/utils/PartnerShipLevel";
import validate from "validate.js";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import {Roles} from "../../common/Roles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { redirectPaymentPageMobility} from "../../services/utils";
import Paper from "@material-ui/core/Paper";
import { getSchoolByStudent } from "../../redux/actions/schoolsActions";


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 55px 0 55px',
    flexGrow: 1,
    backgroundColor: "white",
    height: "350px",
  },
  container: {
     display: 'flex',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly'
  },
  categoryTitle : {
     textTransform: 'uppercase',
     color :'#8ECECE',
     fontWeight: 'bold',
  },
  contentContainer: {
     marginTop: '6px',
     paddingBottom: "0px!important",
     [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "0px!important",
      "& h4": {
        fontSize: "8px",
      }, 
    },
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
    "& label": {
      color: "#2F6472",
      fontSize: "28px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "13px",
      },
    },
  },
  planeIcon: {
    color :'#6eaff5',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    transform: 'rotate(45deg)',
    width: '40px',
    height: '40px'
   },
   locationIcon: {
    color :'#2F6472',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    width: '40px',
    height: '40px'
   },
   formControl: {
    width: "180px",
  },
   multimediaIcon: {
    color :'#8ECECE',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    width: '40px',
    height: '40px'
   },
   plusIcon: {
     color :'#23009A',
     marginRight: '1%'
   },

   wrapIcon : {
    verticalAlign: 'middle',
    display: 'inline-flex',
    fontWeight: 'bold',
    color: 'black'
   },
   selectInsurance: {
    width: '65%',
    top: '-20px',
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
    }, 
   },
   linkDownLoad: {
     width: '100%',
     display: 'flex',
     fontWeight: '700',
     textAlign: 'center',
     fontSize: '20px',
     letterSpacing: '0px',
     color: '#000000',
     opacity: '1',
     cursor: 'pointer'
   },
  linkDownloadDisabled: {
    width: '100%',
    display: 'flex',
    fontWeight: '700',
    textAlign: 'center',
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#8d8b8b',
    opacity: '1',
  },
   linkPlus: {
     width: '100%',
     display: 'flex',
     color: '#23009A',
     marginTop:'60px'
   },
   textPlus: {
    fontSize: '15px',
    marginTop: '8px'
   },
   paragraphContainer: {
    display: 'flex',
    width: '100%',
    height: '80%'
   },
   contentParagraphs: {
     display: 'flex',
     width: '100%',
     height: '100%',
     marginTop: '70px',
     flexDirection: 'row',
   },
   contentParagraphsColumn: {
     display: 'flex',
     width: '50%',
     height: '100%',
     flexDirection: 'column',
   },
   logo: {
     width: '80px',
     height: '25px',
     marginRight: '5px'
   },
   logobg: {
     width: "83px",
     minHeight: "44px",
     backgroundSize: "contain",
     backgroundRepeat: "no-repeat",
     backgroundPosition: "center left",
     marginTop: "3px",
     marginRight: "10px"
   },
   line1: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: '5px',
    marginBottom: '5px'
   },
   subtitle: {
     display: 'flex',
     color: 'black',
     fontWeight: '700',
     fontSize: '15px',
     lineHeight: '18px',
     alignSelf: "flex-end"
   },
   paragraph: {
     textAlign: 'left',
     font: 'normal normal normal',
     letterSpacing: '0px',
     color: '#000000',
     opacity: '1',
     fontSize: '13px'
   },
  textInput: {
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '1'
  },
  payContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: '20px'
  },
  priceText: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '30px',
    alignItems: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px'
},
  payButton: {
    width: '240px',
    color: '#2F6472',
    backgroundColor: 'white',
    fontSize: "20px",
    fontWeight: "bold"
  },
  dialogContent: {
    width: '500px'
  },
  dialogTitle: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '30px',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: '1',
    margin: '20px'
  },
  dialogSquare: {
    height: '200px',
    margin: '20px',
    padding: '10px'
  },
  dialogSquareContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '5px'
  },
  dialogContentCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogItemContent: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '15px',
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '20px'
  },
  dialogSubTitle: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '16px',
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '10px'
  },
  itemResponsivePickAndGo: {
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: "8px",
      borderTop: "solid #8ECECE",    
      "& h4": {
        fontoSize: "26px",
      },
    },
    titleRoom: {
      color: "#2F6472",
      fontSize: "28px",
    },
  },
  buttonLink: {
    textAlign: 'center',
    "& a": {
      padding: "23px",
      background: "#28A745",
      color: "white",
      fontSize: "22px",
      fontWeight: "400",
    }
  },
  contentAnnualPrice: {
    paddingTop: "0px!important",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "0px!important",
      "& h4": {
        fontSize: "8px",
      }, 
    },
  },
  priceMensual: {
    fontSize: "12px",
    marginLeft: "3px",
    [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "8px",
    },
  },
  estimationPrice: {
    fontSize: "23px",
    color: "#28A745",
    marginLeft: "5px",
  }
}));

export const HabitationEstimationPage = (props) => {

  const classes = useStyles();
  const {dispatch} = props;

  const {t} = useTranslation('common');


  const history = useHistory();

  let [value, onChange] = useState([new Date(), new Date()]);

  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState({mensual: null , annual: null});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [newData, setNewData] = useState({
    rooms: 0,
    roommates: 0,
    address: {},
  });
  const handleRooms = (event) => {
    setNewData((data) => ({
      ...data,
      rooms: event.target.value,
    }));
    if(event.target.value == 1){
      setPrice({mensual: 6.5 , annual: 75});
    }else if(event.target.value == 2){
      setPrice({mensual: 8.23 , annual: 95});
    }else if(event.target.value == 3){
      setPrice({mensual: 9.96 , annual: 115});
    }else if(event.target.value == 4){
      setPrice({mensual: 25.84 , annual: 310});
    }else if(event.target.value == 5){
      setPrice({mensual: 29.17 , annual: 350});
    }else if(event.target.value == 6){
      setPrice({mensual: 35.84 , annual: 430});
    }
  };
  const handleRoommates = (event) => {
    setNewData((data) => ({
      ...data,
      roommates: event.target.value,
    }));
  };

  return (
      <div className={classes.root}>
        <Grid container justify="left" alignItems="center" spacing={8}>
            <Grid item xs={12} sm={12} align="left" className={classes.contentContainer}>
              <div className={classes.itemContent}>
              <InputLabel shrink className={classes.titleRoom} >
              {t("insurance.housing.step1.numberOfRooms")}
              </InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  value={newData.rooms}
                  onChange={handleRooms}
                  required
                >
                  <MenuItem value={0}>{ "  " }</MenuItem>
                  <MenuItem value={1}>{t("insurance.housing.step1.rooms.1")}</MenuItem>
                  <MenuItem value={2}>{t("insurance.housing.step1.rooms.2")}</MenuItem>
                  <MenuItem value={3}>{t("insurance.housing.step1.rooms.3")}</MenuItem>
                  <MenuItem value={4}>{t("insurance.housing.step1.rooms.4")}</MenuItem>
                  <MenuItem value={5}>{t("insurance.housing.step1.rooms.5")}</MenuItem>
                  <MenuItem value={6}>{t("insurance.housing.step1.rooms.6")}</MenuItem>
                </Select>
              </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} align="left" className={classes.contentContainer}>
              <Typography
                  className={classes.wrapIcon}
                  style={{textTransform: 'uppercase'}}
                  variant="h4">
                {t('insurance.cost')} {t('insurance.mensual')} : <span className={classes.estimationPrice} >{ price.mensual == null ? "" : price.mensual + "€" }</span> <span className={classes.priceMensual} > / {t('insurance.month')}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} align="left" className={classes.contentAnnualPrice}>
              <Typography
                  className={classes.wrapIcon}
                  style={{textTransform: 'uppercase'}}
                  variant="h4">
                {t('insurance.cost')} {t('insurance.annual')} : <span className={classes.estimationPrice} >{ price.mensual == null ? "" : price.annual + "€" }</span> <span className={classes.priceMensual} > / {t('insurance.year')}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} align="left" className={classes.buttonLink}>
              <RouterLink target="_blank" to="/login/classic" >
                {t('insurance.go')}
              </RouterLink>
            </Grid>
       </Grid>
      </div>
  );
 };

 HabitationEstimationPage.propTypes = {
    history: PropTypes.object,
};

export default HabitationEstimationPage;
