import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import { Grid, Typography, Avatar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import {useSelector} from "react-redux";
import { useDispatch } from "react-redux";
import {contactForm} from '../../redux/actions/contactActions'

const constraints = (t) => ({
    lastname: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    firstname: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    title: {
        presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    message: {
        presence: { allowEmpty: false, message: t('validation.field.required') },
    }
  });


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 16px 0 16px',
    flexGrow: 1,
    marginLeft: '6%',
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  container: {
    display: 'flex',
    padding: '50px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  categoryTitle : {
    textTransform: 'uppercase',
    color: '#66a882',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  subtitle: {
    marginTop: '2%'
  },
  form: {
    width: '75%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
    },
  },
  wrapIcon : {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginTop: theme.spacing(1),
    width: '100%',
    fontWeight: 'bold',
    color: 'black'
  },
  avatar: {
    width: 90,
    height: 90
  },
  icons: {
    color :'#4f73a4',
    marginRight: '1%'
  },
  councillorTitle: {
    marginTop: theme.spacing(1.5),
    fontWeight: 'bold',
    color: 'black'
  },
  councillorBlock: {
      marginTop: '15%'
  }
}));

export const Contact = () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const {t} = useTranslation('common');
  const schema = constraints(t);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleEmpty = () => {
    setFormState(formState => ({
      ...formState,
      values: {},
      touched: {}
    }))
  }


  const handleContact = event => {
    event.preventDefault();
    const { values } = formState
    const subject = 'Contact App Studcorp ' + values.lastname + " " + values.firstname;
    const body = '\n' + values.title + '\n' + '\n' + values.message;
    const data = { 
      subject: subject,
      body: body
    }
    dispatch(contactForm({ values: data}))
    handleEmpty();
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
                <Typography variant="h2" className={classes.categoryTitle}>
                    {t('contact.title')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.subtitle}>
                <Typography variant="h3" className={classes.categoryTitle}>
                    {t('contact.message-title')}
                </Typography>
                <Typography variant="h3" className={classes.title}>
                    <form className={classes.form}  onSubmit={handleContact} >
                        <TextField className={classes.textField} error={hasError('lastname')}  fullWidth
                                    helperText={ hasError('lastname') ? formState.errors.lastname[0] : null }
                                    margin="normal"
                                    label={t('contact.field.lastname')}
                                    name="lastname"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.lastname || ''}
                                    variant="outlined"
                                    />
                        <TextField className={classes.textField} error={hasError('firstname')} fullWidth
                                    helperText={ hasError('firstname') ? formState.errors.firstname[0] : null }
                                    margin="normal"
                                    label={t('contact.field.firstname')}
                                    name="firstname"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.firstname || ''}
                                    variant="outlined"
                                     />
                        <TextField className={classes.textField} error={hasError('title')} fullWidth
                                    helperText={ hasError('title') ? formState.errors.title[0] : null }
                                    margin="normal"
                                    label={t('contact.field.title')}
                                    name="title"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.title || ''}
                                    variant="outlined"
                                     />
                        <TextField className={classes.textField} error={hasError('message')} fullWidth
                                    helperText={ hasError('message') ? formState.errors.message[0] : null }
                                    margin="normal"
                                    rows="4"
                                    label={t('contact.field.message')}
                                    name="message"
                                    onChange={handleChange}
                                    multiline
                                    value={formState.values.message || ''}
                                    variant="outlined"
                                     />
                        <Button className={classes.submit} color="primary"
                                disabled={!formState.isValid}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained" >
                                {t('contact.field.button')}
                        </Button>
                    </form>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.subtitle}>
                <Typography variant="h3" className={classes.categoryTitle}>
                    {t('contact.phone-title')}
                </Typography>
                <Grid container spacing={2} className={classes.councillorBlock}>
                    <Grid item>
                        <Avatar
                        alt="SC"
                        className={classes.avatar}
                        src="/images/profil_arnaud.jpeg"
                        />
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography
                                className={classes.councillorTitle}
                                variant="h5"
                                >
                                    {t('contact.councillor.title')}
                                </Typography>
                                <Typography
                                className={classes.wrapIcon}
                                variant="subtitle2"
                                >
                                    <PhoneIcon className={classes.icons} />
                                    {t('contact.councillor.phone')}
                                </Typography>
                                <Typography
                                className={classes.wrapIcon}
                                variant="subtitle2"
                                >
                                  <MailIcon className={classes.icons} />
                                  {t('contact.email')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
};
