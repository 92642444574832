import * as actionTypes from '../actions/actionTypes'

const initialState = {
  data:  null,
  token: null,
  error: null,
}

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { token, data } = action.payload
      return { ...state, token, data }
    case actionTypes.AUTH_FAILED:
      return { ...state, data: {}, error: action.payload }
    case actionTypes.AUTH_LOGOUT:
      return { ...state, data: {}, token: null }
    default:
      return state
  }
}
