import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useScript} from "../../helpers";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  component: {
    padding: "5px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  addressInput: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;

const AutoCompleteAddress = (props) => {
  let autocomplete;
  let address1Field;

  const classes = useStyles();
  const {t} = useTranslation("common");

  const { setAddress, limitFrenchCountry } = props;
  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&libraries=places`
  );

  useEffect(() => {
    if (status === 'ready')
      initAutocomplete();
  }, [status]);

  const clear = () => {
    initAutocomplete()
    address1Field.focus();
  }

  function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");

    if (status === "ready") {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: limitFrenchCountry && {country: ["fr"]},
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
    } 
    autocomplete.addListener("place_changed", fillInAddress);
  }

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let streetAndNumber = "";
    let postcode = "";
    let country = "";
    let city = "";
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          streetAndNumber += component.long_name + " ";
          break;
        }
        case "route": {
          streetAndNumber += component.short_name;
          break;
        }
        case "postal_code": {
          postcode = component.long_name;
          break;
        }
        case "postal_code_suffix": {
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_1": {
          break;
        }
        case "country":
          country = component.short_name;
          break;
      }
    }
    let address = {};
    address.street = streetAndNumber;
    address.postalCode = postcode;
    address.city = city;
    address.country = country;

    if (setAddress) setAddress(address);

  }


  return (
    <div>
      <input name="autocomplete" id="autocomplete" type="hidden"/>
      <TextField id="ship-address" label={t("confirmation-pass.form.fillAddressBtn")} variant="outlined"
                 className={classes.addressInput}/>
    </div>
  );
}

export default AutoCompleteAddress;