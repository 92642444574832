import * as actionTypes from "./actionTypes";
import { appLoading, appSuccess, appError } from "./appActions";
import { toastr } from "react-redux-toastr";
import { fetchOfferBySchool } from "../../api/offersApi"

export const offerListSuccess = (data) => ({
  type: actionTypes.OFFER_LIST_SUCCESS,
  payload: data,
});

export const offerListFailed = (data) => ({
  type: actionTypes.OFFER_LIST_FAILED,
  payload: data,
});

export const offerList = (payload) => (dispatch) => {
  const { idSchool } = payload;

  dispatch(appLoading());
  fetchOfferBySchool(idSchool)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(offerListSuccess(data.data));
    })
    .catch((error) => {
      dispatch(offerListFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
    });
}
