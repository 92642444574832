export const CardLinksData = [
  {
    text: 't("app.offer-smartphone-card")',
    image: '/images/smartphone.jpg',
    link: '/insurance/health-mobility',
    onClick: "onChangePhone",
    color: '#F7C548',
    icon: 'smartphone',
    displayForCS: 'none'
  },
  {
    text: 't("app.offer-multimedia-card")',
    image: '/images/multimedia.jpg',
    link: '/insurance/multimedia',
    onClick: "onChangeMultimedia",
    color: '#F46036',
    icon: 'laptop'
  },
];
