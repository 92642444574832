import * as actionTypes from "../actions/actionTypes";

const initialState = {
  data: [],
  dataDetails: null,
  dataFirma: {},
};

export const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_COUNTRIES_SUCCESS:
      const { data } = action.payload;
      return { ...state, data };
    case actionTypes.LIST_COUNTRIES_FAILED:
      return { ...state, data: [], error: action.payload };
    case actionTypes.DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_SUCCESS:
      const dataDetails = action.payload;
      return { ...state, dataDetails };
    case actionTypes.DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_FAILED:
      return { ...state, data: [], error: action.payload };
    default:
      return state;
  }
};

export const houseInsuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRICE_SUCCESS:
      const { data } = action.payload;
      return { ...state, data };
    case actionTypes.GET_PRICE_FAILED:
      return { ...state, data: [], error: action.payload };
    case actionTypes.CREATE_ACCOUNT_HOME_INSURANCE_SUCCESS:
        const { dataAccount } = action.payload;
        return { ...state, createAccountResult: dataAccount };
    case actionTypes.CREATE_ACCOUNT_HOME_INSURANCE_FAILED:
        return { ...state, data: [], error: action.payload };
    case actionTypes.TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_SUCCESS:
       // debugger
      const { dataFirma } = action.payload;
      return { ...state, tokenDigitalFirma: dataFirma};
    case actionTypes.TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_FAILED:
      return { ...state, data: [], error: action.payload };
    default:
      return state;
  }
};

export const multimediaInsuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_PRICE_SUCCESS:
      const { data } = action.payload;
      return { ...state, data };
    case actionTypes.LIST_PRICE_FAILED:
      return { ...state, data: [], error: action.payload };
    case actionTypes.DETAIL_MULTIMEDIA_SUCCESS:
      const dataDetails = action.payload;
      return { ...state, dataDetails };
    case actionTypes.DETAIL_MULTIMEDIA_FAILED:
      return { ...state, data: [], error: action.payload };
    case actionTypes.CREATE_CONTRACT_MULTIMEDIA_INSURANCE_SUCCESS:
        return { ...state, dataDetails: action.payload };
    case actionTypes.CREATE_CONTRACT_MULTIMEDIA_INSURANCE_FAILED:
        return { ...state, data: [], error: action.payload };
    default:
      return state;
  }
};