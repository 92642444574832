import React, {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {Typography, Grid, Hidden} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch, connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {
  getAllClaimForLoginUser
} from "../../redux/actions/claimActions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
    marginLeft: "6%",
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: "0px",
    },
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  categoryTitle: {
    textTransform: "uppercase",
    color: "#A80000",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
  title: {
    marginBottom: "45px",
  },
  paper: {
    padding: theme.spacing(1), //grid padding
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  trClicked: {
    backgroundColor: "rgba(67, 116, 168, .08)"
  },
  divContainer: {
    padding: "10px"
  },

  divChild: {
    width: "50%",
    float: "left",
    marginBottom: "5px"
  },
  spanTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: "#000000",
  },
  divDocument: {
    display: "flow-root",
    marginTop: "15px",
    width: "90%",
    padding: "10px",
  },
  spanCour: {
    textAlign: "left",
    font: "normal normal normal 14px Helvetica Neue",
    letterSpacing: "0px",
    color: "#8ECECE",
    opacity: "1"
  },
  spanTraite: {
    textAlign: "left",
    font: "normal normal normal 14px Helvetica Neue",
    letterSpacing: "0px",
    color: "#2F6472",
    opacity: "1"
  },
  td: {
    textAlign: "left",
    font: "normal normal normal 14px Helvetica Neue",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1"
  },
  th: {
    borderRight: "solid",
    borderRightWidth: "thin",
    textAlign: "left",
    font: "normal normal normal 18px Helvetica Neue",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1"
  },
  thLast: {
    textAlign: "left",
    font: "normal normal normal 18px Helvetica Neue",
    letterSpacing: "0px",
    color: "#000000",
    opacity: "1"
  },
  imgDoc: {
    width: "49.25px",
    height: "61.56px",
    marginBottom: "-15px"
  },
}));

const ClaimsMonitoring = (props) => {
  const back_url = process.env.REACT_APP_BACKEND_URL;
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUserToken = useSelector((state) => state.auth.token);
  // const claims = useSelector((state) => state.claims);
  const {claims} = props;
  const {t} = useTranslation("common");
  const [selectClaim, setSelectClaim] = useState({
    id: "", title: "", creation_date: "", claim_documents: [], insurance_type: "", state: "", message: ""
  });

  useEffect(() => {
    dispatch(getAllClaimForLoginUser());
  }, []);

  useEffect(() => {
      if (claims.claims.length > 0) {
        setSelectClaim(claims.claims[0]);
      }
    },
    [claims]);

  const onClick = event => {
    const id = event.currentTarget.getAttribute("data-id");
    // this.setState({ clicked_id: id });
    const claim = claims.claims.filter(c => c.id == id);
    if (claim) {
      setSelectClaim(claim[0]);
    }
  };

  const formatDate = (date) => {
    const dateF = moment(new Date(date)).format("DD/MM/YYYY");
    return dateF;
  };

  const returnState = (status) => {
    if (status > 0) {
      return status === 1 ? <span className={classes.spanCour}>En cours</span> :
        <span className={classes.spanTraite}>Traité</span>
    }
    return '';
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.title} align="center" justify="center" alignItems="center">
          <Typography variant="h2" className={classes.categoryTitle}>
            {t("claim.monitoring.title")}
          </Typography>
        </Grid>
        {claims.claims.length > 0 ?
          (
            <Fragment>
              <Hidden only={['sm', 'md', 'lg', 'xl']}	>
              <Grid item xs={12} sm={5}>
                <Paper className={classes.paper}>
                  <div className={classes.divContainer}>
                    <div className={classes.divChild}>
                      <div><span className={classes.thLast}>{t('claim.monitoring.table.title')}&nbsp;:</span></div>
                      <span className={classes.td}>{selectClaim !== {} ? selectClaim.title : ''}</span>
                    </div>
                    <div className={classes.divChild}>
                      <div><span
                        className={classes.thLast}>{t('claim.monitoring.table.state')}&nbsp;:</span> {selectClaim !== {} ? returnState(selectClaim.state) : ''}
                      </div>
                    </div>

                  </div>

                  <div className={classes.divDocument}>
                    <div>
                      <span className={classes.thLast}>Message&nbsp;:</span>
                    </div>
                    <span className={classes.td}> {selectClaim ? selectClaim.message : ''}</span>
                  </div>

                  <div className={classes.divDocument}>
                    <div>
                      <span className={classes.thLast}>Document&nbsp;:</span>
                    </div>

                    {selectClaim.claim_documents.map((row) => (
                      <div key={row.id}>
                        <div>
                          <a href={`${back_url}/uploads/${row.fileName}`} target="_blank"><img src="/images/logos/document.svg"
                                                                               className={classes.imgDoc}/></a>
                        </div>
                        <a href={`${back_url}/uploads/${row.fileName}`} download>{row.title}</a>
                      </div>
                    ))}
                  </div>

                </Paper>
              </Grid>
              </Hidden>
              <Grid item xs={12} sm={7} >
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.th}>{t('claim.monitoring.table.title')}</TableCell>
                        <TableCell className={classes.th}>{t('claim.monitoring.table.date')}</TableCell>
                        <TableCell className={classes.th}>{t('claim.monitoring.table.type')}</TableCell>
                        <TableCell className={classes.thLast}>{t('claim.monitoring.table.state')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {claims.claims.map((row) => (
                        <TableRow key={row.id} data-id={row.id} onClick={onClick}
                                  className={row.id == selectClaim.id ? classes.trClicked : ""}>
                          <TableCell className={classes.td} component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell className={classes.td} align="right">{formatDate(row.creation_date)}</TableCell>
                          <TableCell className={classes.td} align="right">{row.insurance_type}</TableCell>
                          <TableCell className={classes.td} align="right">{returnState(row.state)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Hidden xsDown>
              <Grid item xs={12} sm={5}>
                <Paper className={classes.paper}>
                  <div className={classes.divContainer}>
                    <div className={classes.divChild}>
                      <div><span className={classes.thLast}>{t('claim.monitoring.table.title')}&nbsp;:</span></div>
                      <span className={classes.td}>{selectClaim !== {} ? selectClaim.title : ''}</span>
                    </div>
                    <div className={classes.divChild}>
                      <div><span
                        className={classes.thLast}>{t('claim.monitoring.table.state')}&nbsp;:</span> {selectClaim !== {} ? returnState(selectClaim.state) : ''}
                      </div>
                    </div>

                  </div>

                  <div className={classes.divDocument}>
                    <div>
                      <span className={classes.thLast}>Message&nbsp;:</span>
                    </div>
                    <span className={classes.td}> {selectClaim ? selectClaim.message : ''}</span>
                  </div>

                  <div className={classes.divDocument}>
                    <div>
                      <span className={classes.thLast}>Document&nbsp;:</span>
                    </div>

                    {selectClaim.claim_documents.map((row) => (
                      <div key={row.id}>
                        <div>
                          <a href={`${back_url}/uploads/${row.fileName}`} target="_blank"><img src="/images/logos/document.svg"
                                                                               className={classes.imgDoc}/></a>
                        </div>
                        <a href={`${back_url}/uploads/${row.fileName}`} download>{row.title}</a>
                      </div>
                    ))}
                  </div>

                </Paper>
              </Grid>
              </Hidden>
            </Fragment>
          )
          : (
            <Fragment>
              <Grid item xs={12} align="center" justify="center" alignItems="center">
                <Typography variant="h2">
                  {t('claim.monitoring.not-result')}
                </Typography>
              </Grid>

            </Fragment>
          )
        }

      </Grid>
    </div>
  );
};
//
const mapStateToProps = (state) => ({
  claims: state.claims,
});

export default connect(mapStateToProps)(ClaimsMonitoring);

