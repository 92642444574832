const LocalStorageService = (() => {
  let service
  function getService() {
    if (!service) {
      service = this
      return service
    }
    return service
  }

  function setUser(userObj) {
    let toUserStorage = userObj.data.user !== undefined ? userObj.data.user : userObj.data;
    let currentContracts = userObj.contracts !== undefined ? userObj.contracts : null;

    const contracts = [];
    if (currentContracts !== null){
      currentContracts.forEach(contract => {
        contracts.push(contract);
      }
      );
    }


    localStorage.setItem('user_email', toUserStorage.email);
    localStorage.setItem('user_lastname', toUserStorage.lastName);
    localStorage.setItem('user_name', toUserStorage.name);
    localStorage.setItem('user_role', toUserStorage.roles && toUserStorage.roles.length > 0 ? toUserStorage.roles[0] : "none");
    localStorage.setItem('school', userObj.school);
    localStorage.setItem('offers', userObj.offers && userObj.offers.length > 0 ? JSON.stringify(userObj.offers) : "none");
    localStorage.setItem('currentContracts', contracts && contracts.length > 0 ? JSON.stringify(contracts) : "none");

  }

  function getUser() {
    let user = {};
    user.email = localStorage.getItem('user_email');
    user.lastName = localStorage.getItem('user_lastname');
    user.name = localStorage.getItem('user_name');
    user.roles = [localStorage.getItem('user_role')];
    user.school = [localStorage.getItem('school')];
    user.offers = [localStorage.getItem('offers')];
    user.currentContracts = [localStorage.getItem('currentContracts')];
    return user;
  }

  function clearUser(){
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_lastname');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_role');
    localStorage.removeItem('school');
    localStorage.removeItem('offers');
    localStorage.removeItem('currentContracts');
  }

  function setToken(tokenObj) {
    localStorage.setItem('token', tokenObj.token)
    localStorage.setItem('refresh_token', tokenObj.refresh_token)
  }

  function getAccessToken() {
    return localStorage.getItem('token')
  }

  function getRefreshToken() {
    return localStorage.getItem('refresh_token')
  }

  function clearToken() {
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
  }

  function setHouseContract(obj) {
    localStorage.setItem('houseContract', JSON.stringify(obj));
  }

  function getHouseContract() {
    const value = localStorage.getItem('houseContract');
    return JSON.parse(value);
  }

  function removeHouseContract() {
    localStorage.removeItem('houseContract')
  }

  function setHealthMobilityContract(obj) {
    localStorage.setItem('healthMobilityContract', JSON.stringify(obj));
  }

  function getHealthMobilityContract() {
    const value = localStorage.getItem('healthMobilityContract');
    return JSON.parse(value);
  }

  function removeHealthMobilityContract() {
    localStorage.removeItem('healthMobilityContract')
  } 

  function setMultimediaContract(obj) {
    localStorage.setItem('multimediaContract', JSON.stringify(obj));
  }

  function getMultimediaContract() {
    const value = localStorage.getItem('multimediaContract');
    return JSON.parse(value);
  }

  function removeMultimediaContract() {
    localStorage.removeItem('multimediaContract')
  } 

  function setLanguage(obj) {
    localStorage.setItem('language', JSON.stringify(obj));
  }

  function getLanguage() {
    const value = localStorage.getItem('language');
    return JSON.parse(value);
  }


  return {
    getService,
    getUser,
    setUser,
    clearUser,
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken,
    setHouseContract,
    getHouseContract,
    removeHouseContract,
    setHealthMobilityContract,
    getHealthMobilityContract,
    removeHealthMobilityContract,
    setMultimediaContract,
    getMultimediaContract,
    removeMultimediaContract,
    setLanguage,
    getLanguage
  }
})()

export default LocalStorageService
