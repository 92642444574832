import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import {useSelector} from "react-redux";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LocalStorageService from "../../../../../../../services/LocalStorageService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  avatar: {
    width: 60,
    height: 60,
    marginBottom: "10px"
  },
  title: {
    marginTop: theme.spacing(1.5),
    fontWeight: 'bold',
    color: 'black',
    fontSize: "20px !important",
    marginLeft: "5px"
  },
  wrapIcon : {
    verticalAlign: 'middle',
    display: 'inline-flex',
    width: '100%',
    fontWeight: 'bold',
    color: 'black'
  },
  icons: {
    color :'#4f73a4',
    marginRight: '4%',
    width: "13px",
    height: "18px",
    paddingTop: "5px"
  },
  avatarContainer: {
    margin: '4px -8px -8px 8px!important'
  }
}));

const Profile = props => {
  const { className, ...rest } = props;
  const currentUser = LocalStorageService.getUser();
  const {t} = useTranslation("common");

  const classes = useStyles();

  const user = {
    name: currentUser.username,
    role: currentUser.roles,
    avatar: '/images/profil_arnaud.jpeg',
    title: t("navbar.advisor") + ' Arnaud',
    phone: '+33 6 02 64 85 96',
    mail: 'aportier@studcorp.com'
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(() => {
        if (user.role != "ROLE_ADMIN") {
          return (
            <Grid container spacing={0} className={classes.avatarContainer}>
              <Grid item>
                <Avatar
                alt="SC"
                className={classes.avatar}
                src={user.avatar}
                />
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      className={classes.title}
                      variant="h5"
                    >
                      {user.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="row" spacing={2}>
                  <Grid item xs>
                    <Typography
                      className={classes.wrapIcon}
                      variant="subtitle2"
                    >
                      <PhoneIcon className={classes.icons} />
                      {user.phone}
                    </Typography>
                    <Typography
                      className={classes.wrapIcon}
                      variant="subtitle2"
                    >
                      <MailIcon className={classes.icons} />
                      {user.mail}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        }
      })()}


    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
