import {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { CardLink, CardLinksData } from './components';
import LocalStorageService from "../../services/LocalStorageService";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  container: {
    display: 'flex',
    padding: '50px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  }
}));

export const Home = () => {
  const classes = useStyles();
  const currentUser = LocalStorageService.getUser();
  const {t} = useTranslation("common");

  const offerAvailableFilter = (item) => {
    if (currentUser.roles[0] === "ROLE_STUDENT_PARTNER") {
      if (JSON.parse(currentUser.offers).includes(item)) {
        return true;
      };
    }else{
      return true;
    };
  };


  const getCardFilter = () => {
    let listCard = [];
    CardLinksData.map((cardLink) => {
      if(offerAvailableFilter(cardLink.value)){
        switch (cardLink.text) {
          case 't("app.health-mobility-card")':
            cardLink = { ...cardLink, text: t("app.health-mobility-card")};
            break; 
          case 't("app.home-card")':
            cardLink = { ...cardLink, text: t("app.home-card")};
            break; 
          case 't("app.multimedia-card")':
            cardLink = { ...cardLink, text: t("app.multimedia-card")};
            break;
          case 't("app.health-mutual-card")':
            cardLink = { ...cardLink, text: t("app.health-mutual-card")};
            break;
          default:
            break;                
        }

        
        listCard.push(cardLink);
      }
    }
    )
    return listCard;
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.container}>
        {getCardFilter().map((card) =>
          ( <CardLink text={card.text}
                      image={card.image}
                      link={card.link}
                      color={card.color}
                      icon={card.icon}
                      displayForCS={card.displayForCS}
                      key={card.link}
                      newO={card.newO}
            /> )
        )}
      </Grid>
    </div>
  );
};
