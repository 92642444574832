import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Button, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../../../redux/actions/authActions';
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import LocalStorageService from "../../../../../services/LocalStorageService";
import { AccountCircle, Home, Laptop } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { redirectAccountStripeManagement } from "../../../../../services/utils";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import Lang from "../../../../../Lang";


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  toolbar: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#000"
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
        fontSize: "14px",
      },
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      fontSize: "14px",
    },
  },
}))(MenuItem);

const Topbar = props => {
  const [anchorEl, setAnchorEl] = useState(false);
  const contracts = useSelector((state) => state.contracts);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const { className, onSidebarOpen, ...rest } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  const [notifications] = useState([]);

  const { t } = useTranslation("common");

  const handleLogout = () => {
    dispatch(logoutUser());
  }
  const redirectAccountManagement = (typeContract) => {
    redirectAccountStripeManagement({typeContract: typeContract}, dispatch);
  }
  const currentUser = LocalStorageService.getUser();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="transparent"
      position="initial"
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
        {t("navbar.notification.welcome")}{currentUser.name} !
        <div>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleClick}
          >
            <AccountCircle />
          </IconButton>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={anchorEl}
            onClose={handleClose}
          >
            {contracts.hasCurrentHomeContract && (
              <StyledMenuItem onClick={() => redirectAccountManagement("home")}>
                <ListItemIcon>
                  <Home fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('navbar.Information_about_my_payments_Home')} />
              </StyledMenuItem>
            )}
            {contracts.hasCurrentMultimediaContract && (
              <StyledMenuItem onClick={() => redirectAccountManagement("multimedia")}>
                <ListItemIcon>
                  <Laptop fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t('navbar.Information_about_my_payments_Multimedia')} />
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleLogout}>
              <ListItemIcon>
                <InputIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('navbar.disconnection')} />
            </StyledMenuItem>
          </StyledMenu>
        </div>
        </Hidden>
        <Lang />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
