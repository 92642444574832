import * as actionTypes from "../actions/actionTypes";

const initialState = {
  claims: [],
};

export const claims = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.GET_ALL_CLAIMS_SUCCESS:
      const data = action.payload;
      return { ...state, claims: data }
    default:
      return state;
  }
};
