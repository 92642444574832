import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {Grid, Typography, Slider, Button, Input} from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import LocalStorageService from "../../../services/LocalStorageService";
import {detailMultimediaSuccess} from "../../../redux/actions/InsuranceActions";



const useStyles = makeStyles(theme => ({
  container: {
     display: 'flex',
     padding: '50px',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly',
  },
  modalTitle : {
     textTransform: 'uppercase',
     color :'white',
     fontWeight: 'bold',
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
  },
  information : {
    color :'#4374a8',
    fontWeight: 'bold',
 },
  contentContainer: {
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.between("xs", "sm")]: {
      display: 'block',
    }
  },
  moreInformation:{
    color: "#4374a8",
    fontSize: "11px",
    fontStyle: "italic",
    textTransform: "none"
  },
  buttonOffer: {
    background: "#8ecece",
    height: "250px",
    width: "250px",
    borderRadius: "0px",
    fontSize: "20px",
    color: "white",
    "&:hover":{
      background: "#8ecece9e",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100px",
      width: "100px",
      "& .MuiTypography-h4":{
        fontSize: "12px!important",
      }
    },
 },
 sliderContent: {
  color: "#F7C548",
  "& .MuiSlider-markLabelActive":{
    color: "#F7C548",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    "& .MuiSlider-markLabel":{
      display: "none!important",
    }
  },
},
 priceElement: {
    color: "white",
    fontSize: "23px",
    lineHeight: "inherit",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "20px",
    },
  },
headerModal: {
    padding: "40px",
    background: "#F7C548",
    position: "sticky",
    top: "0",
    zIndex: "9999",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: '10px',
    }
  },
padding30: {
  paddingLeft: "30px",
  paddingRight: "30px",
},
paddingTop30: {
  paddingTop: "30px",
},
paddingTop20: {
  paddingTop: "20px",
  [theme.breakpoints.between("xs", "sm")]: {
    paddingTop: "0px",
  },
},
categoryTitle : {
  color :'#000000c7',
  "& h4":{
    fontWeight: "600",
    wordBreak: "break-all",
  }
},
categoryContent: {
  display: "flex",
  marginTop: "20px",
  marginBottom: "30px",
  borderRadius: "0px",
  height: "80px",
  [theme.breakpoints.between("xs", "sm")]: {
    marginTop: "0px",
    marginBottom: "0px",
    height: "80px",
  },
  "& h6": {
    height: "auto",
  },
},
categoryContentButton: {
  marginTop: "10px",
  marginBottom: "30px",
},
submit: {
  padding: "10px",
  background: "white",
  color: "#F7C548",
  fontSize: "18px",
  fontWeight: "500",
  boxShadow: "none",
  "&:hover":{
    background: "#F7C548",
    color: "white",
  },
  [theme.breakpoints.between("xs", "md")]: {
    width: "100%",
    padding: "7px",
  },
},
colorDarkBlue: {
  background: "#2F6472",
  "& h6":{
    color: "white",
  },
  borderRight: "solid white 5px",
  borderLeft: "solid white 5px",
  [theme.breakpoints.between("xs", "sm")]: {
    borderRight: "solid white 0px",
    borderLeft: "solid white 0px",
  },
},
colorLightBlue: {
  background: "#8ECECE",
  "& h6":{
    color: "white",
  }
},
purchasePriceInput: {
  textAlign: "center",
  marginTop: "-20px",
  "& div": {
    width: "70px",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    "& div": {
      width: "100%",
    }
  },
},
middleItem:{
  display: "flex",
},
buttonContainer: {
  [theme.breakpoints.between("xs", "md")]: {
    padding: "7px",
    width: "100%",
  },
}
}));

 export const PhoneOfferStep1 = ({setOpen, setContextOfferSelectionned}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const {t} = useTranslation('common');
  const [value, setValue] = useState(300.00);
  const [valueLimit, setValueLimit] = useState([]);
  const [price, setPrice] = useState();
  const service = LocalStorageService.getService();

  const multimediaInsurance = useSelector((state) => state.multimediaInsurance);
  
  useEffect(() => {
    if(multimediaInsurance.data.length !== valueLimit.length){
      multimediaInsurance.data.forEach(element => {
        setValueLimit(oldArray => [...oldArray, {value: element.trancheMax, label: element.trancheMax}]);
      });
    }
  }, [multimediaInsurance.data]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let checkValue = value;
    if(checkValue === ''){
      checkValue = 1;
    }

    const insuranceSelected = multimediaInsurance.data.find(element => checkValue >= element.trancheMin && checkValue <= element.trancheMax);
    if(insuranceSelected !== undefined){
      setPrice(insuranceSelected.unit_amount / 100);
      dispatch(detailMultimediaSuccess(insuranceSelected));
    }

  }, [multimediaInsurance.data, value,]);

  const goPhoneOffer = () =>{
    setContextOfferSelectionned("smartphone");
    const multimediaContractService = { ...multimediaInsurance.dataDetails, insuredDevice: {purchasePriceTTC: value }};
    service.setMultimediaContract( multimediaContractService );
    setOpen(false);
  }
  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 2500) {
      setValue(2500);
    }
  };
  return (
    <>
      <Grid container className={classes.headerModal} justifyContent="center">
          <Grid item align="center" className={[classes.middleItem]} justify="center" alignItems="center" xs={4}>
            <Typography variant="h2" className={classes.priceElement}>
            {t('insurance.multimedia.totalCost')} {price} {t('insurance.multimedia.€perMonth')}
            </Typography>
          </Grid>
          <Grid item xs={5} className={[classes.middleItem]} align="center" justifyContent="center" alignItems="center">
            <Typography variant="h2" className={classes.modalTitle}>
              {t('insurance.multimedia.phoneOffer')}
            </Typography>
          </Grid>
          <Grid className={[classes.middleItem, classes.buttonContainer]} item align="center" justify="center" alignItems="center" md={12} lg={3}>
            <Button className={classes.submit}
              disabled={multimediaInsurance.data.length === 0 ? true : false}
              size="large"
              type="button"
              onClick={goPhoneOffer}
              variant="contained" >
              {t('insurance.multimedia.subscribe')}
            </Button>
          </Grid>
       </Grid>
       <Grid container className={[classes.padding30, classes.paddingTop30, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.purchasePrice')} {': ' + parseFloat(value).toFixed(2) + ' €'} 
          </Typography>
        </Grid>
      </Grid>
       <Grid container className={classes.padding30} spacing={2} alignItems="center">
        <Grid item xs={10}>
           <Slider
             className={classes.sliderContent}
             disabled={multimediaInsurance.data.length === 0 ? true : false}
             value={typeof value === 'number' ? value : 0}
             onChange={handleSliderChange}
             aria-labelledby="input-slider"
             max={2500}
             min={0}
             marks={valueLimit}
           />
        </Grid>
        <Grid item xs={2} className={classes.purchasePriceInput}>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }} 
          /> €
        </Grid>
      </Grid>
      <Grid container className={[classes.padding30, classes.paddingTop20, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.guarantee')}
          </Typography>
        </Grid>
      </Grid>
       <Grid container className={classes.padding30} spacing={2} alignItems="center">
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {t('insurance.multimedia.smartphone.guarantee1')}
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {t('insurance.multimedia.smartphone.guarantee2')}
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {t('insurance.multimedia.smartphone.guarantee3')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.condition')}
          </Typography>
        </Grid>
      </Grid>
       <Grid container className={classes.padding30} spacing={2} alignItems="center">
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {t('insurance.multimedia.smartphone.condition1')}
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {t('insurance.multimedia.smartphone.condition2')}
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {t('insurance.multimedia.smartphone.condition3')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">

      </Grid>
    </>
  );
 };

 PhoneOfferStep1.propTypes = {
  setOpen: PropTypes.func,
  setContextOfferSelectionned: PropTypes.func,
};

 export default PhoneOfferStep1;