import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Topbar } from "./components";
import Footer from "../Main/components/Footer";

const useStyles = makeStyles(theme => ({
  root: {
    /* paddingTop: 64,*/
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    height: "100%",
    marginBottom: "15px",
    background: "white",
  },
  imgContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "absolute",
    flexDirection: "row",
    justifyContent: "flex-end",
    "pointer-events": "none"
  },
  img: {
    display: "flex",
    width: "80%",
    opacity: "0.16",
    height:  "86.9%",
    zIndex: "-1",
    position: "absolute",
    marginTop: "100px",
    right: "0px",
    clipPath: "polygon(50% 0, 100% 0, 100% 100%, 0 100%)",
    bottom: "0",
    backgroundSize: "100%",
    backgroundPosition: "top",
    [theme.breakpoints.between("xs", "sm")]: {
      backgroundSize: "cover",
      position: "fixed",
      height: "80%",
    },
  },
  footer: {
    marginBottom: "-12px",
  },
}));

const Minimal = (props) => {
  const { children, image } = props;

  const classes = useStyles();

  const isCustomLogin = () => {
    /*if (children && children.props.location && !children.props.location.pathname.match("/register_is"))
         return ( <Topbar />);*/
  };

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer}>
        {image != null && <div style={{backgroundImage: 'url('+image+')'}} className={classes.img} alt={""} />}
      </div>
      {isCustomLogin()}
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  image: PropTypes.string,
};

export default Minimal;
