import * as actionTypes from '../actions/actionTypes'

const initialState = {
   data: {}
}

export const validateRegisterIs = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VALIDATE_REGISTER_IS_SUCCESS:
            const { data} = action.payload
            return { ...state, data }
        case actionTypes.VALIDATE_REGISTER_IS_FAILED:
            return { ...state, userIs: null, error: action.payload }
        default:
            return state
    }
}

