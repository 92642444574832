import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FlightIcon from "@material-ui/icons/Flight";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import { DocumentsComponent } from "./DocumentsComponent";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: theme.spacing(4),
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
    marginTop: theme.spacing(1),
    width: "100%",
    fontWeight: "bold",
    color: "black",
  },
  yearText: {
    textTransform: "uppercase",
    marginTop: "1%",
  },
  filterTextTypo: {
    marginTop: "1%",
  },
  filterLink: {
    color: "#040f89",
  },
  formControl: {
    margin: "0px 8px 8px 8px",
    minWidth: 120,
  },
  planeIcon: {
    color: "#6eaff5",
    marginRight: "1%",
  },
  multimediaIcon: {
    color: "#8ECECE",
    marginRight: "1%",
  },
  locationIcon: {
    color: "#2F6472",
    marginRight: "1%",
  },
}));

export const DocumentsSection = (props) => {
  const { documents, type } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  const [selectedYear, setSelectedYear] = useState("");
  const [docYears, setDocYears] = useState([]);
  const [orderedDocs, setOrderedDocs] = useState([]);

  const handleYearFilterChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const resetYearFilter = () => {
    setSelectedYear("");
  };

  function removeDuplicates(data) {
    return data.filter((value, index) => data.indexOf(value) == index);
  }

  const getTitle = () => ({
    sM: (
      <Typography
        className={classes.wrapIcon}
        style={{ textTransform: "uppercase" }}
        variant="h4"
      >
        <FlightIcon className={classes.planeIcon} />
        {t("docs.health.title")}
      </Typography>
    ),
    h: (
      <Typography
        className={classes.wrapIcon}
        style={{ textTransform: "uppercase" }}
        variant="h4"
      >
        <LocationOnIcon className={classes.locationIcon} />
        {t("docs.home.title")}
      </Typography>
    ),
    m: (
      <Typography
        className={classes.wrapIcon}
        style={{ textTransform: "uppercase" }}
        variant="h4"
      >
        <SaveAltIcon className={classes.multimediaIcon} />
        {t("docs.multimedia.title")}
      </Typography>
    ),
  });

  useEffect(() => {
    let years = [];
    documents.map((item, i) => {
      let year = item.createdAt.split("/");
      years.push(year[2]);
    });
    let properYears = removeDuplicates(years);
    setDocYears(properYears);

    let doc = {};
    let docs = [];
    documents.map((item, i) => {
      let createdYear = item.createdAt.split("/");
      doc = {
        name: item.document !== undefined ? item.document.fileName : "",
        year: createdYear[2],
        contractId: item.id,
        documentType: item.documentType,
        documentId: item.document !== undefined ? item.document.id : "",
      };
      docs.push(doc);
    });
    setOrderedDocs(docs);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        align="center"
        justify="center"
        alignItems="center"
        className={classes.contentContainer}
      >
        {getTitle()[type]}
      </Grid>
      <Grid item xs={6} container direction="row">
        <Typography className={classes.yearText} variant="h6">
          {t("docs.year")} {" :"}
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            value={selectedYear}
            onChange={handleYearFilterChange}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ "aria-label": "Year" }}
          >
            <MenuItem value="">
              <em>Toutes</em>
            </MenuItem>
            {docYears.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className={classes.filterTextTypo}>
          <Link
            href="#"
            onClick={resetYearFilter}
            variant="body2"
            className={classes.filterLink}
          >
            {t("docs.filter")}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} container direction="row">
        {selectedYear ? (
          <DocumentsComponent
            documents={orderedDocs.filter((doc) => doc.year === selectedYear)}
            year={selectedYear}
          />
        ) : (
          docYears.map((item, i) => (
            <DocumentsComponent
              key={i}
              documents={orderedDocs.filter((doc) => doc.year === item)}
              year={item}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};
