import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import LocalStorageService from "../../services/LocalStorageService";
import { createContractWithoutCertificate, createFrenchZoneHealthMobility } from "../../redux/actions/InsuranceActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
}));

export const PaymentRedirect = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { history } = props;

  const [result, setResult] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(count + 1);
    if (count <= 1) {
      processUrl();
    }
  }, []);


  const processUrl = () => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      // window.location.href = 'https://www.studcorp.com/formulaire-attestation';
      const service = LocalStorageService.getService();

      let healthMobilityContract = service.getHealthMobilityContract();
      healthMobilityContract = { ...healthMobilityContract, sessionStripe: query.get("session_id") }

      service.setHealthMobilityContract(healthMobilityContract);
      if (healthMobilityContract && healthMobilityContract.country && healthMobilityContract.country.id === 72) {
        dispatch(createFrenchZoneHealthMobility({ params: healthMobilityContract, history }));
      } else {
        dispatch(createContractWithoutCertificate({ params: healthMobilityContract, history }));
      }
    } else {
      // history.push("/insurance/health-mobility");
    }
  };

  return <div className={classes.root}>{result && result}</div>;
};
