import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import { useTranslation } from "react-i18next";
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 100000,
    color: '#239f1b',
  },
  circularProgress: {
    color: '#26851f',
    marginTop: "20px",
    marginBottom: "20px",
  },
  successIcon: {
    color: '#239f1b', // couleur pour l'icône de succès
    fontSize: 40, // ajustez la taille de l'icône selon vos besoins
    marginBottom: 10, // ajustez le positionnement si nécessaire
  },
  box:{
    textAlign: "center",
    padding: "23px"
  },
  logo: {
    height: "40px",
  },
  titleStudcorp: {
    color: "#2F6472",
  },
  textRedirection: {
    color: "#2F6472c9"
  }
}))

const Loading = () => {
  const classes = useStyles()
  const loading = useSelector((state) => state.app.loading)
  const redirect = useSelector((state) => state.app.redirect)
  const successMessage = useSelector((state) => state.app.successMessage)

  const {t} = useTranslation('common');

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        {redirect && (
          <Card className={classes.box}>
          <Typography variant="h4" className={classes.titleStudcorp}>
            STUDCORP
          </Typography>
          <CircularProgress className={classes.circularProgress} />
          <Typography variant="h6" className={classes.textRedirection}>
            {t("app.redirect-message")}
          </Typography>
          </Card>
        )}
        {!redirect && (
          <CircularProgress className={classes.circularProgress} />
        )}
        {successMessage.active && (
          <Card className={classes.box}>
            <Typography variant="h4" className={classes.titleStudcorp}>
              STUDCORP
            </Typography>
            <CheckCircleOutlineIcon className={classes.successIcon} />
            <Typography variant="h6" className={classes.textRedirection}>
              {successMessage.message}
            </Typography>
          </Card>
          )}
      </Backdrop>
    </div>
  )
}

export default Loading
