import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {loginUser} from "../../../../../redux/actions/authActions";
import {getSchoolDetails} from "../../../../../redux/actions/schoolsActions";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import validate from "validate.js";
import Checkbox from "@material-ui/core/Checkbox";
import {PartnerShipLevel} from "../../../../Schools/utils/PartnerShipLevel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {registerStudentCs} from "../../../../../redux/actions/studentsActions";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import moment from "moment";
import AutoCompleteAddress from "../../../../../components/AutoCompleteAddress/AutoCompleteAddress";
import validator from 'validator'
import cgv from "../../../../../assets/pdfs/Conditions_générales_de_vente.pdf";

const validatePhoneNumber = (number) => {
 const isValidPhoneNumber = validator.isMobilePhone(number)
 return (isValidPhoneNumber)
}


const constraints = (t) => ({
  prenom: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },
  nom: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },
  phoneNumber: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },
  emailUser: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
    format: {
      pattern: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
      message: t('validation.field.email.incorrect')
    }
  },
  password: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
    format: {
      pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      message: t('validation.field.password.incorrect')
    }
  },
  passwordConfirm: { 
    presence: {allowEmpty: false, message: t("validation.field.required")},
    equality: {
      attribute: "password",
      message: t("validation.field.password.must.match"),
      comparator: function (v1, v2) {
        return v1 === v2;
      },
    },
  },
  acceptTerm: {
    presence: {
      message: t("validation.field.required"),
    },
    inclusion: {
      within: [true],
      message: "^You need accept term",
    },
  },
  civilite: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },
  birthday: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },

});

const useStyles = makeStyles((theme) => ({
  form: {
    width: "50%",
    marginTop: theme.spacing(1),
    float: "right",
    borderLeft: "1px solid " + theme.palette.green,
    paddingLeft: "33px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  emailDomain: {
    display: "flex",
    flexDirection: "row",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  textField1: {
    marginTop: theme.spacing(2),
    marginRight: "10px",
  },
  titleName: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center",
    marginBottom: "72px",

  },
  fieldset: {
    width: "100%",
  },
  date: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "15px",
  },
  error: {
    color: "red",
  },
  helperError: {
    marginTop: "-10px",
    marginLeft: "14px",
  }
}));

const Registration = (props) => {
  const classes = useStyles();
  const {school, dispatch, history} = props;
  const {t} = useTranslation("common");
  const schema = constraints(t);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const [helperAcceptTerm, setHelperAcceptTerm] = useState('');
  const [helperCivility, setHelperCivility] = useState('');
  const [helperPhoneNumber, setHelperPhoneNumber] = useState('');
  const [helperAddress, setHelperAddress] = useState('');
  const [address, setAddress] = useState({});

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      prenom: "",
      nom: "",
      emailUser: "",
      domain: "",
      password: "",
      passwordConfirm: "",
      acceptTerm: false,
      birthday: "",
      civilite: "",
      phoneNumber: ""
    },
    touched: {},
    errors: {},
    flag: true,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema, {fullMessages: false});

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleRegistration = (event) => {
    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      touched: {
        prenom: true,
        nom: true,
        emailUser: true,
        password: true,
        passwordConfirm: true,
        acceptTerm: true,
        birthday: true,
        civilite: true,
        phoneNumber: true
      },
    }));
    hasError('civilite');
    hasError('phoneNumber');
    hasError('acceptTerm');

    const addressValid = validateAddress();
    if( addressValid ){
      setHelperAddress('');
    }else{
      setHelperAddress(t("validation.field.required"));
    }
    if (Object.keys(formState.errors).length == 0 && addressValid) {
      const {values} = formState;
      const formatDate = moment(values.birthday.replace(/-/g, "/")).format(
        "DD/MM/YYYY"
      );
      let data = {};
      let student = {};
      let user = {};
      user.name = values.prenom;
      user.lastName = values.nom;
      user.email = values.emailUser;
      user.password = values.password;
      student.user = user;
      student.civility = values.civilite;
      student.phoneNumber = values.phoneNumber;
      student.birthday = formatDate;
      student.address = address;
      student.studentType = "classic";
      data.user = student;
      // debugger;
      dispatch(registerStudentCs(student));
    }
  };

  const validateAddress = () => {
    let valid = false;
    if (address.hasOwnProperty('street') && address.hasOwnProperty('postalCode')
      && address.hasOwnProperty('city') && address.hasOwnProperty('country')) {
      return address.street.length > 0 && address.postalCode.length > 0
        && address.city.length > 0 && address.country.length > 0;
    }

    return valid;
  }

  useEffect(() => {
    if( validateAddress() ){
      setHelperAddress('');
    }
  }, [address]);

  useEffect(() => {
    if (hasError('civilite')) {
      setHelperCivility(t("validation.field.required"));
    } else {
      setHelperCivility('');
    }

    if (hasError('phoneNumber')) {
      setHelperPhoneNumber(t("validation.field.required"));
    } else {
      setHelperPhoneNumber('');
    }

    if (hasError('acceptTerm')) {
      setHelperAcceptTerm(t("validation.field.required"));
    } else {
      setHelperAcceptTerm('');
    }
    console.log(formState);

  }, [formState.touched, formState.errors]);

  const hasError = (field) => {
    if (formState.errors[field]) {
      if (formState.touched[field]) {
        return true;
      }
    }
    return false;
  }

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const setPhoneNumber = (event) => {
    validatePhoneNumber();
  }

  return (
    <form className={classes.form} onSubmit={handleRegistration}>
      <Typography className={classes.titleName} variant="h1" gutterBottom>
        {t("registration-partner.title")}
      </Typography>

      <FormLabel component="legend">
        {t("confirmation-pass.form.inputs.civilite")}
      </FormLabel>
      <RadioGroup
        aria-label={t("confirmation-pass.form.inputs.civilite")}
        name="civilite"
        value={formState.values.civilite || ""}
        onChange={handleChange}
      >
        <FormControlLabel
          value="M"
          control={<Radio/>}
          label={t("confirmation-pass.form.inputs.civilite-option1")}
        />
        <FormControlLabel
          value="Mme"
          control={<Radio/>}
          label={t("confirmation-pass.form.inputs.civilite-option2")}
        />
      </RadioGroup>
      <FormHelperText className={classes.error}>{helperCivility}</FormHelperText>

      <div className={classes.emailDomain}>
        <TextField
          className={classes.textField1}
          error={hasError("prenom")}
          fullWidth
          helperText={hasError("prenom") ? formState.errors.prenom[0] : null}
          margin="normal"
          label={t("registration-partner.form.inputs.prenom")}
          name="prenom"
          onChange={handleChange}
          type="text"
          value={formState.values.prenom || ""}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError("nom")}
          fullWidth
          helperText={hasError("nom") ? formState.errors.nom[0] : null}
          margin="normal"
          label={t("registration-partner.form.inputs.nom")}
          name="nom"
          onChange={handleChange}
          type="text"
          value={formState.values.nom || ""}
          variant="outlined"
        />
      </div>
      <FormLabel component="legend">
        {t("confirmation-pass.form.inputs.brithday")}
      </FormLabel>
      <TextField
        id="birthday"
        error={hasError("birthday")}
        helperText={
          hasError("birthday")
            ? formState.errors.birthday[0]
            : null
        }
        name="birthday"
        value={formState.values.birthday}
        type="date"
        className={classes.date}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
       <TextField
          placeholder={t("registration-partner.form.inputs.phoneNumber")}
          className={classes.textField1}
          error={hasError("phoneNumber")}
          country="FR"
          fullWidth
          helperText={hasError("phoneNumber") ? formState.errors.phoneNumber[0] : null}
          margin="normal"
          label={t("registration-partner.form.inputs.phoneNumber")}
          name="phoneNumber"
          onChange={handleChange}
          type="text"
          value={formState.values.phoneNumber || ""}
          variant="outlined"
        />
      {/* <FormHelperText className={classes.error}>{helperPhoneNumber}</FormHelperText> */}
      <AutoCompleteAddress
        setAddress={setAddress}
      />
      <FormHelperText className={[classes.error, classes.helperError]}>{helperAddress}</FormHelperText>
      <div className={classes.emailDomain}>
        <TextField
          className={classes.textField}
          error={hasError("emailUser")}
          fullWidth
          helperText={
            hasError("emailUser") ? formState.errors.emailUser[0] : null
          }
          margin="normal"
          label={t("login-school.form.inputs.email")}
          name="emailUser"
          onChange={handleChange}
          type="text"
          value={formState.values.emailUser || ""}
          variant="outlined"
        />
      </div>

      <TextField
        className={classes.textField}
        error={hasError("password")}
        fullWidth
        helperText={hasError("password") ? formState.errors.password[0] : null}
        margin="normal"
        label={t("login-school.form.inputs.password")}
        name="password"
        onChange={handleChange}
        type={showPassword ? "text" : "password"}
        value={formState.values.password || ""}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <TextField
        className={classes.textField}
        error={hasError("passwordConfirm")}
        fullWidth
        helperText={
          hasError("passwordConfirm")
            ? formState.errors.passwordConfirm[0]
            : null
        }
        margin="normal"
        label={t("registration-partner.form.inputs.confirmPassword")}
        name="passwordConfirm"
        onChange={handleChange}
        type={showPassword2 ? "text" : "password"}
        value={formState.values.passwordConfirm || ""}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword2}
                onMouseDown={handleMouseDownPassword2}
              >
                {showPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <div>
        <Checkbox
          checked={formState.values.acceptTerm || false}
          onChange={handleChange}
          name="acceptTerm"
          color="primary"
        />
        <a href = {cgv} target = "_blank" rel="noreferrer">{t("registration-partner.form.inputs.acceptTerm")}</a>
        <FormHelperText className={classes.error}>{helperAcceptTerm}</FormHelperText>
      </div>
      <Button
        className={classes.submit}
        color="primary"
        // disabled={!formState.isValid}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
      >
        {t("registration-partner.form.registrationBtn")}
      </Button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  school: state.school,
});

export default connect(mapStateToProps)(Registration);
