import React, { useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { validateRegisterIsUser } from "../../redux/actions/validateRegisterIsActions";
import PropTypes from 'prop-types';

export const ValidateRegisterIs = (props) => {
  const { history } = props;

  const query = new URLSearchParams(useLocation().search);

  const dispatch = useDispatch();

  const processUrl = () => {
    const params = {
      token: query.get("token"),
    };
    if (validateParams(params)) {
      dispatch(validateRegisterIsUser({ userIs: params, history }))
    } else {
      history.push('/not-found');
    }
  }

  useEffect(function () {
    processUrl();
  });

  const validateParams = (params) => {
    return !!(params.token)
  }

  return (
    <React.Fragment>
    </React.Fragment>
  );
}

ValidateRegisterIs.propTypes = {
  history: PropTypes.object
};





