import React from 'react';
import { Route, Switch, useRouteMatch, BrowserRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import { default as SchoolsPage } from "./SchoolsPage";
import PropTypes from "prop-types";
import { SchoolForm } from "./components/SchoolForm";
import { useSelector } from "react-redux";
import { validateAccessUser } from "../../services/utils";
import {RouterPathApp} from "../../components/routers/RoutesPathApp";
import LocalStorageService from "../../services/LocalStorageService";

export const SchoolRouter = () =>  {

   const match = useRouteMatch();
   const currentUser = LocalStorageService.getUser();

   return validateAccessUser( RouterPathApp.SCHOOL, currentUser.roles[0]) ? (
       <BrowserRouter>
           <Switch>
               <Route exact path={ match.path }>
                   <SchoolsPage/>
               </Route>
               <Route exact path={`${match.path}/create`}>
                   <SchoolForm/>
               </Route>
               <Route exact path={`${match.path}/:id`}>
                   <SchoolForm/>
               </Route>
           </Switch>
       </BrowserRouter>
   ) : (
       <Redirect to="/schools" />
   )
}

SchoolRouter.propTypes = {
    history: PropTypes.object
};


