import React, {useRef, useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Container, TextField, Grid, Typography, Modal, Box, Button,IconButton, Input} from "@material-ui/core";
// import MUIDataTable from "mui-datatables";
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import { studentList, sendConfirmationMail} from "../../../redux/actions/studentsActions";
import { getContractsByStudents, getDocument, uploadContractHM, updateContractDocument, updateContract } from "../../../redux/actions/contractsActions";
import {Publish as Upload, GetApp as DownloadIcon, FileCopy as ReplaceIcon, MailOutline as MailOutlineIcon, NoEncryption} from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';

const columns = (sendEmail) => [
    {
        selector: row =>  row.civility,
        name: "Civilité",
    },
    {
        selector: row =>  row.name,
        name: "Prénom",
        sortable: true,

    },
    {
        selector: row =>  row.lastName,
        name: "Nom",
        sortable: true,

    },
    {
        selector: row =>  row.email,
        name: "Email",
    },
    {
        selector: row =>  row.birthday !== undefined && row.birthday.substring(0, 10),
        name: "Anniversaire",
    },
    {
        selector: row =>  row.address !== undefined && row.address.street + ', ' + row.address.city + ', ' + row.address.postalCode ,
        name: "Adresse",
    },
    {
        selector: row =>  row.phoneNumber,
        name: "Portable",
    },
    {
        selector: row =>  row.schoolName,
        name: "Ecole",
        sortable: true,

    },
    {
        selector: row =>  row.partnerlevel,
        name: "Partenaire",
        sortable: true,

    },
    {
        selector: row =>  row.isActive !== true && 
        (
        <Grid container spacing={4}>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
            <Tooltip title="Renvoyer un mail de confirmation">
                <IconButton onClick={() => sendEmail(row)}> <MailOutlineIcon /> </IconButton> 
            </Tooltip>
            </Grid>
        </Grid>
        )
        ,
        name: "Action",
    }
   ];


   const ClearButton = styled(Button)`
   	border-top-left-radius: 0;
   	border-bottom-left-radius: 0;
   	border-top-right-radius: 5px;
   	border-bottom-right-radius: 5px;
   	height: 34px;
   	width: 32px;
   	text-align: center;
   	display: flex;
   	align-items: center;
   	justify-content: center;
   `;
   const FilterComponent = ({ filterText, onFilter, onClear }) => (
    	<>
    		<TextField
    			id="search"
    			type="text"
    			placeholder="Recherche"
    			aria-label="Search Input"
    			value={filterText}
    			onChange={onFilter}
    		/>
    		<ClearButton type="button" onClick={onClear}>
    			X
    		</ClearButton>
    	</>
    );


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        marginTop: "50px",
      },
  paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  },
  avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
  },
  form: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center'
  },
  formControl: {
      margin: theme.spacing(2, 0 , 3),
      minWidth: "300px"
  },
  formField: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
  },
  submit: {
      margin: theme.spacing(3, 0, 2),
      width: "80%"
  },
  studentsTable: {
      "& .rdt_TableCell div":{
        whiteSpace: "normal!important",
      },
      "& .MuiGrid-container":{
        display: "inherit",
      }
  },
  contractsTable: {
    "& .rdt_TableCell div":{
      whiteSpace: "normal!important",
    },
    "& .rdt_TableCol_Sortable div":{
        whiteSpace: "normal!important",
    },
    "& .rdt_TableCell label":{
      fontSize: "12px",
    },
    "& .rdt_TableCell button":{
        fontSize: "12px",
    },
   },
  noPadding: {
    padding: "15px",
  },
  styleBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    background: 'white',
    boxShadow: "24",
    p: "4",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80%",
    },
  },
  styleBoxTerminate:  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    background: 'white',
    boxShadow: "24",
    p: "4",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
    },
    "& .MuiInput-underline:before":{
        display: "none",
    },
    "& Input":{
        paddingTop: "2px",
    }
  },
  buttonYes:{
    "& button":{
        background: "white",
        width: "90%",
        color: "green",
        marginBottom: "10px",
    },
    "& button:hover":{
        background: "green",
        width: "90%",
        color: "white",
        marginBottom: "10px"
    }
  },
  buttonNo:{
    "& button":{
        background: "white",
        width: "90%",
        color: "#c31e1e",
        marginBottom: "10px"
    },
    "& button:hover":{
        background: "#c31e1e",
        width: "90%",
        color: "white",
        marginBottom: "10px"
        }
    }
}));
const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
};
const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;

export const StudentList = props => {

    const history = useHistory();
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState('');
    const [selectedReference, setSelectedReference] = useState('');
    const [currentStudent, setCurrentStudent] = useState('');
    const [contextUpload, setContextUpload] = useState('');
    const [isTerminate, setTerminate] = useState(false);
    const [currentContrat, setCurrentContrat] = useState(null);
    const [contractEndDate, setContractEndDate] = useState(new Date().toLocaleDateString('fr-CA'));
    
    const {t} = useTranslation('common');
    const students = useSelector((state) => state.students.students);
    const contracts = useSelector((state) => state.contracts.data);

    const dispatch = useDispatch();

	const [filterText, setFilterText] = useState('');

	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [contractResetPaginationToggle, setContractResetPaginationToggle] = useState(false);
    const [contractFilterText, setContractFilterText] = useState('');

	const filteredItems = students.filter( item => item.name && (item.name.toLowerCase().includes(filterText.toLowerCase()) || item.lastName.toLowerCase().includes(filterText.toLowerCase()) || item.email.toLowerCase().includes(filterText.toLowerCase()) || item.schoolName.toLowerCase().includes(filterText.toLowerCase())));


    const contractFilteredItems = contracts.filter(
		item => item.reference && item.reference.toLowerCase().includes(contractFilterText.toLowerCase()), 
	);

    const onChangeStatus = (event, row) => {
        if(event.target.value === "résilié"){
            setTerminate(true);
            setCurrentContrat({...row, statut: event.target.value, isReadyToUpdate: false});
        }else{
            setCurrentContrat({...row, statut: event.target.value, isReadyToUpdate: true});
        }
    }

    const changeStatus = () =>{
        setCurrentContrat({...currentContrat, isReadyToUpdate: true, endDate: moment(contractEndDate).format('DD/MM/YYYY')});
        handleCloseTerminate();
    }

    useEffect(() => {
        if(currentContrat !== null){
            if(currentContrat.isReadyToUpdate ){
                dispatch(updateContract({contract: currentContrat}));
            }
        }
       }, [currentContrat]);


    function convertArrayOfObjectsToCSV(array) {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(students[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }
    
    function downloadCSV(array) {
            const link = document.createElement('a');
            let csv = convertArrayOfObjectsToCSV(array);
            if (csv == null) return;
        
            const filename = 'export.csv';
        
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }
        
            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
        }

	const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(filteredItems)} />, []);

    const handleClose = () => {
        setOpen(false);
      };

    const handleCloseTerminate = () => {
      setTerminate(false);
    };

    function downloadDocument(id, name) {
      dispatch(getDocument({ id: id, filename: name }));
    }

    function uploadDocument(e, reference) {
        if (e.target.files.length > 0) {
            setContextUpload("upload");
            handleFiles(e.target.files, reference);
          }  
    }
    function replaceDocument(e, reference) {
        if (e.target.files.length > 0) {
            setContextUpload("replace");
            handleFiles(e.target.files, reference);
          }  
    }
    const handleFiles = (files, reference) => {
        if (validateFile(files[0])) {
          setSelectedFiles(files[0]);
          setSelectedReference(reference);
        }
    };

    const validateFile = (file) => {
      const validTypes = [
        "application/pdf",
      ];
      if (validTypes.indexOf(file.type) === -1) {
        return false;
      }

      return true;
    };
    const contractColumns = [
        {
            selector: row =>  row.insuranceType,
            name: "Type d'assurance",
        },
        {
            selector: row =>  row.documentType,
            name: "Type de document",
        },
        {
            selector: row =>  row.paymentStatus,
            name: "Statut de paiement",
            sortable: true,
    
        },
        {
            selector: row =>  row.reference,
            name: "reference",
            sortable: true,
    
        },
        {
            selector: row =>  row.createdAt,
            name: "Date de creation",
        },
        {
            selector: row =>  row.document.length !== 0 ? 
            (
            <Grid container spacing={1}>
                <Grid item xs={6} align="center" justify="center" alignItems="center">
                <Tooltip title="Télécharger">
                    <IconButton onClick={() => downloadDocument(row.document.id, row.document.fileName)}> <DownloadIcon /> </IconButton> 
                </Tooltip>
                </Grid>
                <Grid item xs={6} align="center" justify="center" alignItems="center">
                    <Tooltip title="Remplacer le contract">

                        <IconButton
                        variant="contained"
                        component="label"
                        >
                            <ReplaceIcon />
                            <input
                                className="file-input"
                                type="file"
                                accept=".pdf"
                                onChange={(e) => replaceDocument(e, row.reference)}
                                hidden
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            ) :
            <>
                <Tooltip title="Upload un Pdf">
                    <IconButton
                        variant="contained"
                        component="label"
                        >
                            <Upload />
                        <input
                            className="file-input"
                            type="file"
                            accept=".pdf"
                            onChange={(e) => uploadDocument(e, row.reference)}
                            hidden
                        />
                    </IconButton>
                </Tooltip>
            </>
                ,
            name: "Document",
        },
        {
            selector: row =>  row.customerStripe,
            name: "Identifiant Client Stripe",
        },
        {
            selector: row =>
                <FormControl className={classes.formControl}>
                    <Select
                    native
                    value={row.statut}
                    onChange={(e) => onChangeStatus(e, row)}
                    inputProps={{
                        name: 'statut',
                        id: 'statut-native-simple',
                    }}
                    >
                    <option value={"ok"}>Ok</option>
                    <option value={"résilié"}>Résilié</option>
                    <option value={"impayé"}>Impayé</option>
                    </Select>
              </FormControl>
              ,
              name: "Statut"
        },
        {
            selector: row => row.startDate,
            name: "Date de début"
        },
        {
            selector: row => row.endDate,
            name: "Date de fin"
        },
        {
            selector: row => row.countryName,
            name: "Destination"
        }, 
        {
            selector: row => row.numberRoom,
            name: "Nb pièces"
        }
       ];
    const clickRow =  (event) =>{
        setCurrentStudent(event.id);
        dispatch(getContractsByStudents(event.id));
        setOpen(true);
    }
	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, contractResetPaginationToggle]);
    
	const contractSubHeaderComponentMemo = useMemo(() => {
		const contractHandleClear = () => {
			if (contractFilterText) {
				setResetPaginationToggle(!contractResetPaginationToggle);
				setContractFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setContractFilterText(e.target.value)} onClear={contractHandleClear} filterText={contractFilterText} />
		);
	}, [contractFilterText, contractResetPaginationToggle]);

    const clickNo = () =>{
        setSelectedFiles('');
        setSelectedReference('');
    }
    const clickYes = async () =>{
        var contractData = new FormData();
        contractData.append("reference", selectedReference);
        if(contextUpload === "replace"){
            contractData.append("newDocument", selectedFiles);
            await dispatch(updateContractDocument({ data: contractData, currentStudent: currentStudent }));
        }else{
            contractData.append("certificateFile", selectedFiles);
            await dispatch(uploadContractHM({ data: contractData, currentStudent: currentStudent }));
        }
        setSelectedFiles('');
        setSelectedReference('');
    }  

    useEffect(() => {
        dispatch(studentList())
       }, []);

    const sendEmail = (student) => {
        dispatch(sendConfirmationMail({"referenceStudent": student.id}));
    }

    return (
        <Container component="main" maxWidth="lg" className={[classes.studentsTable, classes.root]}>
            <Grid container spacing={4}>
                <Grid item xs={12} align="center" justify="center" alignItems="center">
                    <Typography variant="h2" className={classes.categoryTitle}>
                        {t('admin.student.title')}
                    </Typography>
                </Grid>
                <DataTable
                    columns={columns(sendEmail)}
                    data={filteredItems}
                    customStyles={customStyles} 
                    highlightOnHover
                    onRowDoubleClicked={clickRow}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead  
                    // actions={actionsMemo}             
                />
            </Grid>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={[classes.noPadding, classes.styleBox]} >
                    <Grid container spacing={4}>
                        <Grid item xs={12} align="center" justify="center" alignItems="center">
                            <Typography variant="h2" className={classes.categoryTitle}>
                                {t('admin.contract.title')}
                            </Typography>
                        </Grid>
                        {selectedFiles !== '' ? (
                            <>
                                <Grid item xs={12} align="center" justify="center" alignItems="center">
                                    <Typography variant="h4" className={classes.categoryTitle}>
                                        {t('admin.contract.reference')} {' '}
                                    </Typography>
                                    <Typography variant="p" className={classes.categoryTitle}>
                                        {selectedReference}
                                    </Typography>
                                    <Typography variant="h4" className={classes.categoryTitle}>
                                        {t('admin.contract.are-you-sur')} {' '}
                                    </Typography>
                                    <Typography variant="p" className={classes.categoryTitle}>
                                        {selectedFiles.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align="center" justify="center" alignItems="center" className={classes.buttonNo} onClick={clickNo}>
                                    <Button>
                                        {t('insurance.housing.step2.no')}
                                    </Button>
                                </Grid> 
                                <Grid item xs={6} align="center" justify="center" alignItems="center" className={classes.buttonYes} onClick={clickYes}>
                                    <Button>
                                        {t('insurance.housing.step2.yes')}
                                    </Button>
                                </Grid> 
                            </>
                            ) : (
                                <Grid item xs={12} align="center" justify="center" alignItems="center" className={[classes.contractsTable]}>
                                    <DataTable
                                        columns={contractColumns}
                                        data={contractFilteredItems}
                                        customStyles={customStyles} 
                                        highlightOnHover 
                                        pagination
                                        paginationResetDefaultPage={contractResetPaginationToggle}
                                        subHeader
                                        subHeaderComponent={contractSubHeaderComponentMemo}
                                        persistTableHead  
                                    />
                                </Grid> 
                            )}

                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={isTerminate}
                onClose={handleCloseTerminate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className={[classes.noPadding, classes.styleBoxTerminate]} >
                    <Grid container  spacing={2}>
                        <Grid item xs={12} align="center" justify="center" alignItems="center">
                            <Typography variant="h2" className={classes.categoryTitle}>
                                Résiliation du contrat {currentContrat && currentContrat.reference}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} align="right" justify="right" alignItems="center">
                            <Typography variant="h5" className={classes.categoryTitle}>
                            La date de fin est pour le 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} align="left" justify="left" alignItems="center">
                             <TextField
                                id="date"
                                type="date"
                                value={contractEndDate}
                                onChange= {(e) => setContractEndDate(e.target.value)}
                                className={classes.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} align="center" justify="center" alignItems="center">
                            <Button style={{border: "solid 2px red", color: "red"}} onClick={changeStatus}>
                                Résilier
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Container>
    );
}

export default StudentList;
