import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/styles";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Container from "@material-ui/core/Container";
import { getContracts } from "../../redux/actions/contractsActions";
import "./Dropzone.css";
import LocalStorageService from "../../services/LocalStorageService";
import {
  declareClaim
} from "../../redux/actions/claimActions";
import { Redirect } from 'react-router'

const constraints = (t) => ({
  title: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
  message: {
    presence: { allowEmpty: false, message: t("validation.field.required") },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  categoryTitle: {
    textTransform: "uppercase",
    color: "#a80000",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginBottom: "45px",
  },
  paperC: {
    padding: "5%",
    marginTop: "40px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      "& .drop-container":{
        width: "100%",
      },
    },
    boxShadow: "20px 17px 46px 8px rgba(168,0,0,0.16);",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: "0px 8px 8px 8px",
    minWidth: 120,
  },
  selectText: {
    marginTop: "1%",
  },
}));

export const DeclareClaim = () => {
  const fileInputRef = useRef();
  const modalImageRef = useRef();
  const modalRef = useRef();
  const progressRef = useRef();
  const uploadRef = useRef();
  const uploadModalRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [insuranceType, setInsuranceType] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation("common");
  const schema = constraints(t);
  const currentUserToken = useSelector((state) => state.auth.token);

  const [claimType, setClaimType] = React.useState("");

  const [redirectToList, setRedirectToList] = React.useState(false);

  const contracts = useSelector((state) => state.contracts);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  function removeDuplicates(data) {
    return data.filter((value, index) => data.indexOf(value) == index);
  }

  useEffect(() => {
    dispatch(getContracts({ token: currentUserToken }));
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));

    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);

    let insuranceType = [];
    if (contracts.data.length !== 0) {
      contracts.data.map((item, i) => {
        insuranceType.push(item.insuranceType);
      });
      let properInsuranceType = removeDuplicates(insuranceType);
      setInsuranceType(properInsuranceType);
    }
  }, [formState.values, selectedFiles, contracts]);

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]["invalid"] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage("File type not permitted");
        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    const index = validFiles.findIndex((e) => e.name === name);
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  const openImageModal = (file) => {
    const reader = new FileReader();
    modalRef.current.style.display = "block";
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  };

  const closeModal = () => {
    modalRef.current.style.display = "none";
    modalImageRef.current.style.backgroundImage = "none";
  };

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = "none";
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleEmpty = () => {
    setFormState((formState) => ({
      ...formState,
      values: {},
      touched: {},
    }));
    setClaimType("");
    /*for (let i = 0; i < validFiles.length; i++) {
      removeFile(validFiles[i].name);
    }*/
  };

  const handleClaimTypeChange = (event) => {
    setClaimType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { values } = formState;
    const subject =
      "Sinistre App Studcorp " +
      " " +
      LocalStorageService.getUser().name +
      " " +
      LocalStorageService.getUser().lastName +
      " " +
      claimType;
    const body =
      "\n" +
      values.title +
      "\n" +
      "\n" +
      values.message +
      "\n" +
      "\n" +
      "Infos étudiant : " +
      "\n" +
      LocalStorageService.getUser().name +
      " " +
      LocalStorageService.getUser().lastName +
      "\n" +
      "Email : " +
      LocalStorageService.getUser().email;
    var formdata = new FormData();
    formdata.append("recipient", process.env.REACT_APP_DEFAULT_MAIL_RECEIVER);
    formdata.append("subject", subject);
    formdata.append("body", body);
    for (let index = 0; index < validFiles.length; index++) {
      formdata.append("file" + (index + 1), validFiles[index]);
    }

    var claimdata = new FormData();
    claimdata.append("insurance_type", claimType);
    claimdata.append("title", values.title);
    claimdata.append("message", values.message);
    claimdata.append("state", 1);
    for (let index = 0; index < validFiles.length; index++) {
      claimdata.append("claimDocuments[]", validFiles[index]);
    }

    // dispatch(declareEmailClaim({ data: formdata }));
    dispatch(declareClaim({ data: claimdata }));
    handleEmpty();
    setRedirectToList(true);

  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  return (redirectToList ? <Redirect to="/claims/my-claims" /> :
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} align="center" justify="center" alignItems="center">
          <Typography variant="h2" className={classes.categoryTitle}>
            {t("claim.declare.title")}
          </Typography>
        </Grid>
        <Container component="main">
          {contracts.data.length !== 0 ? (
            <div className={classes.paper}>
              <Paper elevation={5} className={classes.paperC}>
                <Grid item xs={12}>
                  <Typography variant="h3" className={classes.title}>
                    <form className={classes.form} onSubmit={ handleSubmit}>
                      <Grid item xs={6} container direction="row">
                        <Typography className={classes.selectText} variant="h6">
                        {t("claim.declare.type-of-claim")}
                        </Typography>
                        <FormControl className={classes.formControl}>
                          <Select
                            value={claimType}
                            onChange={handleClaimTypeChange}
                            displayEmpty
                            required
                            className={classes.selectEmpty}
                            inputProps={{ "aria-label": "Year" }}
                            autoWidth={true}
                          >
                            <MenuItem value="">
                              <em>Choisissez</em>
                            </MenuItem>
                            {insuranceType.map((item, i) => (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <TextField
                        className={classes.textField}
                        error={hasError("title")}
                        fullWidth
                        helperText={
                          hasError("title") ? formState.errors.title[0] : null
                        }
                        margin="normal"
                        label={t("claim.declare.field.title")}
                        name="title"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.title || ""}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={hasError("message")}
                        fullWidth
                        helperText={
                          hasError("message")
                            ? formState.errors.message[0]
                            : null
                        }
                        margin="normal"
                        label={t("claim.declare.field.message")}
                        name="message"
                        rows="4"
                        onChange={handleChange}
                        type="text"
                        multiline
                        value={formState.values.message || ""}
                        variant="outlined"
                      />
                      <div>
                        <div className="container">
                          {unsupportedFiles.length ? (
                            <p>Please remove all unsupported files.</p>
                          ) : (
                            ""
                          )}
                          <div
                            className="drop-container"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop}
                            onClick={fileInputClicked}
                          >
                            <div className="drop-message">
                              <div className="upload-icon"></div>
                              Glisser & déposer vos fichiers ou cliquer pour les
                              télécharger
                            </div>
                            <input
                              ref={fileInputRef}
                              className="file-input"
                              type="file"
                              accept=".pdf,.png,.jpeg,.jpg"
                              multiple
                              onChange={filesSelected}
                            />
                          </div>
                          <div className="file-display-container">
                            {validFiles.map((data, i) => (
                              <div className="file-status-bar" key={i}>
                                <div
                                  onClick={
                                    !data.invalid
                                      ? () => openImageModal(data)
                                      : () => removeFile(data.name)
                                  }
                                >
                                  <span
                                    className={`file-name ${
                                      data.invalid ? "file-error" : ""
                                    }`}
                                  >
                                    {data.name}
                                  </span>
                                  <span className="file-size">
                                    ({fileSize(data.size)})
                                  </span>{" "}
                                  {data.invalid && (
                                    <span className="file-error-message">
                                      ({errorMessage})
                                    </span>
                                  )}
                                </div>
                                <div
                                  className="file-remove"
                                  onClick={() => removeFile(data.name)}
                                >
                                  X
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="modal" ref={modalRef}>
                          <div className="overlay"></div>
                          <span className="close" onClick={() => closeModal()}>
                            X
                          </span>
                          <div
                            className="modal-image"
                            ref={modalImageRef}
                          ></div>
                        </div>

                        <div className="upload-modal" ref={uploadModalRef}>
                          <div className="overlay"></div>
                          <div
                            className="close"
                            onClick={() => closeUploadModal()}
                          >
                            X
                          </div>
                          <div className="progress-container">
                            <span ref={uploadRef}></span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                ref={progressRef}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button
                        className={classes.submit}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {t("claim.declare.field.button")}
                      </Button>
                    </form>
                  </Typography>
                </Grid>
              </Paper>
            </div>
          ) : (
            <Grid item xs={12} align="center" justify="center" alignItems="center">
            <Typography variant="h2">
              Vous ne disposez d'aucun contrat d'assurance sur lequel déclarer
              un sinistre
            </Typography>
            </Grid>
          )}
        </Container>
      </Grid>
    </div>
  );
};
