import { instance as axios } from "./baseUrl";

export const listCountriesApi = () => axios.get("/countries");

export const downloadContractApi = (data) =>
  axios.post(
    `/contracts/health_mobility`,
    { startDate: data.startDate, endDate: data.endDate, country: data.country, sessionStripe: "" },
    { responseType: "blob" }
  );

export const createContractWithoutCertificateApi = (data) =>
  axios.post(
    `/contracts/health_mobility/without`,
    { startDate: data.startDate, endDate: data.endDate, country: data.country, sessionStripe: data.sessionStripe, healthMobilityPrime: data.healthMobilityPrime, addMultimediaInsurance: data.addMultimediaInsurance }
  );

export const generateAttestation = (data) =>
  axios.post(
    `/contracts/health_mobility/without`,
    { startDate: data.startDate, endDate: data.endDate, country: data.country, sessionStripe: data.sessionStripe }
  );

  export const createFrenchZoneHealthMobilityApi = (data) =>
  axios.post(
    `contracts/create_health_mobility_french_zone/create`, 
    {startDate: data.startDate, endDate: data.endDate, country: data.country, sessionStripe: data.sessionStripe, addressOrigin: data.addressOrigin, countryNationality: data.countryNationality});

export const createMultimediaContractApi = (data) =>
  axios.post(
    `/contracts/multimedia`,
    data,
    { responseType: "blob" }
  );

export const downloadGuaranteesTableApi = () =>
  axios.get(`/contracts/guarantees_file`, { responseType: "blob" });

export const getPublicKeyHealthMobility = (zone, month, partnerLevelHealthMobilityPrice) =>
  axios.get(`/payments/price/health_mobility?zone=${zone}&month=${month}&partnerLevelHealthMobilityPrice=${partnerLevelHealthMobilityPrice}`);

export const redirectPaymentHealth_mobility = (data) =>
  axios.post(`/payments/checkout/health_mobility`, { ...data });

export const getHomeInsurancePrice = (paymentMethod, rooms) =>
  axios.get(
    `/payments/price/home_insurance?paymentType=${paymentMethod}&room=${rooms}`
  );

export const getPublicKeyMultimedia = (price, condition, school) =>
  axios.get(`/payments/price/multimedia?price=${price}&condition=${condition}&school=${school}`);

export const getListPublicKeyMultimedia = (type, school, reccuring) =>
  axios.get(`/payments/price/listmultimedia?type=${type}&school=${school}&recurring=${reccuring}`);

export const createAccountForHomeInsurance = (data) =>
  axios.post(`/security/register/student/classic`, { ...data });

export const createContractForHouseInsurance = (data) =>
  axios.post(`/contracts/home_insurance`, { ...data });

export const createContractForMultimediaInsurance = (data) =>
  axios.post(`/contracts/multimedia_insurance`, { ...data });

export const createContractForHealthInsurance = (data) =>
  axios.post(`/contracts/health_insurance`, { ...data });

export const redirectPaymentHome_insurance = (data) =>
  axios.post(`payments/checkout/home_insurance`, { ...data });

export const redirectPaymentMultimedia_insurance = (data) =>
  axios.post(`/payments/checkout/multimedia_insurance`, { ...data });

export const tokenDigitalFirmaHome_insurance = (data) =>
  axios.post(`/contracts/home_insurance/sign`, { ...data });

export const finishDigitalFirmApi = (data) =>
  axios.post(`/contracts/home_insurance/send`, { ...data });

export const finishHealthMobilityDigitalFirmApi = (data) =>
    axios.post(`/contracts/health_insurance/send`, {...data});

export const finishMultimediaDigitalFirmApi = (data) =>
  axios.post(`/contracts/multimedia_insurance/send`, { ...data });

export const redirectStripeAccountManagement = (data) =>
  axios.post(`/payments/account/stripe/management`, { ...data });

export const redirectHealthMutualInsurance = () =>
  axios.post(`/contracts/health_mutual_insurance`, {});
