import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";
import { getInitials } from "../../../../helpers";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { PartnerShipLevel } from "../../utils/PartnerShipLevel";
import MenuItem from "@material-ui/core/MenuItem";
import { PartnerLevelHealthMobility } from "../../utils/PartnerLevelHealthMobility";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export const SchoolsTable = (props) => {
  const { className, schools, handleEdit, handleDelete, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  const [selectedSchools, setSelectedSchools] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    const { schools } = props;

    let selectedSchools;

    if (event.target.checked) {
      selectedSchools = schools.map((user) => user.id);
    } else {
      selectedSchools = [];
    }

    setSelectedSchools(selectedSchools);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedSchools.indexOf(id);
    let newSelectedSchools = [];

    if (selectedIndex === -1) {
      newSelectedSchools = newSelectedSchools.concat(selectedSchools, id);
    } else if (selectedIndex === 0) {
      newSelectedSchools = newSelectedSchools.concat(selectedSchools.slice(1));
    } else if (selectedIndex === selectedSchools.length - 1) {
      newSelectedSchools = newSelectedSchools.concat(
        selectedSchools.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedSchools = newSelectedSchools.concat(
        selectedSchools.slice(0, selectedIndex),
        selectedSchools.slice(selectedIndex + 1)
      );
    }

    setSelectedSchools(newSelectedSchools);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getPartnershipLevel = (value) => {
    let result = "";
    Object.keys(PartnerShipLevel).map((key, i) => {
      if (PartnerShipLevel[key].value === value) {
        result = PartnerShipLevel[key].text;
      }
    });
    return result;
  }

  const getPartnerLevelHealthMobility = (value) => {
    let result = "";
    Object.keys(PartnerLevelHealthMobility).map((key, i) => {
      if (PartnerLevelHealthMobility[key].value === value) {
        result = PartnerLevelHealthMobility[key].text;
      }
    });
    return result;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("schoolManager.list.name")}</TableCell>
                  <TableCell>
                    {t("schoolManager.list.studentsSchoolName")}
                  </TableCell>
                  <TableCell>
                    {t("schoolManager.list.partnershipLevel")}
                  </TableCell>
                  <TableCell>
                    {t("schoolManager.list.insuranceForForeign")}
                  </TableCell>
                  <TableCell>{t("schoolManager.list.insurance")}</TableCell>
                  <TableCell>{t("schoolManager.list.emailDomain")}</TableCell>
                  <TableCell>{t("schoolManager.list.path")}</TableCell>
                  <TableCell>{t("schoolManager.list.actions")}</TableCell>
                  <TableCell>{t("schoolManager.list.partnerLevelHealthMobility")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((school) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={schools.id}
                    selected={selectedSchools.indexOf(school.id) !== -1}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{school.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{school.studentsSchoolName}</TableCell>
                    <TableCell>
                      {getPartnershipLevel(school.partnershipLevel)}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={school.insuranceForForeign}
                        color="primary"
                        onChange={(event) => handleSelectOne(event, school.id)}
                        value="false"
                        disabled="true"
                      />
                    </TableCell>
                    <TableCell key={schools.id}>{school.insurer}</TableCell>
                    <TableCell>{school.emailDomain}</TableCell>
                    <TableCell>{school.path}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEdit(school.id)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {getPartnerLevelHealthMobility(school.partnerLevelHealthMobility)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={schools.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[100, 200]}
        />
      </CardActions>
    </Card>
  );
};

SchoolsTable.propTypes = {
  className: PropTypes.string,
  schools: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
};
