import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import { Grid, Typography, Avatar, FormControlLabel, Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router"
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import {useSelector} from "react-redux";
import { useDispatch } from "react-redux";
import {contactForm} from '../../redux/actions/contactActions'
import LocalStorageService from "../../services/LocalStorageService";
import { SuccessPage } from '../Success';

const constraints = (t) => ({
    lastname: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    firstname: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    countryNationality: {
        presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    countryResidence: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    phone: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    mail: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    address: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    reason: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
    hostOrganization: {
      presence: { allowEmpty: false, message: t('validation.field.required') },
    },
  });


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 16px 0 16px',
    flexGrow: 1,
    marginLeft: '6%',
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  container: {
    display: 'flex',
    padding: '50px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  categoryTitle : {
    textTransform: 'uppercase',
    color: '#66a882',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  subtitle: {
    marginTop: '2%'
  },
  form: {
    width: '90%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
    },
  },
  wrapIcon : {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginTop: theme.spacing(1),
    width: '100%',
    fontWeight: 'bold',
    color: 'black'
  },
  avatar: {
    width: 90,
    height: 90
  },
  icons: {
    color :'#4f73a4',
    marginRight: '1%'
  },
  councillorTitle: {
    marginTop: theme.spacing(1.5),
    fontWeight: 'bold',
    color: 'black'
  },
  councillorBlock: {
      marginTop: '15%'
  }
}));

export const HealthMobilityCertificate = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [stateSecondNationality, setStateSecondNationality] = useState(false);
  const {t} = useTranslation('common');
  const schema = constraints(t);
  const service = LocalStorageService.getService();
  const healthMobilityContract = service.getHealthMobilityContract();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleEmpty = () => {
    setFormState(formState => ({
      ...formState,
      values: {},
      touched: {}
    }))
  }

  const handleClose = () => {
    setOpen(false);
    history.push('/documents')
  };

  const handleContact = event => {
    event.preventDefault();
    const { values } = formState
    const subject = 'Info Attestation Sante Mobilite - App Studcorp ' + values.lastname + " " + values.firstname;
    const body = "<br> Demande d'attestation <br>" + ' <br>' + 
    "<br> Nom: " + values.lastname + ' <br>' +
    "<br> Prénom: " + values.firstname + ' <br>' +
    "<br> Nationalité: " + values.countryNationality + ' <br>' +
    "<br> Seconde nationalité: " + values.secondCountryNationality + ' <br>' +
    "<br> Pays de résidence: " + values.countryResidence + ' <br>' +
    "<br> Téléphone: " + values.phone + ' <br>' +
    "<br> Email: " + values.mail + ' <br>' +
    "<br> Adresse: " + values.address + ' <br>' +
    "<br> Motif de séjour: " + values.reason + ' <br>' +
    "<br> Organisation d'accueil: " + values.hostOrganization +
    "<br> Date du " + healthMobilityContract.startDate +
    "<br> au " + healthMobilityContract.endDate +
    "<br> Destination " + healthMobilityContract.countryName +
    "<br> Référence: " + healthMobilityContract.reference 
    ;

    const data = { 
      subject: subject,
      body: body
    }
    dispatch(contactForm({ values: data}))
    service.removeHealthMobilityContract();
    handleEmpty();
    setOpen(true);
  };


  const handleChangeSecondNationality = (event) => {
    setStateSecondNationality(event.target.checked );
  };

  
  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
            <Grid item xs={12} align="center" justify="center" alignItems="center">
                <Typography variant="h2" className={classes.categoryTitle}>
                    {t('health-mobility-certificate.title')}
                </Typography>
            </Grid>
            <Grid item sm={12} className={classes.subtitle}>
                <Typography variant="h3" className={classes.title}>
                    <form className={classes.form}  onSubmit={handleContact} >
                        <TextField className={classes.textField} error={hasError('lastname')}  fullWidth
                                    helperText={ hasError('lastname') ? formState.errors.lastname[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.lastname')}
                                    name="lastname"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.lastname || ''}
                                    variant="outlined"
                                    />
                        <TextField className={classes.textField} error={hasError('firstname')} fullWidth
                                    helperText={ hasError('firstname') ? formState.errors.firstname[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.firstname')}
                                    name="firstname"
                                    onChange={handleChange}
                                    type="text"
                                    value={formState.values.firstname || ''}
                                    variant="outlined"
                                     />
                        <TextField className={classes.textField} error={hasError('countryNationality')} fullWidth
                                    helperText={ hasError('countryNationality') ? formState.errors.countryNationality[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.countryNationality')}
                                    name="countryNationality"
                                    onChange={handleChange}
                                    
                                    value={formState.values.countryNationality || ''}
                                    variant="outlined"
                                     />
                        <FormControlLabel control={<Checkbox checked={stateSecondNationality} onChange={handleChangeSecondNationality} name="secondNationality" />} label={t('health-mobility-certificate.field.secondNationality')} />
                         { stateSecondNationality ? (
                         <TextField className={classes.textField} error={hasError('secondCountryNationality')} fullWidth
                         helperText={ hasError('secondCountryNationality') ? formState.errors.secondCountryNationality[0] : null }
                         margin="normal"
                         label={t('health-mobility-certificate.field.secondCountryNationality')}
                         name="secondCountryNationality"
                         onChange={handleChange}
                         
                         value={formState.values.secondCountryNationality || ''}
                         variant="outlined"
                          /> ) : (null)
                         }
                         <TextField className={classes.textField} error={hasError('countryResidence')} fullWidth
                                    helperText={ hasError('countryResidence') ? formState.errors.countryResidence[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.countryResidence')}
                                    name="countryResidence"
                                    onChange={handleChange}
                                    
                                    value={formState.values.countryResidence || ''}
                                    variant="outlined"
                                     />

                         <TextField className={classes.textField} error={hasError('phone')} fullWidth
                                    helperText={ hasError('phone') ? formState.errors.phone[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.phone')}
                                    name="phone"
                                    onChange={handleChange}
                                    
                                    value={formState.values.phone || ''}
                                    variant="outlined"
                                     />       
                        <TextField className={classes.textField} error={hasError('mail')} fullWidth
                                    helperText={ hasError('mail') ? formState.errors.mail[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.mail')}
                                    name="mail"
                                    onChange={handleChange}
                                    
                                    value={formState.values.mail || ''}
                                    variant="outlined"
                                     />
                        <TextField className={classes.textField} error={hasError('address')} fullWidth
                                    helperText={ hasError('address') ? formState.errors.address[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.address')}
                                    name="address"
                                    onChange={handleChange}
                                    
                                    value={formState.values.address || ''}
                                    variant="outlined"
                                     />
                        <TextField className={classes.textField} error={hasError('reason')} fullWidth
                                    helperText={ hasError('reason') ? formState.errors.reason[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.reason')}
                                    name="reason"
                                    onChange={handleChange}
                                    
                                    value={formState.values.reason || ''}
                                    variant="outlined"
                                     />
                        <TextField className={classes.textField} error={hasError('hostOrganization')} fullWidth
                                    helperText={ hasError('hostOrganization') ? formState.errors.hostOrganization[0] : null }
                                    margin="normal"
                                    label={t('health-mobility-certificate.field.hostOrganization')}
                                    name="hostOrganization"
                                    onChange={handleChange}
                                    
                                    value={formState.values.hostOrganization || ''}
                                    variant="outlined"
                                     />                                                      
                        <Button className={classes.submit} color="primary"
                                disabled={!formState.isValid}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained" >
                                {t('health-mobility-certificate.field.button')}
                        </Button>
                    </form>
                </Typography>
            </Grid>
        </Grid>
        <SuccessPage handleClose={handleClose} open={open}/>
    </div>
  );
};
