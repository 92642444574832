import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Grid, Typography, Modal, Box, useMediaQuery, createMuiTheme } from "@material-ui/core";
import FlightIcon from "@material-ui/icons/Flight";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from '@material-ui/icons/Add';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import LocalStorageService from "../../services/LocalStorageService";
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router"
import PropTypes, { string } from 'prop-types';

import { PartnerLevelHealthMobility } from '../Schools/utils/PartnerLevelHealthMobility';
import { PartnerShipLevel } from "../Schools/utils/PartnerShipLevel";
import { downloadContract, downloadGuaranteesTable, getPublicKey, listCountries } from "../../redux/actions/InsuranceActions";
import validate from "validate.js";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import { Roles } from "../../common/Roles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { redirectPaymentPageMobility } from "../../services/utils";
import Paper from "@material-ui/core/Paper";
import { getSchoolByStudent } from "../../redux/actions/schoolsActions";
import RockerSwitch from "../../components/RockerSwitch/rockerSwitch"
import { LocalHospital as LocalHospitalIcon, FlightTakeoff as FlightTakeoffIcon, Work as WorkIcon, Payment } from '@material-ui/icons';
import Tooth from '../../assets/images/tooth.png';
import PersonIcon from '@material-ui/icons/Person';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import WarningIcon from '@material-ui/icons/Warning';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import SecurityIcon from '@material-ui/icons/Security';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
import MultimediaInsuranceOption from '../MultimediaInsurance/MultimediaInsuranceOption';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import MultimediaOption from "../MultimediaInsurance/MultimediaInsuranceOption";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import contraintsStep1 from "../HouseInsurance/HouseInsurance"

import { isAuthenticated, redirectPaymentPageHome } from "../../services/utils";
import { useDispatch } from 'react-redux';

import { getListPriceMultimediaInsurance, detailMultimediaSuccess } from "../../redux/actions/InsuranceActions";


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#5CBEFF',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
      },
    },
    MuiPickersDay: {
      day: {
        color: '#5CBEFF',
      },
      daySelected: {
        backgroundColor: '#5CBEFF',
      },
      dayDisabled: {
        color: '#5CBEFF',
      },
      current: {
        color: '#5CBEFF',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#5CBEFF',
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 20px 0 20px',
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    padding: '50px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginLeft: '200rem',
  },
  categoryTitle: {
    textTransform: 'uppercase',
    color: '#6EAFF5',
    fontWeight: 'bold',
  },
  contentContainer: {
    marginTop: '6px',
    [theme.breakpoints.between("xs", "sm")]: {
    },
  },
  itemContent: {
    display: 'block',
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.between("xs", "sm")]: {
      display: 'block',
    }
  },
  planeIcon: {
    color: '#6eaff5',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    transform: 'rotate(45deg)',
    width: '40px',
    height: '40px'
  },
  locationIcon: {
    color: '#2F6472',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    width: '40px',
    height: '40px'
  },
  multimediaIcon: {
    color: '#8ECECE',
    marginRight: '30px',
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: "0px",
    },
    width: '40px',
    height: '40px'
  },
  plusIcon: {
    color: '#23009A',
    marginRight: '1%'
  },

  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    fontWeight: 'bold',
    color: 'black'
  },
  wrapText: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    fontWeight: 'bold',
    color: 'black',
    marginTop: "auto",
  },
  selectInsurance: {
    width: '65%',
    top: '-20px',
    [theme.breakpoints.between("xs", "sm")]: {
      width: '100%',
    },
  },
  linkDownLoad: {
    width: '100%',
    display: 'block',
    fontWeight: '700',
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '1',
    cursor: 'pointer'
  },
  linkDownloadDisabled: {
    width: '100%',
    display: 'block',
    fontWeight: '700',
    textAlign: 'center',
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#8d8b8b',
    opacity: '1',
  },
  linkPlus: {
    width: '100%',
    display: 'flex',
    color: '#23009A',
    marginTop: '60px'
  },
  textPlus: {
    fontSize: '15px',
    marginTop: '8px'
  },
  paragraphContainer: {
    display: 'flex',
    width: '100%',
    height: '80%'
  },
  contentParagraphs: {
    display: 'flex',
    width: '100%',
    height: '100%',
    marginTop: '70px',
    flexDirection: 'row',
  },
  contentParagraphsColumn: {
    display: 'flex',
    width: '50%',
    height: '100%',
    flexDirection: 'column',
  },
  logo: {
    width: '80px',
    height: '25px',
    marginRight: '5px'
  },
  logobg: {
    width: "83px",
    minHeight: "44px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center left",
    marginRight: "10px"
  },
  line1: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: '5px',
    marginBottom: '5px'
  },
  subtitle: {
    display: 'flex',
    color: 'black',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '18px',
    alignSelf: "flex-end"
  },
  paragraph: {
    textAlign: 'left',
    font: 'normal normal normal',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '1',
    fontSize: '13px'
  },
  textInput: {
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '1'
  },
  payContainer: {
    display: 'inline-block',
    width: '100%',
    flexDirection: 'row',
    marginTop: '20px',
    [theme.breakpoints.between("xs", "md")]: {
      position: "fixed",
      top: "10px",
      zIndex: "999",
      left: "0",
      backgroundColor: "white",
      padding: "21px",
    },
  },
  priceText: {
    display: 'inline-block',
    justifyContent: 'flex-start',
    marginRight: '20px',
    alignItems: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px',
    [theme.breakpoints.between("xs", "md")]: {
      color: 'white',
    },
  },
  payButton: {
    width: '240px',
    color: 'white',
    backgroundColor: '#28A745',
    fontSize: "20px",
    fontWeight: "bold",
    borderRadius: "0px",
    "&:hover": {
      backgroundColor: 'white',
      color: '#28A745',
    },
    [theme.breakpoints.between("xs", "md")]: {
      width: '80%',
    },
  },
  dialogContent: {
    width: '100%',
    padding: "0px"
  },
  dialogTitle: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '30px',
    letterSpacing: '0px',
    textTransform: 'uppercase',
    opacity: '1',
    marginBottom: '25px',
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: '22px',
    },
  },
  dialogSquare: {
    height: 'auto',
    margin: '13px',
    padding: '10px'
  },
  dialogContentCol: {
    display: 'flex',
    flexDirection: 'column',
    width: "50%",
    columnCount: "2",
    [theme.breakpoints.between("xs", "md")]: {
      display: "contents",
    }
  },
  dialogItemContent: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '15px',
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '30px',
  },
  dialogSubTitle: {
    color: '#2F6472',
    textAlign: 'left',
    font: 'normal normal bold',
    fontSize: '16px',
    letterSpacing: '0px',
    opacity: '1',
    marginTop: '10px'
  },
  styleBox: {
    padding: "2%",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    background: 'white',
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "80%",
      height: "90%",
    },
  },

  titleMultimedia: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.between("xs", "md")]: {
      display: "inline-block",
    },
  },

  containerMultimedia: {
    [theme.breakpoints.between("xs", "md")]: {
      backgrondColor: 'red',
      width: "100%",
      height: '100%',
    },
  },
  recap: {
    fontSize: "50px",
  },
  formatDate: {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "20px",
    marginRight: "20px",
    "& input": {
      fontSize: "20px",
      fontWeight: "600",
    },
    "& button": {
      color: "#5CBEFF"
    },
    "& .MuiPickersToolbar-toolbar ": {
      backgrondColor: "#2F6472",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  selectCountry: {
    "& label": {
      fontSize: "17px"
    }
  }
}));
const ToothIcon = props => (
  <img src={Tooth} alt="Tooth" width="30" height="30" {...props} />
)

const InsurancePage = (props) => {

  const classes = useStyles();

  const currentUser = useSelector((state) => state.auth.data);
  const isResponsive = useMediaQuery('(max-width:1200px)');

  const { t } = useTranslation('common');

  const { countries, school, dispatch } = props;

  const history = useHistory();
  let minDateValue = new Date();
  minDateValue.setDate(minDateValue.getDate() + 1);
  let backDateValue = new Date();
  backDateValue.setDate(backDateValue.getDate() + 2);
  let [value, setValue] = useState([minDateValue, backDateValue]);
  let [maxDate, setMaxDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
  let [minDate, setMinDate] = useState(new Date());

  const getNumberOfMonths = () => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);

    const timeDifference = endDate.getTime() - startDate.getTime();
    const NumberOfMonths = Math.ceil(timeDifference / (1000 * 60 * 60 * 24) / 31);
    return NumberOfMonths;
  }

  const [NumberOfMonths, setNumberOfMonths] = useState(getNumberOfMonths());
  const [countrySelected, setCountry] = useState({ id: '', name: '' });
  const [multimediaPaymentMethod, setMultimediaPaymentMethod] = useState("month");
  const [open, setOpen] = useState(false);
  const service = LocalStorageService.getService();
  const handleStartDateChange = (date) => {
    let valueMaxDate = new Date(date);
    let valueBackDate = new Date(date);
    new Date(valueMaxDate.setDate(valueMaxDate.getDate() + 364));
    setMaxDate(new Date(valueMaxDate));
    value[0] = date;
    if (date >= value[1]) {
      value[1] = new Date(valueBackDate.setDate(valueBackDate.getDate() + 1));
    }
    setValue([...value]);
    getPriceBySelectionCountryAndDateRange('');
    const NumberOfMonths = getNumberOfMonths();
    setNumberOfMonths(NumberOfMonths);
  };

  const handleEndDateChange = (date) => {
    value[1] = date;
    setValue([...value]);
    getPriceBySelectionCountryAndDateRange('');
    const NumberOfMonths = getNumberOfMonths();
    setNumberOfMonths(NumberOfMonths);

  };
  const handleClickOpen = () => {
    setOpen(true);
    if (countrySelected.name === 'France') {
      dispatch(detailMultimediaSuccess({ active: false }));
    }
  };


  useEffect(() => {
    let minDateValue = new Date(value[0]);

    setMinDate(new Date(minDateValue.setDate(minDateValue.getDate() + 1)));

  }, [value[0]]);


  const handleClose = () => {
    setOpen(false);

    setFormState((prevState) => ({
      addMultimediaInsurance: null,
      values: {
        acceptTerm: null,
        acceptTerm2: null,
        countryNationality: prevState.values.countryNationality === 'France' ? null : prevState.values.countryNationality,
        addressOrigin: null,
        additionalPriceId: null,
      }

    }));
  };

  const handleChange = (option) => {
    if (option === null) {
      setCountry({ id: '', name: '' });
      getPriceBySelectionCountryAndDateRange('');
    } else {
      setCountry(option);
      getPriceBySelectionCountryAndDateRange(option.id);
    }
  };

  useEffect(() => {
    if (!(countries.data && countries.data.length)) {
      dispatch(listCountries());
    }
    if (!(school.data && school.data.length) && currentUser.roles[0] !== 'ROLE_STUDENT_CLASSIC') {
      dispatch(getSchoolByStudent());
    }
  }, []);

  const guarantedBoard = [
    {
      icon: LocalHospitalIcon,
      title: t('insurance.health-mobility.guaranted-board.medical-expenses.title'),
      text: t('insurance.health-mobility.guaranted-board.medical-expenses.text')
    },
    {
      icon: ToothIcon,
      title: t('insurance.health-mobility.guaranted-board.emergency-dental-costs.title'),
      text: t('insurance.health-mobility.guaranted-board.emergency-dental-costs.text')
    },
    {
      icon: FlightTakeoffIcon,
      title: t('insurance.health-mobility.guaranted-board.repatriation.title'),
      text: t('insurance.health-mobility.guaranted-board.repatriation.text')
    },
    {
      icon: WorkIcon,
      title: t('insurance.health-mobility.guaranted-board.luggage.title'),
      text: t('insurance.health-mobility.guaranted-board.luggage.text')
    },
    {
      icon: SecurityIcon,
      title: t('insurance.health-mobility.guaranted-board.support.title'),
      text: t('insurance.health-mobility.guaranted-board.support.text')
    },
    {
      icon: PersonIcon,
      title: t('insurance.health-mobility.guaranted-board.personal-liability-abroad.title'),
      text: t('insurance.health-mobility.guaranted-board.personal-liability-abroad.text')
    },
    {
      icon: WarningIcon,
      title: t('insurance.health-mobility.guaranted-board.individual-travel-accident-insurance.title'),
      text: t('insurance.health-mobility.guaranted-board.individual-travel-accident-insurance.text')
    },
    {
      icon: LocalHospitalIcon,
      title: t('insurance.health-mobility.guaranted-board.assistance-in-the-event-of-death.title'),
      text: t('insurance.health-mobility.guaranted-board.assistance-in-the-event-of-death.text')
    },
    {
      icon: HelpOutlineIcon,
      title: t('insurance.health-mobility.guaranted-board.do-you-know.title'),
      text: t('insurance.health-mobility.guaranted-board.do-you-know.text'),
      space: 12
    }
  ]

  const handleLinkDownload = (down) => {
    let selectedCountry = countries.data.find(country => country.id === countrySelected.id)

    const data = {
      "startDate": moment(value[0]).format('DD/MM/YYYY'),
      "endDate": moment(value[1]).format('DD/MM/YYYY'),
      "country": selectedCountry
    };
    dispatch(downloadContract({ params: data, history }));
  }

  const enabledByConditional = () => {
    return ((countrySelected.id !== '') && (value !== null && value[0] !== undefined && value[1] !== undefined));
  }

  const restrictForIsForeignAndPsStudent = (selected) => {
    const selection = selected === '' ? countrySelected.id : selected;
    let selectedCountry = countries.data.find(country => country.id === selection)

    if (currentUser && currentUser.roles && currentUser.roles.some(item => item !== Roles.STUDENT_INCLUSIVE.value)) {
      return false;
    } else if (currentUser && currentUser.roles && currentUser.roles.some(item => item === Roles.STUDENT_INCLUSIVE.value) && selectedCountry.name == "France" && school && school.school && school.school.insuranceForForeign == false) {
      return false;
    } else if (currentUser && currentUser.roles && currentUser.roles.some(item => item === Roles.STUDENT_INCLUSIVE.value)) {
      return true;
    }

    return false;
  }

  const getPriceBySelectionCountryAndDateRange = (selected) => {
    let partnerLevelHealthMobility = 0;
    if (school.school.partnerLevelHealthMobility !== undefined) {
      partnerLevelHealthMobility = school.school.partnerLevelHealthMobility;
    }
    const selection = selected === '' ? countrySelected.id : selected;
    if (selection !== '' && (value !== null && value[0] !== undefined && value[1] !== undefined) && validateViewPayment(selection)) {
      const countryObj = countries.data.find(country => country.id === selection)
      let monthsCount = moment(value[1]).diff(moment(value[0]), 'months', true);
      monthsCount = parseInt(monthsCount) + 1;
      dispatch(getPublicKey({ zone: countryObj.zone, month: monthsCount, partnerLevelHealthMobilityPrice: partnerLevelHealthMobility, history }));
    }
  }

  const validateViewPayment = (selected = '') => {
    const selection = selected === '' ? countrySelected.id : selected;
    let selectedCountry = countries.data.find(country => country.id === selection)

    return selection !== '' && (value !== null && value[0] !== undefined && value[1] !== undefined) ?
      (currentUser && currentUser.roles && currentUser.roles.some(item => item !== Roles.STUDENT_INCLUSIVE.value)) ? true :
        school && school.school && school.school.insuranceForForeign === true ? false :
          selectedCountry.name === "France" : false
  }

  const downloadGuaranteeTableForPS = () => {
    const url = { text: countries.dataDetails.price.name, url: '/document/health_mobility/' + countries.dataDetails.price.tg };
    let link = document.createElement('a');
    link.href = url.url;
    link.setAttribute(
      'download',
      `tableau de garantie.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }
  const handleLinkDownloadGuaranteeTables = () => {
    currentUser.roles.some(item => item === Roles.STUDENT_INCLUSIVE.value) ?
      dispatch(downloadGuaranteesTable()) : downloadGuaranteeTableForPS();

  }

  const handleClickPayment = () => {
    let selectedCountry = countries.data.find(country => country.id === countrySelected.id)

    const healthMobilityDetails = {
      startDate: moment(value[0]).format('DD/MM/YYYY'),
      endDate: moment(value[1]).format('DD/MM/YYYY'),
      country: selectedCountry,
      countryName: selectedCountry.name,
      healthMobilityPrime: (countries.dataDetails.stripe.unit_amount / 100),
      additionalPriceId: formState.values.countryNationality && formState.values.addressOrigin ? 0 : multimediaInsurance.product ?? null,
      addMultimediaInsurance: multimediaInsurance ? multimediaInsurance.active ? multimediaInsurance : null : null,
      countryNationality: formState.values.countryNationality,
      addressOrigin: formState.values.addressOrigin,
    };


    service.setHealthMobilityContract(healthMobilityDetails);
    redirectPaymentPageMobility({
      priceId: countries.dataDetails.stripe.id,
      description: "Départ du " + healthMobilityDetails.startDate + " au " + healthMobilityDetails.endDate + " à " + healthMobilityDetails.countryName,
      email: currentUser.username,
      additionalPriceId: formState.values.countryNationality && formState.values.addressOrigin ? 0 : multimediaInsurance.product ?? null,
    });
  }



  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);


  const multimediaInsurance = useSelector((state) => state.multimediaInsurance.dataDetails);


  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      acceptTerm2: false,
      acceptTerm: false,
      addressOrigin: '',
      countryNationality: '',
    },
    touched: {},
    errors: {},
    flag: true,
  });
  const checked = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleInputChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} align="center" justify="center" alignItems="center">
            <Typography variant="h2" className={classes.categoryTitle}>
              {t('insurance.health-mobility.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
            <div className={classes.itemContent}>
              <Grid container spacing={0}>
                <Grid item xs={0} sm={0} lg={3} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                </Grid>
                <Grid item xs={12} sm={3} lg={1} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                  <Typography className={classes.wrapText} variant="h4">
                    {t('insurance.health-mobility.i-go')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={2} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        className={classes.formatDate}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="start-date-picker-inline"
                        value={value[0]}
                        locale={'fr'}
                        minDate={minDateValue}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={1} lg={1} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                  <Typography className={classes.wrapText} variant="h4">
                    {t('insurance.health-mobility.to')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={2} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        className={classes.formatDate}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="end-date-picker-inline"
                        value={value[1]}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </div>
            <div className={classes.selectCountry}>
              <Typography
                className={classes.wrapIcon}
                style={{ textTransform: 'uppercase' }}
                variant="h4">
              </Typography>
              <Autocomplete
                id="countriesSelect"
                fullWidth
                value={countrySelected}
                options={countries.data}
                getOptionLabel={(option) => option.name}
                onChange={(event, option) => handleChange(option) && isResponsive}
                style={{ width: "80%" }}
                renderInput={(params) => <TextField {...params} label={t('insurance.health-mobility.selectCountry')} />}
              />
            </div>

            {validateViewPayment() && (
              <div className={classes.payContainer}>
                {countries && countries.dataDetails !== null && countries.dataDetails.stripe.unit_amount && !isResponsive && (
                  <div className={classes.priceText}>
                    {'PRIX : ' + countries.dataDetails.stripe.unit_amount / 100 + '€'}
                  </div>)
                }
                <Button
                  type="submit"
                  className={classes.payButton}
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}>
                  {countries && countries.dataDetails !== null && countries.dataDetails.stripe.unit_amount && isResponsive && (
                    <div className={classes.priceText}>
                      {'PRIX : ' + countries.dataDetails.stripe.unit_amount / 100 + '€'}
                    </div>)
                  }
                  {t('insurance.health-mobility.paymentButton')}
                </Button>
              </div>)
            }
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg" classes="container">
              <DialogContent className={classes.dialogContent}>
                <Paper className={classes.dialogSquare}>
                  <Grid container>
                    <Grid item sm={6} xs={12} style={{ padding: "22px", borderRight: "solid 1px #F1F1F1" }}>

                      <div>
                        {countrySelected.name !== 'France' ? (
                          <div className={classes.containerMultimedia}>
                            <Typography variant="h2" className={classes.dialogTitle} style={{ textAlign: "center" }}>{t('insurance.health-mobility.dialogPayment.title')}</Typography>
                            <span className={classes.titleMultimedia}>{t('insurance.health-mobility.dialogPayment.titleMultimedia')}</span>
                            <div style={{ marginTop: '10px' }}>
                              <FormControl>
                                <RadioGroup
                                  aria-label="paymentMethod"
                                  name="paymentMethod"
                                  defaultValue={"month"}
                                  value={multimediaPaymentMethod}
                                  onChange={() => setMultimediaPaymentMethod(multimediaPaymentMethod === "month" ? "year" : "month")}
                                >
                                  <div>
                                    <FormControlLabel
                                      value={"year"}
                                      control={<Radio />}
                                      label={t('insurance.health-mobility.dialogPayment.annual')}
                                    />
                                    <FormControlLabel
                                      value={"month"}
                                      control={<Radio />}
                                      label={t('insurance.health-mobility.dialogPayment.monthly')}
                                    />
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <contraintsStep1></contraintsStep1>
                            <MultimediaOption interval={multimediaPaymentMethod} optionType="Multimedia_Health" />
                            {multimediaInsurance && multimediaInsurance.active &&
                              <>
                                <Grid item xs={12} align="left" justify="left" alignItems="left" className={classes.contentContainer}>
                                  <div>
                                    <p>
                                      <Checkbox
                                        checked={formState.values.acceptTerm2}
                                        onChange={checked}
                                        name="acceptTerm2"
                                        color="primary"
                                      />
                                      {t("insurance.multimedia.acceptTerm2")}</p>
                                    <FormHelperText className={classes.error}></FormHelperText>
                                  </div>
                                </Grid>
                                <Grid item xs={12} align="left" justify="left" alignItems="left" className={classes.contentContainer}>
                                  <div>
                                    <p>
                                      <Checkbox
                                        checked={formState.values.acceptTerm}
                                        onChange={checked}
                                        name="acceptTerm"
                                        color="primary"
                                      />
                                      {t("insurance.multimedia.acceptTerm")}</p>
                                    <FormHelperText className={classes.error}></FormHelperText>
                                  </div>
                                </Grid>
                              </>
                            }
                          </div>

                        ) : (

                          <div>
                            <Typography variant="h2" className={classes.dialogTitle} style={{ textAlign: "center" }}>{t('insurance.health-mobility.dialogPayment.titleInfoSupp')}</Typography>
                            {isResponsive ? (
                              <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} md={6} lg={4}>
                                  <TextField
                                    required
                                    id="countryNationality"
                                    label={t('health-mobility-certificate.field.countryNationality')}
                                    variant="outlined"
                                    value={formState.values.countryNationality || ''}
                                    onChange={handleInputChange}
                                    name='countryNationality'
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <TextField
                                    required
                                    id="addressOrigin"
                                    label={t('health-mobility-certificate.field.addressOrigin')}
                                    variant="outlined"
                                    value={formState.values.addressOrigin || ''}
                                    onChange={handleInputChange}
                                    name='addressOrigin'
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid>
                                <TextField style={{ width: "300px", marginLeft: '100px', marginTop: '100px' }}
                                  required
                                  id="standard-required"
                                  label={t('health-mobility-certificate.field.countryNationality')}
                                  defaultValue=""
                                  variant="standard"
                                  value={formState.values.countryNationality || ''}
                                  onChange={handleInputChange}
                                  name='countryNationality'
                                />
                                <TextField style={{ width: "300px", marginLeft: '100px' }}
                                  required
                                  id="standard-required"
                                  label={t('health-mobility-certificate.field.addressOrigin')}
                                  defaultValue=""
                                  variant="standard"
                                  value={formState.values.addressOrigin || ''}
                                  onChange={handleInputChange}
                                  name='addressOrigin'
                                />
                              </Grid>
                            )}
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12} style={{ padding: "22px" }}>
                      <Grid item xs={12}>
                        <Typography variant="h2" className={classes.dialogTitle} style={{ textAlign: "center" }}>{t('insurance.health-mobility.dialogPayment.titleRecap')}</Typography>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                          <span className={classes.dialogItemContent} style={{ fontWeight: "bold" }}>{t('insurance.health-mobility.dialogPayment.countrySelected')}</span>
                          <box className={classes.dialogItemContent}>
                            {countrySelected.name}
                          </box>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                          <span className={classes.dialogItemContent} style={{ justifyContent: 'space-arround', fontWeight: "bold" }}>{t('insurance.health-mobility.dialogPayment.startDate')}</span>
                          <box className={classes.dialogItemContent} style={{ justifyContent: 'space-arround' }}>
                            {moment(value[0]).format('DD/MM/YYYY')}
                          </box>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                          <span className={classes.dialogItemContent} style={{ fontWeight: "bold" }}>{t('insurance.health-mobility.dialogPayment.endDate')}</span>
                          <box className={classes.dialogItemContent} >
                            {moment(value[1]).format('DD/MM/YYYY')}
                          </box>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                          <span className={classes.dialogItemContent} style={{ fontWeight: "bold" }}>{t('insurance.health-mobility.dialogPayment.NumberOfMonths')}</span>
                          <span className={classes.dialogItemContent}>
                            {NumberOfMonths + t('insurance.health-mobility.dialogPayment.month')}
                          </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "20px" }}>
                          <span className={classes.dialogItemContent} style={{ fontWeight: "bold" }}>{t('insurance.health-mobility.dialogPayment.price')}</span>
                          {countries && countries.dataDetails !== null && countries.dataDetails.stripe.unit_amount && (
                            <span className={classes.dialogItemContent} style={{ color: '#2F6472' }}>
                              {countries.dataDetails.stripe.unit_amount / 100 + '€'}
                            </span>)
                          }
                        </div>
                        {multimediaInsurance && multimediaInsurance.active &&
                          <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "20px" }}>
                            <span className={classes.dialogItemContent} style={{ fontWeight: "bold", marginBottom: '20px' }}>{t('insurance.health-mobility.dialogPayment.multimediaInsurance')}</span>
                            <span className={classes.dialogItemContent} style={{ color: '#2F6472' }}>
                              {multimediaInsurance && multimediaInsurance.active ? (multimediaInsurance.unit_amount / 100).toFixed(2) : "0"}€
                            </span>
                          </div>
                        }
                        <Link color="inherit"
                          className={classes.dialogItemContent}
                          style={{ color: '#28A745' }}
                          variant='h5'
                          target="_blank"
                          href={countries.dataDetails === null ? null : '/document/health_mobility/' + countries.dataDetails.price.cg}>
                          {'Conditions générales de souscription'}
                        </Link>
                        <br></br>
                        <Link color="inherit"
                          className={classes.dialogItemContent}
                          style={{ color: '#28A745' }} p
                          variant='h5'
                          target="_blank"
                          href={countries.dataDetails === null ? null : '/document/health_mobility/' + countries.dataDetails.price.ipid}>
                          {'IPID'}
                        </Link>
                        {countrySelected && (countrySelected.name === 'France') && (
                          <DialogActions style={{ marginRight: '50px' }}>
                            <Button
                              type="submit"
                              disabled={
                                (formState.values.countryNationality && formState.values.addressOrigin)
                                  ? false
                                  : true
                              }
                              className={classes.payButton}
                              variant="contained"
                              color="primary"
                              onClick={handleClickPayment}
                            >
                              {t('insurance.health-mobility.dialogPayment.paymentButton')}
                            </Button>
                          </DialogActions>
                        )}
                      </Grid>
                      <Grid container>
                        {multimediaInsurance && multimediaInsurance.active &&
                          <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12}>
                              <Grid container style={{ marginBottom: '50px' }}>
                                {countries.data.length !== 0 && multimediaInsurance !== null &&
                                  <Typography variant="h5" style={{ color: "#2F6472", marginTop: "50px", fontSize: "15px", opacity: '1' }}>
                                    {t('insurance.health-mobility.dialogPayment.either', { price: (countries.dataDetails.stripe.unit_amount / 100).toFixed(2) })}
                                    {countries.dataDetails.stripe.unit_amount / 100 + '€'} + {(multimediaInsurance.unit_amount / 100).toFixed(2) + '€'}
                                    &nbsp;( Option Multimédia ) {multimediaPaymentMethod === "month" ? t('insurance.health-mobility.dialogPayment.perMonth') : t('insurance.health-mobility.dialogPayment.perYear')}
                                    {multimediaPaymentMethod === "month" && <span style={{ display: 'block' }}>{t('insurance.health-mobility.dialogPayment.engagementMonths')}</span>}
                                  </Typography>
                                }
                              </Grid>
                              <Grid item xs={12}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
                                  <span className={classes.dialogItemContent} style={{ fontWeight: 'bold' }}>
                                    {t('insurance.health-mobility.dialogPayment.payToday')}
                                  </span>
                                  <span className={classes.dialogItemContent} style={{ color: '#2F6472', fontSize: '20px' }}>
                                    {multimediaInsurance.active ?
                                      (parseFloat((multimediaInsurance.unit_amount / 100).toFixed(2)) + parseFloat((countries.dataDetails.stripe.unit_amount / 100).toFixed(2))) :
                                      (countries.dataDetails.stripe.unit_amount / 100).toFixed(2)}€
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </DialogContent>
              {!(countrySelected && (countrySelected.name === 'France')) && (
                <DialogActions style={{ marginRight: '50px' }}>
                  <Button
                    type="submit"
                    disabled={
                      (multimediaInsurance && !multimediaInsurance.active) ||
                        (formState.values.acceptTerm2 && formState.values.acceptTerm)
                        ? false
                        : true
                    }
                    className={classes.payButton}
                    variant="contained"
                    color="primary"
                    onClick={handleClickPayment}
                  >
                    {t('insurance.health-mobility.dialogPayment.paymentButton')}
                  </Button>
                </DialogActions>
              )}
            </Dialog>
            <div className={classes.itemContent}>
              {enabledByConditional() && restrictForIsForeignAndPsStudent('') ? (
                <div className={classes.payContainer}>
                  <Button
                    type="submit"
                    className={classes.payButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleLinkDownload(true)}>
                    {t('insurance.health-mobility.downLoadGeneratedCertificate')}
                  </Button>
                </div>) : (null)}
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid item lg={2} />
              <Grid item xs={12} align="center" justify="center" alignItems="center">
                <Typography variant="h3" style={{ textTransform: "uppercase" }}>
                  {t('insurance.health-mobility.guaranted-board.title')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={2}></Grid>
              <Grid item xs={12} sm={12} lg={8}>
                <Grid container spacing={2}>
                  {guarantedBoard.map((guaranted, index) => {
                    return (
                      <Grid item xs={12} sm={guaranted.space || 3} md={guaranted.space || 3} lg={guaranted.space || 3} >
                        <RockerSwitch Icon={guaranted.icon} title={guaranted.title} text={guaranted.text} index={index} backColor={"#89c9f5"} isUnlockMouseOver={false}></RockerSwitch>
                      </Grid>
                    )
                  }
                  )}
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.itemContent}>
              {enabledByConditional() ? (
                <Link color="inherit"
                  className={classes.linkDownLoad}
                  variant='h5'
                  onClick={() => { handleLinkDownloadGuaranteeTables() }}>
                  {t('insurance.health-mobility.downLoadGuaranteeTables')}
                </Link>) : (<span className={classes.linkDownloadDisabled}>
                  {t('insurance.health-mobility.downLoadGuaranteeTables')}
                </span>)}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

InsurancePage.propTypes = {
  history: PropTypes.object,
  listCountries: PropTypes.func,
  getSchoolByStudent: PropTypes.func,
  countries: PropTypes.object,
  school: PropTypes.object,
  interval: PropTypes.object,
};

const mapStateToProps = (state) => ({
  countries: state.insurance,
  school: state.school
});



export default connect(mapStateToProps)(InsurancePage);
