import { toastr } from "react-redux-toastr";
import * as actionTypes from "./actionTypes";
import { appLoading, appSuccess, appError } from "./appActions";
import { getSchoolByStudent } from "./schoolsActions";
import { offerList } from "./offersActions";
import { login } from "../../api/authApi";
import LocalStorageService from "../../services/LocalStorageService";
import { isAdminUser } from "../../services/utils";
export const authSuccess = (data) => ({
  type: actionTypes.AUTH_SUCCESS,
  payload: data,
});

export const authFailed = (error) => ({
  type: actionTypes.AUTH_FAILED,
  payload: error,
});

export const authLogout = () => ({
  type: actionTypes.AUTH_LOGOUT,
});

export const loginUser = (payload) => (dispatch) => {
  const userTmp = {
    username: payload.user.email,
    password: payload.user.password,
  };
  const { user, history } = payload;
  dispatch(appLoading());
  login(userTmp)
    .then( (response) => {
      const { data } = response;
      const { token, refresh_token } = data;
      const service = LocalStorageService.getService();
      service.setToken({ token, refresh_token });
      service.setUser({...data});
      dispatch(appSuccess());
      dispatch(authSuccess(data));
      if (isAdminUser(data.data)) {
        history.push("/schools");
      } else {
        history.push("/home");
      }
  }).catch((error) => {
      console.log(error);
      dispatch(authFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};

export const logoutUser = () => (dispatch) => {
  try {
    const service = LocalStorageService.getService();
    service.clearToken();
    service.clearUser();
    service.removeHealthMobilityContract();
    dispatch(authLogout());
    window.location.href = "/login/classic";
  } catch (e) {
    dispatch(appError(e));
  }
};
