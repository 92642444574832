import { instance as axios } from "./baseUrl";

export const contracts = (token) => axios.get("/contracts", { ...token });

export const updateContractAPI = (data) => axios.put("/contracts", data );

export const getDoc = (id) => axios.get(`/files/document/${id}`, {responseType: 'blob'});

export const getDocByStudent = (values) => axios.post('/contracts/contract_by_students', { ...values });

export const uploadContractHealthMobility = (data) => axios.post('/contracts/health_mobility/upload', data);

export const updateContractDocumentApi = (data) => axios.post(`/files/document/update`, data);

export const getCurrentContracts = (token) => axios.get("/contracts/current_contract",{ ...token });

export const getRenewingContracts = (token) => axios.get("/contracts/renewing_contract",{ ...token });

export const postRenewingContracts = (token) => axios.post("/contracts/renewing_contract",{ ...token });
