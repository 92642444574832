import * as actionTypes from '../actions/actionTypes'

const schoolForm = {
    emailDomain: "",
    id: null,
    name: "",
    partnershipLevel: "",
    path: "",
    studentsSchoolName: "",

}

const initialState = {
   data: [],
   school: {},
   schoolForm
}

export const schoolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_SCHOOLS_SUCCESS:
            const { data } = action.payload
            return { ...state, data }
        case actionTypes.LIST_SCHOOLS_FAILED:
            return { ...state, data: [], error: action.payload }
        case actionTypes.ADD_SCHOOL_SUCCESS:
            const { dataAdd } = action.payload
            return { ...state, dataAdd }
        case actionTypes.ADD_SCHOOL_FAILED:
            return { ...state, data: [], error: action.payload }
        case actionTypes.DETAILS_SCHOOLS_SUCCESS:
            const dataDetails = action.payload
            return { ...state, dataDetails }
        case actionTypes.DETAILS_MYSCHOOL_SUCCESS:
            const school = action.payload
            return { ...state, school }
        case actionTypes.DETAILS_SCHOOLS_FAILED:
            return { ...state, data: [], error: action.payload }
        case actionTypes.LOAD_SCHOOL_STUDENTS_SUCCESS:
            const dataLoad = action.payload
            return { ...state, dataLoad }
        case actionTypes.LOAD_SCHOOL_STUDENTS_FAILED:
            return { ...state, data: [], error: action.payload }
        default:
            return state
    }
}

