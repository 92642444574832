import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {useDispatch, useSelector} from "react-redux";
import { Grid, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { getCountRenewingContract, renewingContract } from "../../redux/actions/contractsActions";
import CachedIcon from '@material-ui/icons/Cached';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  contentInfo: {
    background: "#2f6471",
    color: "white",
    textAlign: "center",
    padding: "10px",
  },
  kpi: {
    fontSize: "30px",
    fontWeight: "500",
    color: "white",
  },
  button: {
    color: "white",
  }
}));

export const Dashboard = () => {
  const classes = useStyles();
  const {t} = useTranslation('common');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountRenewingContract())
   }, []);

  const countRenewingContract = useSelector((state) => state.contracts.countRenewingContract);
  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.container}>
          <Grid item xs={3} spacing={4} className={classes.container}>
            <Grid container xs={12} spacing={2} className={classes.contentInfo}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  Nombre de contrats à renouveler
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.kpi}>
                  {countRenewingContract}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <IconButton
                  className={classes.button}
                  onClick={() => {dispatch(renewingContract())}}>
                  <CachedIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
         </Grid>
      </Grid>
    </div>
  );
};
