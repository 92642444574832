import * as actionTypes from '../actions/actionTypes'

const initialState = {
  loading: false,
  redirect: false,
  successMessage: {active: false, message: ""},
  error: null,
}

export const app = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_LOADING:
      return { loading: true, redirect: false, error: null, successMessage: {active: null, message: ""} }
    case actionTypes.APP_REDIRECT:
      return { loading: true, redirect: true, error: null, successMessage: {active: null, message: ""} }
    case actionTypes.APP_SUCCESS:
      return { loading: false, redirect: false, error: null, successMessage: {active: null, message: ""} }
    case actionTypes.APP_ERROR:
      return { loading: false, redirect: false, error: action.payload, successMessage: {active: null, message: ""} }
    case actionTypes.APP_SUCCESS_MESSAGE:
      return { loading: true, redirect: false, error: null, successMessage: action.payload}
    default:
      return state
  }
}
