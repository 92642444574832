import React, { useEffect, useState, Fragment } from "react";
import { Route, Switch, useRouteMatch, BrowserRouter } from "react-router-dom";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import SignInBySchool from "./SignInBySchool";
import LoginRegistrationPagePartner from "./Components/Partner/LoginRegistrationPage";
import LoginRegistrationPageClasic from "./Components/Clasic/LoginRegistrationPage";
import { useSelector, connect } from "react-redux";
import { validateAccessUser } from "../../services/utils";
import { RouterPathApp } from "../../components/routers/RoutesPathApp";
import { getSchoolDetails } from "../../redux/actions/schoolsActions";

const SignInBySchoolSwitch = (props) => {
  const [typeSchool, setTypeSchool] = useState("");
  const match = useRouteMatch();
  const { school, getDetails, history } = props;

  useEffect(() => {
    processUrl();
  }, []);

  useEffect(() => {
    if (school.hasOwnProperty("dataDetails"))
      if (school.dataDetails.hasOwnProperty("partnershipLevel")) {
        setTypeSchool(school.dataDetails.partnershipLevel);
      }
  }, [school]);

  const processUrl = () => {
    if (match.params) {
      const { schoolName } = match.params;
      if (schoolName === "classic") {
        setTypeSchool("classic");
      } else {
        getDetails(schoolName, history);
      }
    }
  };

  return (
    <Fragment>
      {typeSchool === "inclusive" && <SignInBySchool {...props} />}
      {typeSchool === "partner" && <LoginRegistrationPagePartner {...props} />}
      {typeSchool === "classic" && <LoginRegistrationPageClasic {...props} />}
    </Fragment>
  );
};

SignInBySchoolSwitch.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  school: state.school,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDetails: (schoolName, history) =>
      dispatch(getSchoolDetails({ name: schoolName, history })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInBySchoolSwitch);
