import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {useDispatch} from "react-redux";
import {redirectHealthMutualContractApi} from "../../redux/actions/InsuranceActions";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
}));

export const RedirectHealthMutual = (props) => {
  const classes = useStyles();
  const history = useHistory(); 
  const [result, setResult] = useState(null);
  const [callApi, setCallApi] = useState(false);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if(!callApi){
      processUrl();
      setCallApi(true);
    }
  });

  const processUrl = () => {
      dispatch(redirectHealthMutualContractApi({history}));
  };

  return <div className={classes.root}>{result && result}</div>;
};
