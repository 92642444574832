import * as actionTypes from "./actionTypes";
import { appLoading, appSuccess, appError } from "./appActions";
import { toastr } from "react-redux-toastr";
import { registerPs, registerCs, resettingPasswordEmail as registerResettingPasswordEmail, resetPassword, studentListApi, sendConfirmationMailApi } from "../../api/studentsApi";
import { logoutUser } from "./authActions";
import { resettingPasswordApi } from "../../api/authApi"
import { useSelector } from "react-redux";

export const studentListSuccess = (data) => ({
  type: actionTypes.STUDENT_LIST_SUCCESS,
  payload: data,
});

export const studentListFailed = (data) => ({
  type: actionTypes.STUDENT_LIST_FAILED,
  payload: data,
});

export const registerStudentPs = (payload) => (dispatch) => {
  //const { student, history } = payload;
  dispatch(appLoading());
  registerPs(payload)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      //dispatch(addSchoolSuccess(data));
      toastr.success("INFO", "Pour confirmer la création de votre compte, nous vous invitons à consulter vos emails et cliquer sur le lien de confirmation.");
      //history.push('/schools');
    })
    .catch((error) => {
      dispatch(appError());
      if (error.response.data) {
        toastr.error("Error", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error("Error", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
        dispatch(logoutUser());
      }
    });
};

export const registerStudentCs = (payload) => (dispatch) => {
  //const { student, history } = payload;
  dispatch(appLoading());
  registerCs(payload)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      //dispatch(addSchoolSuccess(data));
      toastr.success("INFO", "Pour confirmer la création de votre compte, nous vous invitons à consulter vos emails et cliquer sur le lien de confirmation.");
      //history.push('/schools');
    })
    .catch((error) => {
      dispatch(appError());
      if (error.response.data) {
        toastr.error("Error", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error("Erreur", "Une erreur est survenue. Veuillez ré-essayer.");
        console.log("Error: ", "error connected to server");
        dispatch(logoutUser());
      }
    });
};

export const resettingPasswordEmail = (payload) => (dispatch) => {
  // const { student, history } = payload;
  dispatch(appLoading());

  registerResettingPasswordEmail(payload)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      toastr.success("INFO", "Un email vous a été envoyé.");
    })
    .catch((error) => {
      dispatch(appError());
      if (error.response.data) {
        toastr.error("Error", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error("Erreur", "Une erreur est survenue. Veuillez ré-essayer.");
        console.log("Error: ", "error connected to server");
        dispatch(logoutUser());
      }
    });
};

export const resettingPassword = (payload, history) => (dispatch) => {
   dispatch(appLoading());
   resettingPasswordApi(payload).then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      toastr.success("INFO", "Mot de passe réinitialisé!");
      history.push('/login/classic');

    })
    .catch((error) => {
      dispatch(appError());
      if(error.response.data.message
          &&   error.response.data.errors.children.password.errors[0]){
         console.log('Error: ', error.response.data.message + ". "
             // iterar
             +  error.response.data.errors.children.password.errors[0]);
          toastr.error('ERROR', error.response.data.message + ". "
              // iterar
              +  error.response.data.errors.children.password.errors[0]);
        } else {
            console.log('Error: ', 'error connected to server');
            toastr.error('Error', 'Une erreur est survenue lors de la connexion au serveur');
            }
      // toastr.error('', error.message);
     //  history.push('/not-found');
    })
}

export const studentList = (payload, history) => (dispatch) => {
  dispatch(appLoading());
  studentListApi()
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(studentListSuccess(data.data));
    })
    .catch((error) => {
      dispatch(studentListFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
      dispatch(logoutUser());
    });
}

export const sendConfirmationMail = (data, history) => (dispatch) => {
  dispatch(appLoading());
  sendConfirmationMailApi(data)
    .then((response) => {
      dispatch(appSuccess());
      toastr.success("INFO", "Email Envoyé");
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
      // dispatch(logoutUser());
    });
}