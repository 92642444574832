import React from 'react';
import { Route, Switch, useRouteMatch, BrowserRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import PropTypes from "prop-types";
import { validateAccessUser } from "../../services/utils";
import { RouterPathApp } from "../../components/routers/RoutesPathApp";
import { default as InsurancePage } from "./InsurancePage";
import LocalStorageService from "../../services/LocalStorageService";

export const InsuranceRouter = () =>  {

   const match = useRouteMatch();
    const currentUser = LocalStorageService.getUser();

   return validateAccessUser( RouterPathApp.INSURANCE, currentUser.roles[0]) ?(
       <BrowserRouter>
           <Switch>
               <Route exact path={`${match.path}`}>
                   <InsurancePage/>
               </Route>
               {/*<Route exact path={`${match.path}/create`}>
                   <SchoolForm/>
               </Route>
               <Route exact path={`${match.path}/:id`}>
                   <SchoolForm/>
               </Route>*/}
           </Switch>
       </BrowserRouter>
   ) : (
       <Redirect to="/home" />
   )
}

InsuranceRouter.propTypes = {
    history: PropTypes.object
};


