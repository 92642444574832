import * as actionTypes from '../actions/actionTypes'

const initialState = {
   offers: [],
}

export const offersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OFFER_LIST_SUCCESS:
            const offers = action.payload
            return { ...state, offers }
        case actionTypes.OFFER_LIST_FAILED:
            return { ...state, offers: [], error: action.payload }
        default:
            return state
    }
}

