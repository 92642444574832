import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getContracts } from "../../redux/actions/contractsActions";
import { DocumentsSection } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  categoryTitle: {
    textTransform: "uppercase",
    color: "#4374a8",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginBottom: "45px",
  },
  noContract: {
  },
}));

export const Documents = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUserToken = useSelector((state) => state.auth.token);
  const { t } = useTranslation("common");

  useEffect(() => {
    dispatch(getContracts({ token: currentUserToken }));
  }, []);

  const contracts = useSelector((state) => state.contracts);

  let haveHealthContract =
    contracts.data.length > 0
      ? contracts.data.some(function (doc) {
          return (
            doc.insuranceType === "Santé/Mobilité" &&
            doc.documentType === "Certificate" &&
            doc.paymentStatus !== "payment_pending"
          );
        })
      : false;

  let haveHomeContract =
    contracts.data.length > 0
      ? contracts.data.some(function (doc) {
          return (
            doc.insuranceType === "Habitation" &&
            doc.documentType === "Certificate" &&
            doc.paymentStatus !== "payment_pending"
          );
        })
      : false;

  let haveMultimediaContract =
    contracts.data.length > 0
      ? contracts.data.some(function (doc) {
          return (
            doc.insuranceType === "Multimédia" &&
            doc.documentType === "Contract" &&
            doc.paymentStatus !== "payment_pending"
          );
        })
      : false;

      return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} align="center" justify="center" alignItems="center">
          <Typography variant="h2" className={classes.categoryTitle}>
            {t("docs.title")}
          </Typography>
        </Grid>
        {contracts.data.length > 0 && haveHealthContract ? (
          <DocumentsSection
            documents={contracts.data.filter(
              (doc) => doc.insuranceType === "Santé/Mobilité" && doc.paymentStatus !== "payment_pending"

            )}
            type="sM"
          />
        ) : (
          <p></p>
        )}
        {contracts.data.length > 0 && haveHomeContract ? (
          <DocumentsSection
            documents={contracts.data.filter(
              (doc) => doc.insuranceType === "Habitation" && doc.paymentStatus !== "payment_pending"
            )}
            type="h"
          />
        ) : (
          <p></p>
        )}
        {contracts.data.length > 0 && haveMultimediaContract ? (
          <DocumentsSection
            documents={contracts.data.filter(
              (doc) => doc.insuranceType === "Multimédia" && doc.paymentStatus !== "payment_pending"
            )}
            type="m"
          />
        ) : (
          <p></p>
        )}
        {!haveHealthContract && !haveHomeContract && !haveMultimediaContract ? (
          <Grid item xs={12} align="center" justify="center" alignItems="center">
          <Typography variant="h2" className={classes.noContract}>
            {t("docs.none")}
          </Typography>
          </Grid>
        ) : (
          <p></p>
        )}
      </Grid>
    </div>
  );
};
