import React from "react";
import { makeStyles } from '@material-ui/styles';
import {Button, Grid, Typography} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useDispatch  } from 'react-redux';
import {LocalHospital as LocalHospitalIcon, FlightTakeoff as FlightTakeoffIcon, Work as WorkIcon} from '@material-ui/icons';
import RockerSwitch from "../../components/RockerSwitch/rockerSwitch";


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 55px 0 55px',
    flexGrow: 1,
  },
  styleBox: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "65%",
    height: "90%",
    background: 'white',
    boxShadow: "24",
    overflowY: "auto",
    overflowX: "hidden",
    p: "4",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  container: {
     display: 'flex',
     padding: '50px',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly',
     marginTop: "50px",
     [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "0px",
    },
  },
  categoryTitle : {
     textTransform: 'uppercase',
     color :'#38e059',
     fontWeight: 'bold',
     wordBreak: "break-all",
  },
  contentContainer: {
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.between("xs", "sm")]: {
      display: 'block',
    }
  },
  moreInformation:{
    color: "#4374a8",
    fontSize: "11px",
    fontStyle: "italic",
    textTransform: "none"
  },
  buttonOffer: {
    background: "#8ecece",
    height: "250px",
    width: "250px",
    borderRadius: "0px",
    fontSize: "20px",
    color: "white",
    "&:hover":{
      background: "#8ecece9e",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100px",
      width: "100px",
      "& .MuiTypography-h4":{
        fontSize: "12px!important",
      }
    },
 },
 mutualButton: {
  width: '100%',
  color: 'white',
  backgroundColor: '#38e059',
  fontSize: "20px",
  fontWeight: "bold",
  borderRadius: "0px",
  "&:hover":{
    backgroundColor: 'white',
    color: '#38e059', 
  },
  [theme.breakpoints.between("xs", "md")]: {
    width: '100%',
  },
  },
 selectedButton: {
  border: "solid 3px #4374A8",
},
noPadding: {
  padding: "0px",
},
}));



export const HealthMutualInsurance = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const {t} = useTranslation('common');

  const guarantedBoard = [
    {icon: LocalHospitalIcon,
     title: t('insurance.health-mobility.guaranted-board.medical-expenses.title'),
     text: t('insurance.health-mobility.guaranted-board.medical-expenses.text')
    },
    {icon: FlightTakeoffIcon,
     title: t('insurance.health-mobility.guaranted-board.repatriation.title'),
     text: t('insurance.health-mobility.guaranted-board.repatriation.text')
    },
    {icon: WorkIcon,
     title: t('insurance.health-mobility.guaranted-board.luggage.title'),
     text: t('insurance.health-mobility.guaranted-board.luggage.text')
    },
    {icon: LocalHospitalIcon,
     title: t('insurance.health-mobility.guaranted-board.assistance-in-the-event-of-death.title'),
     text: t('insurance.health-mobility.guaranted-board.assistance-in-the-event-of-death.text')
    },
  ]

  return (
      <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={12} align="center" justify="center" alignItems="center">
          <Typography variant="h2" className={classes.categoryTitle}>
            {t('insurance.health-mutual.title')}
          </Typography>
        </Grid>     
        <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12} sm={12} lg={8}>
          <Button
            type="submit"
            className={classes.mutualButton}
            variant="contained"
            color="primary"
            onClick={null}>
            {t('insurance.health-mutual.subscribe')}
          </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={8}>
            <Grid container spacing={2}>
              {guarantedBoard.map((guaranted, index) => {       
                return (
                  <Grid item xs={12} sm={guaranted.space || 3} md={guaranted.space || 3} lg={guaranted.space || 3} >
                    <RockerSwitch Icon={guaranted.icon} title={guaranted.title} text={guaranted.text} index={index} backColor={"#38e059"} isUnlockMouseOver={true}></RockerSwitch>
                  </Grid>
              )}
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </div>
  );
 };
