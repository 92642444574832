import React, {useState, useEffect, useMemo} from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import SwipeableViews from 'react-swipeable-views';
import {Grid, Typography, Button, TextField,FormLabel, FormHelperText, Checkbox, Link, AppBar,Tabs, Tab, IconButton} from "@material-ui/core";
import PropTypes from 'prop-types';
import AutoCompleteAddress from "../../../components/AutoCompleteAddress/AutoCompleteAddressHomePath";
import validate from 'validate.js';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { redirectPaymentPageMultimedia } from "../../../services/utils";
import LocalStorageService from "../../../services/LocalStorageService";
import PDFViewer from 'pdf-viewer-reactjs'
import Tooltip from "@material-ui/core/Tooltip";
import {Publish as Upload, GetApp as DownloadIcon, FileCopy as ReplaceIcon} from '@material-ui/icons';
import { useTranslation } from "react-i18next";
import TabPanel from './TabPanel';

const multimediaConstraints = (t) => ({
  purchaseDate: {
    presence: { allowEmpty: true, message: t('validation.field.required') },
  },
  imei: {
    presence: { allowEmpty: true, message: t('validation.field.required') },
  },
  birthdayPlace: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  brand: {
    presence: { allowEmpty: true, message: t('validation.field.required') },
  },
  reference: {
    presence: { allowEmpty: true, message: t('validation.field.required') },
  },
  describe: {
    presence: { allowEmpty: true, message: t('validation.field.required') },
  },
  acceptTerm: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  acceptTerm2: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  }
});
const smartphoneConstraints = (t) => ({
  purchaseDate: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  imei: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  birthdayPlace: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  brand: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  reference: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  describe: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  acceptTerm: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  },
  acceptTerm2: {
    presence: { allowEmpty: false, message: t('validation.field.required') },
  }
});
const useStyles = makeStyles(theme => ({
  container: {
     display: 'flex',
     padding: '50px',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly'
  },
  categoryTitle : {
     textTransform: 'uppercase',
     color :'#4374a8',
     fontWeight: 'bold',
  },
  contentContainer: {
      paddingTop: "10px",
      paddingBottom: "10px",
      "& .MuiTextField-root": {
        marginTop: "10px",
        marginBottom: "10px",
      },
      "& .MuistandardInput-input": {
        padding: "13px",
      }
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.between("xs", "sm")]: {
      display: 'block',
    }
  },
  moreInformation:{
    color: "#4374a8",
    fontSize: "11px",
    fontStyle: "italic",
    textTransform: "none"
  },
  buttonOffer: {
    background: "#8ecece",
    height: "250px",
    width: "250px",
    borderRadius: "0px",
    fontSize: "20px",
    color: "white",
    "&:hover":{
      background: "#8ecece9e",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100px",
      width: "100px",
      "& .MuiTypography-h4":{
        fontSize: "12px!important",
      }
    },
 },
 fieldInformation: {
   width: "100%",
   [theme.breakpoints.between("xs", "sm")]: {
    width: "100%",
  },
 },
 addressField: {
   "& :first-child": {
      width: "100%",
      [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
   },
   "& label": {
    width: "auto",
    textAlign: "left",
 }
},
fieldContainer: {
  paddingRight: "10%",
  paddingLeft: "10%",
  paddingTop: "10%",
  [theme.breakpoints.between("xs", "sm")]: {
    paddingRight: "0px",
    paddingLeft: "0px",
    paddingTop: "0px",  },
},
pdfViewer:{
  "& .pdf div":{
    height: "auto!important",
  },
  "& .is-right":{
    visibility: "hidden",
  },
  " & .MuiBox-root":{
    padding: "0px",
  }
}
}));

 export const OfferStep2 = ({context}) => {


    const {t} = useTranslation('common');

    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [formState, setFormState] = useState({
      isValid: false,
      values: {
        purchaseDate: "",
        imei: "",
        brand: "",
        reference: "",
        describe: "",
        birthdayPlace: "",
        acceptTerm: "",
        acceptTerm2: "",
      },
      defaultValue: {
        purchaseDate: "",
        imei: "",
        brand: "Apple",
        reference: "Iphone 12 Pro",
        describe: "",
        birthdayPlace: "",
        acceptTerm: "",
        acceptTerm2: "",
      },
      touched: {},
      errors: {},
      flag: true,
    });
  
    const dateNow = new Date();
    let date = dateNow.getDate();
    let month = dateNow.getMonth() + 1;
    let year = dateNow.getFullYear();
    let today = date + '-' + month + '-' + year;
    const multimediaInsurance = useSelector((state) => state.multimediaInsurance.dataDetails);
    const [helperAddress, setHelperAddress] = useState('');
    const currentUser = useSelector((state) => state.auth.data);
    const service = LocalStorageService.getService();
    let schema = "";

    if(context === "smartphone"){
      schema = smartphoneConstraints(t);
    }else{
      schema = multimediaConstraints(t);
    }
    const [helperAcceptTerm, setHelperAcceptTerm] = useState('');
    const [helperAcceptTerm2, setHelperAcceptTerm2] = useState('');
    const [value, setValue] = React.useState(0);
    const fileReader = new FileReader();

    const fic = useMemo(() => ({ url: '/document/multimedia/' + multimediaInsurance.fic}), [multimediaInsurance]);
    const ipid = useMemo(() => ({ url: '/document/multimedia/ipid.pdf'}), [multimediaInsurance]);
    const notice = useMemo(() => ({ url: '/document/multimedia/notice_studcorp_m.pdf'}), [multimediaInsurance]);

    const handleChangePdf = (event, newValue) => {
      setValue(newValue);
    };
 
    const handleChangeIndexPdf = (index) => {
      setValue(index);
    };
    const [address, setAddress] = useState({});
 
   
      function sumDig(n)
      {
          let a = 0;
          while (n > 0)
          {
              a = a + n % 10;
              n = parseInt(n / 10, 10);
          }
          return a;
      }
   
      function isValidIMEI(n)
      {
   
          // Converting the number into
          // String for finding length
          let s = n.toString();
          let len = s.length;
   
          if (len !== 15)
              return false;
   
          let sum = 0;
          for(let i = len; i >= 1; i--)
          {
            let d = (n % 10);
   
            // Doubling every alternate digit
            if (i % 2 === 0)
                d = 2 * d;
   
            // Finding sum of the digits
            sum += sumDig(d);
            n = parseInt(n / 10, 10);
          }
   
          return (sum % 10 === 0);
      }

      const validatorImei = (imei) => {
        let matches = imei.match(/[0-9]{15}/g);
        if(matches){
          let valid = isValidIMEI(imei);
          return valid;
        }else{
          return false;
        }
      }
      
      useEffect(() => {
        let errors = validate(formState.values, schema);


        if(!formState.values.acceptTerm){
          errors = {acceptTerm: [t('validation.field.acceptTerm.incorrect')]};
          setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
          }));
        };

        if(!formState.values.acceptTerm2){
          errors = {acceptTerm: [t('validation.field.acceptTerm.incorrect')]};
          setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
          }));
        };

        if(!validatorImei(formState.values.imei) && (multimediaInsurance.condition.includes('+ tel') || context === "smartphone")){
          errors = {imei: [t('validation.field.imei.incorrect')]};
          setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
          }));
        };
        const addressValid = validateAddress();
        if( addressValid ){
          setHelperAddress('');
          setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
          }));
        }else{

          setHelperAddress(t("validation.field.required"));

          setFormState(formState => ({
            ...formState,
            isValid: false,
            errors: errors || {}
          }));
        }

      }, [formState.values]);

      const validateAddress = () => {
        let valid = false;
        if (address.hasOwnProperty('street') && address.hasOwnProperty('postalCode')
          && address.hasOwnProperty('city') && address.hasOwnProperty('country')) {
          return address.street.length > 0 && address.postalCode.length > 0
            && address.city.length > 0 && address.country.length > 0;
        }
    
        return valid;
      }
    
      useEffect(() => {
        let errors = validate(formState.values, schema);

        if( validateAddress() ){
          setHelperAddress('');
          setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
          }));
        }else{
          setFormState(formState => ({
            ...formState,
            isValid: false,
            errors: errors || {}
          }));    
        }
      }, [address]);

    const handleChange = (event) => {
        event.persist();

        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]:
              event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value,
          },
          touched: {
            ...formState.touched,
            [event.target.name]: true,
          },
        }));
      };
    
    const hasError = (field) => {
        if (formState.errors[field]) {
          if (formState.touched[field]) {
            return true;
          }
        }
        return false;
      }    

      const handleRegistration = (event) => {
    
        const addressValid = validateAddress();
        if( addressValid ){
          setHelperAddress('');
        }else{
          setHelperAddress(t("validation.field.required"));
        }

        if (Object.keys(formState.errors).length === 0 && addressValid) {
          const {values} = formState;
          const formatDate = moment(values.purchaseDate.replace(/-/g, "/")).format(
            "DD/MM/YYYY"
          );
          let data = {};
          let student = {};
          let multimediaContractService = service.getMultimediaContract();

          student.placeOfBirth = values.birthdayPlace;
          student.address = address;
          data.purchaseDate = formatDate;
          data.description = values.describe;
          data.brand = values.brand;
          data.model = values.reference;
          data.deviceType = "Smartphone";
          data.imei = values.imei;
          data.purchasePriceTtc = multimediaContractService.insuredDevice.purchasePriceTTC;
          // debugger;
          multimediaContractService = { ...multimediaContractService, insuredDevice: { ...multimediaContractService.insuredDevice, ...data}, student}
          service.setMultimediaContract( multimediaContractService );
          let description = "";
          description = "Information Smartphone: Date d'achat: " +  data.purchaseDate + " Description: "+  data.description + " Marque: " + data.brand + " Modele: " + data.model + " Type Produit: " + data.deviceType + " Imei: " + data.imei + " Prix d'achat: " + data.purchasePriceTtc;
          description = " " + description + " Information Utilisateur: Lieu de naissance: " +  student.placeOfBirth;
          redirectPaymentPageMultimedia({priceId: multimediaInsurance.product ,email: currentUser.username, description: description}, dispatch);
        }

      };

  return (
        <Grid container >
            <Grid item xs={12} align="center" justify="center" alignItems="center">
              <Typography variant="h2" className={classes.categoryTitle}>
                {t('insurance.multimedia.multimediaOffer')} {context}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
              <Grid container spacing={3} className={classes.fieldContainer}>
                {context === "smartphone" || multimediaInsurance.condition.includes('+ tel') ? ( 
                <>
                  <Grid item align="left" justify="center" alignItems="left" xs={12}>
                    <Typography variant="h4">
                      {t('insurance.multimedia.smartphoneInformation')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                    <TextField
                          className={classes.fieldInformation}
                          label={t("insurance.multimedia.brand")}
                          name="brand"
                          type="text"
                          placeholder="Apple"
                          variant='standard'
                          onChange={handleChange}
                          value={formState.values.brand}
                          defaultValue={formState.defaultValue.brand}
                      />
                  </Grid>
                  <Grid item xs={12} sm={4} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                    <FormLabel component="legend">
                        {t("insurance.multimedia.purchaseDate")}
                    </FormLabel>
                    <TextField
                      id="purchaseDate"
                      error={hasError("purchaseDate")}
                      helperText={
                      hasError("purchaseDate")
                          ? formState.errors.purchaseDate[0]
                          : null
                      }
                      name="purchaseDate"
                      value={formState.values.purchaseDate}
                      type="date"
                      onChange={handleChange}
                      defaultValue="2020-05-24"
                      className={classes.date}
                      InputLabelProps={{
                      shrink: false,
                      }}
                    />
                  </Grid>
                <Grid item xs={12} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                    <TextField
                        label={t("insurance.multimedia.reference")}
                        name="reference"
                        type="text"
                        placeholder="iPhone 13"
                        className={classes.fieldInformation}
                        onChange={handleChange}
                        value={formState.values.reference}
                        defaultValue={formState.defaultValue.reference}
                        variant="standard"
                    />
                  </Grid>
                  <Grid item xs={12} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                    <TextField
                            className={classes.fieldInformation}
                            label={t("insurance.multimedia.describe")}
                            name="describe"
                            placeholder="iPhone 13 Bleu 16Go"
                            type="text"
                            onChange={handleChange}
                            value={formState.values.describe}
                            variant="standard"
                            
                        />
                  </Grid>
                <Grid item xs={12} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                  <Tooltip title={t("insurance.multimedia.findImei")}>
                    <TextField
                            label={t("insurance.multimedia.imei")}
                            name="imei"
                            className={classes.fieldInformation}
                            type="text"
                            onChange={handleChange}
                            helperText={
                              hasError("imei")
                                  ? formState.errors.imei[0]
                                  : null
                              }
                            value={formState.values.imei}
                            variant="standard"
                            
                        />
                  </Tooltip>
                  </Grid>
                </>
                ) : (null)}
                <Grid item align="left" justify="center" alignItems="left" xs={12}>
                  <Typography variant="h4">
                    {t('insurance.multimedia.personnalInformation')}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="center" justify="center" alignItems="center" className={[classes.contentContainer, classes.addressField]}>
                  <AutoCompleteAddress setAddress={setAddress} />
                  <FormHelperText className={classes.error}>{helperAddress}</FormHelperText>
                </Grid>
                <Grid item xs={12} align="center" justify="center" alignItems="center" className={classes.contentContainer}>
                  <TextField
                          className={classes.fieldInformation}
                          label={t("insurance.multimedia.birthdayPlace")}
                          name="birthdayPlace"
                          type="text"
                          onChange={handleChange}
                          value={formState.values.birthdayPlace}
                          variant="standard"
                          
                      />
                </Grid>
                <Grid item xs={12} alig="left" justify="left" alignItems="left" className={classes.contentContainer}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChangePdf}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab label="fiche d'informations et de conseils" />
                      <Tab label="fiche Ipid" />
                      <Tab label="notice Studcorp" />

                    </Tabs>
                  </AppBar>
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndexPdf}
                    className={classes.pdfViewer}
                  >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                      <PDFViewer
                        document={fic}
                        navbarOnTop={true}
                        css="pdf"
                      />
                      <Link color="inherit"
                        className={classes.dialogItemContent}
                        style={{color: '#0E0DB4', fontSize: "13px"}}
                        variant='h5'
                        target="_blank"
                        href={multimediaInsurance === null ? null : '/document/multimedia/' + multimediaInsurance.fic}>
                        {"fiche d'informations et de conseils.pdf"}
                      </Link>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <PDFViewer
                        document={ipid}
                        navbarOnTop={true}
                        css="pdf"
                      />
                      <Link color="inherit"
                        className={classes.dialogItemContent}
                        style={{color: '#0E0DB4', fontSize: "13px", textAlign: "left"}}
                        variant='h5'
                        target="_blank"
                        href={multimediaInsurance === null ? null : '/document/multimedia/ipid.pdf' }>
                        {'fiche ipid.pdf'}
                      </Link>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                      <PDFViewer
                        document={notice}
                        navbarOnTop={true}
                        css="pdf"
                      />
                      <Link color="inherit"
                        className={classes.dialogItemContent}
                        style={{color: '#0E0DB4', fontSize: "13px", textAlign: "left"}}
                        variant='h5'
                        target="_blank"
                        href={multimediaInsurance === null ? null : '/document/multimedia/notice_studcorp_m.pdf' }>
                        {'notice studcorp.pdf'}
                      </Link>
                    </TabPanel>
                  </SwipeableViews>
                </Grid>
                <Grid item xs={12} align="left" justify="left" alignItems="left" className={classes.contentContainer}>
                <div>
                  <p>
                  <Checkbox
                    checked={formState.values.acceptTerm2}
                    onChange={handleChange}
                    name="acceptTerm2"
                    color="primary"
                  />
                  {t("insurance.multimedia.acceptTerm2")}</p>
                  <FormHelperText className={classes.error}>{helperAcceptTerm2}</FormHelperText>
                </div>  
                </Grid>
                <Grid item xs={12} align="left" justify="left" alignItems="left" className={classes.contentContainer}>
                <div>
                  <p>
                  <Checkbox
                    checked={formState.values.acceptTerm}
                    onChange={handleChange}
                    name="acceptTerm"
                    color="primary"
                  />
                  {t("insurance.multimedia.acceptTerm")}</p>
                  <FormHelperText className={classes.error}>{helperAcceptTerm}</FormHelperText>
                </div>  
                </Grid>
                <Grid item align="left" justify="left" alignItems="left" xs={12}>
                  <Typography variant="h4" className={classes.priceElement}>
                  {t('insurance.multimedia.totalCost')} {multimediaInsurance.unit_amount / 100} {t('insurance.multimedia.€perMonth')}
                  </Typography>
                </Grid>
                <Grid className={classes.categoryContentButton} item align="center" justify="center" alignItems="center" xs={12}>
                  <Button className={classes.submit} color="primary"
                    disabled={!formState.isValid}
                    size="large"
                    type="button"
                    onClick={handleRegistration}
                    variant="contained" >
                    {t('insurance.multimedia.subscribe')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
       </Grid>
  );
 };

 OfferStep2.propTypes = {
    context: PropTypes.string,
};

export default OfferStep2;