import React, {useEffect, useState} from 'react';
import {useDispatch, connect} from "react-redux";
import validate from "validate.js";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router";
import PropTypes from 'prop-types';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { loadSchoolStudents, listSchools } from "../../../../redux/actions/schoolsActions";
import { useTranslation } from "react-i18next";

const schema = (t) =>({
    schoolsF: {
        presence: { selected: false, message: t('validation.field.required') },
    },
   selectedFiles: {
        presence: { allowEmpty: false, message: t('validation.field.required') },
    }
});

const useStyles = makeStyles(theme => ({
  paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
  },
  avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
  },
  form: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center'
  },
  formControl: {
      margin: theme.spacing(2, 0 , 3),
      minWidth: "300px"
  },
  formField: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
  },
  submit: {
      margin: theme.spacing(3, 0, 2),
      width: "80%"
  },
}));

export const SchoolStudentsForm = props => {

    const { schools } = props;

    const history = useHistory();

    const classes = useStyles();

    const {t} = useTranslation('common');

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(listSchools())
     }, []);

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        flag: true,
    });

    // const schools = useSelector((state) => state.school.data);

    /*const getDataByEdit = () => {
        if (match.params.id && formState.flag){
            const { id } = match.params;
            formState.values = schools.find(item => item.id === parseInt(id));
            formState.flag = false;
            setFormState(formState);
        }
    }*/

    useEffect(() => {
        // getDataByEdit();
        const errors = validate(formState.values, schema, {fullMessages: false});
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleChange = event => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values, [event.target.name]:
                    event.target.type === 'file'
                        ? event.target.files
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleLoadStudents = event => {
        event.preventDefault();
        const { values } = formState;
        let formData = new FormData();
        if (values.selectedFiles && values.schoolsF) {
          formData.append("submitFile", values.selectedFiles[0]);
          dispatch(loadSchoolStudents({ id: values.schoolsF, file: formData, history }));
        }
    };

    const handleBack = () => {
        history.push("/schools");
    };

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.paper}>
                <div className={classes.contentHeader}>
                    <IconButton onClick={handleBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <Typography component="h1" variant="h3" margin="normal">
                Ajouter une liste d'étudiants
                </Typography>

                <form className={classes.form} onSubmit={handleLoadStudents}>
                        <div className={classes.formField}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="grouped-select">Sélectionner l'école</InputLabel>
                                    <Select defaultValue="" id="grouped-select"
                                            name="schoolsF"
                                            value={formState.values.schoolsF || ''}
                                            onChange={handleChange}>
                                        { schools.map((school) => (
                                            <MenuItem key={school.id} value={school.id}>
                                              {school.name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                </FormControl>

                                <div style ={{marginTop: 20}}>
                                    <label htmlFor="btn-upload">
                                        <input
                                            id="btn-upload"
                                            name="selectedFiles"
                                            style={{ display: 'none' }}
                                            type="file"
                                            onChange={handleChange}
                                            disabled={(!formState.values.schoolsF)} />
                                        <Button
                                            className="btn-choose"
                                            variant="outlined"
                                            component="span"
                                            disabled={ (!formState.values.schoolsF) } >
                                            Télécharger un fichier au format CSV
                                        </Button>
                                    </label>
                                    <div style ={{marginTop: 20}}>
                                        {formState.values.selectedFiles && formState.values.selectedFiles.length > 0 ? formState.values.selectedFiles[0].name : null}
                                    </div>
                                </div >
                            </div>
                        <Button className={classes.submit} color="primary"
                                 disabled={(!(formState.values.selectedFiles && formState.values.selectedFiles.length > 0)) }
                                 fullWidth
                                 size="large"
                                 type="submit"
                                 variant="contained" >
                                 Ajouter étudiants
                        </Button>

                </form>
            </div>
        </Container>
    );
}

SchoolStudentsForm.propTypes = {
    listSchool: PropTypes.func,
    schools: PropTypes.object,
    history: PropTypes.object
};

const mapStateToProps = (state) => ({
    schools: state.school.data,
});

export default connect(mapStateToProps)(SchoolStudentsForm);
