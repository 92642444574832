import * as actionTypes from "./actionTypes";
import { appLoading, appSuccess, appError } from "./appActions";
import { toastr } from "react-redux-toastr";
import {
  addSchoolApi,
  editSchoolApi,
  getSchoolByNameApi,
  getSchoolByStudentApi,
  listSchoolsApi,
  loadSchoolStudentsApi,
} from "../../api/schoolsApi";
import { logoutUser } from "./authActions";
import { useSelector } from "react-redux";

export const addSchoolSuccess = (data) => ({
  type: actionTypes.ADD_SCHOOL_SUCCESS,
  payload: data,
});

export const addSchoolFailed = (error) => ({
  type: actionTypes.ADD_SCHOOL_FAILED,
  payload: error,
});

export const editSchoolSuccess = (data) => ({
  type: actionTypes.EDIT_SCHOOL_SUCCESS,
  payload: data,
});

export const editSchoolFailed = (error) => ({
  type: actionTypes.EDIT_SCHOOL_FAILED,
  payload: error,
});

export const listSchoolsSuccess = (data) => ({
  type: actionTypes.LIST_SCHOOLS_SUCCESS,
  payload: data,
});

export const listSchoolsFailed = (error) => ({
  type: actionTypes.LIST_SCHOOLS_FAILED,
  payload: error,
});

export const SchoolDetailsSuccess = (data) => ({
  type: actionTypes.DETAILS_SCHOOLS_SUCCESS,
  payload: data,
});

export const SchoolDetailsFailed = (error) => ({
  type: actionTypes.DETAILS_SCHOOLS_FAILED,
  payload: error,
});

export const MySchoolDetailsSuccess = (data) => ({
    type: actionTypes.DETAILS_MYSCHOOL_SUCCESS,
    payload: data,
})

export const MySchoolDetailsFailed = (error) => ({
    type: actionTypes.DETAILS_MYSCHOOL_FAILED,
    payload: error,
})

export const loadSchoolStudentsSuccess = (data) => ({
  type: actionTypes.LOAD_SCHOOL_STUDENTS_SUCCESS,
  payload: data,
});

export const loadSchoolStudentsFailed = (error) => ({
  type: actionTypes.LOAD_SCHOOL_STUDENTS_FAILED,
  payload: error,
});

export const addSchools = (payload) => (dispatch) => {
    const { school, history } = payload;
    dispatch(appLoading());
    addSchoolApi(school)
        .then((response) => {
            const { data } = response;
            dispatch(appSuccess());
            dispatch(addSchoolSuccess(data));
            toastr.success('INFO', 'L\'école a bien été ajoutée.');
            history.push('/schools');
        })
        .catch((error) => {
            dispatch(addSchoolFailed(error));
            dispatch(appError());
            if(error.response.data){
                toastr.error('Error', error.response.data.message);
                console.log('Error: ', error.response.data.message);
            } else {
                toastr.error('Error', "Une erreur est survenue lors de la connexion au serveur.");
                console.log('Error: ', 'error connected to server');
                dispatch(logoutUser());
            }
        })
 }

export const editSchools = (payload) => (dispatch) => {
    const { id, school, history } = payload;

    dispatch(appLoading());
    editSchoolApi(id, school)
        .then((response) => {
            const { data } = response;
            dispatch(appSuccess());
            dispatch(editSchoolSuccess(data));
            toastr.success('INFO', 'L\'école a bien été mise à jour.');
            history.push('/schools');
        })
        .catch((error) => {
            dispatch(appError());
            dispatch(editSchoolFailed(error));
            if(error.response.data){
                toastr.error('Error', error.response.data.message);
                console.log('Error: ', error.response.data.message);
            } else {
                toastr.error('Error', "Une erreur est survenue lors de la connexion au serveur.");
                console.log('Error: ', 'error connected to server');
                dispatch(logoutUser());
            }
        })
}

export const listSchools = () => (dispatch) => {
  dispatch(appLoading());
  listSchoolsApi()
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(listSchoolsSuccess(data));
    })
    .catch((error) => {
      dispatch(listSchoolsSuccess(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
      dispatch(logoutUser());
    });
};

export const getSchoolDetails = (payload) => (dispatch) => {
    const { name, history } = payload;
    dispatch(appLoading());
    getSchoolByNameApi(name)
        .then((response) => {
            const { data } = response;
            const dataDetails = data.data;
            dispatch(appSuccess());
            dispatch(SchoolDetailsSuccess(dataDetails) );
        })
        .catch((error) => {
            dispatch(appError());
            dispatch(SchoolDetailsFailed(error));
            //debugger
            if(error && error.response && error.response.data){
                toastr.error('ERROR', error.response.data.message);
                console.log('Error: ', error.response.data.message);
            } else {
                console.log('Error: ', 'error connected to server');
                toastr.error('ERROR', 'Une erreur est survenue lors de la connexion au serveur.');
            }
            history.push('/');
        })
}

export const getSchoolByStudent = () => (dispatch) => {

    dispatch(appLoading());
    getSchoolByStudentApi()
        .then((response) => {
            const { data } = response;
            const school = data.data;
            console.log(school);
            dispatch(appSuccess());
            dispatch(MySchoolDetailsSuccess(school) );
            return school;
        })
        .catch((error) => {
            dispatch(appError());
            dispatch(MySchoolDetailsFailed(error));
            //debugger
            if(error && error.response && error.response.data){
                toastr.error('ERROR', error.response.data.message);
                console.log('Error: ', error.response.data.message);
            } else {
                console.log('Error: ', 'error connected to server');
                toastr.error('ERROR', 'Une erreur est survenue lors de la connexion au serveur');
            }
        })
}

export const loadSchoolStudents = (payload) => (dispatch) => {
  const { id, file, history } = payload;
  dispatch(appLoading());
  loadSchoolStudentsApi(id, file)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(loadSchoolStudentsSuccess(data));
      toastr.success("INFO", "La liste des étudiants chargée avec succès");
    })
    .catch((error) => {
      dispatch(appError());
      //dispatch(loadSchoolStudentsFailed(error));
      if (error.response.data) {
        toastr.error("Error", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error('Error', "Une erreur est survenue lors de la connexion au serveur");
        console.log('Error: ', 'error connected to server');
        dispatch(logoutUser());
      }
    });
};
