import * as actionTypes from "../actions/actionTypes";

const initialState = {
  data: [],
  error: null,
  hasCurrentMultimediaContract: false,
  hasCurrentHomeContract: false,
  countRenewingContract: 0,
};

export const contracts = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_CONTRACTS_SUCCESS:
      const { data } = action.payload;
      return { ...state, data };
    case actionTypes.LIST_CONTRACTS_FAILED:
      return { ...state, data: {}, error: action.payload };
    case actionTypes.HAS_CURRENT_HOME_CONTRACT:
        const { hasCurrentHomeContract } = action.payload;
        return { ...state, hasCurrentHomeContract };
    case actionTypes.HAS_CURRENT_MULTIMEDIA_CONTRACT:
          const { hasCurrentMultimediaContract } = action.payload;
          return { ...state, hasCurrentMultimediaContract };
    case actionTypes.COUNT_RENEWING_CONTRACT:
          const { countRenewingContract } = action.payload;
          return { ...state, countRenewingContract }
    default:
      return state;
  }
};
