import * as actionTypes from "./actionTypes";
import { appLoading, appSuccess, appError } from "./appActions";
import {
  validateRegisterIsApi,
  validateRegisterPsApi,
  validateRegisterCsApi,
} from "../../api/authApi";
import { toastr } from "react-redux-toastr";

export const validateRegisterIsSuccess = (data, tokenValidate) => ({
  type: actionTypes.VALIDATE_REGISTER_IS_SUCCESS,
  payload: { data },
});

export const validateRegisterIsFailed = (error) => ({
  type: actionTypes.VALIDATE_REGISTER_IS_FAILED,
  payload: error,
});

export const validateRegisterIsUser = (payload) => (dispatch) => {
  const { userIs, history } = payload;
  dispatch(appLoading());
  validateRegisterIsApi(userIs)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(validateRegisterIsSuccess(data.data));
      history.push("/register_is");
      //toastr.success('', 'Bienvenido');
    })
    .catch((error) => {
      dispatch(validateRegisterIsFailed(error));
      dispatch(appError());
      if (error.response && error.response.data) {
        toastr.error("", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error("", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
      history.push("/login");
    });
};

export const validateRegisterPsUser = (payload) => (dispatch) => {
  const { userIs, history } = payload;
  dispatch(appLoading());
  validateRegisterPsApi(userIs)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      //hacer la redireccion al login/schoolSlug
      history.push("/login/" + data.data.school.path);
    })
    .catch((error) => {
      dispatch(appError());
      if (error.response && error.response.data) {
        toastr.error("", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error("", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
      history.push("/login");
    });
};

export const validateRegisterCsUser = (payload) => (dispatch) => {
  const { userIs, history } = payload;
  dispatch(appLoading());
  validateRegisterCsApi(userIs)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      //hacer la redireccion al login/schoolSlug
      history.push("/login/classic");
    })
    .catch((error) => {
      dispatch(appError());
      if (error.response && error.response.data) {
        toastr.error("", error.response.data.message);
        console.log("Error: ", error.response.data.message);
      } else {
        toastr.error("", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
      history.push("/login");
    });
};
