import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'
import { isAuthenticated } from '../../services/utils'
import { useDispatch, useSelector } from "react-redux";
import { authSuccess, logoutUser } from "../../redux/actions/authActions";
import LocalStorageService from "../../services/LocalStorageService";
import { RouterPathApp } from './RoutesPathApp';
import { cleanDetailMultimediaSuccess } from "../../redux/actions/InsuranceActions";

export const PrivateRoute = (props) => {
  const { layout: Layout, component: Component, image, imageStyle, direction, path, ...rest } = props



  const currentUser = LocalStorageService.getUser();

  const dispatch = useDispatch();

  if(path === "/insurance/housing" || path === "/insurance/health-mobility"){
    dispatch(cleanDetailMultimediaSuccess());
  }
  
  const validateRoles = (pathList) => {

    for (const key in pathList) {
      if(pathList[key].href && pathList[key].href == window.location.pathname){
        return currentUser.roles.some(r=> pathList[key].roles.indexOf(r) >= 0)
      } else if(window.location.pathname.startsWith(pathList[key].href)){
        return validateRoles(pathList[key].items);
      }
    }
    return false;
  }

  const validateDataCurrentUser = () => {
        // TODO check logic by the change @Florian
        //if ( !currentUser.email ) {
        const data = isAuthenticated();
        
        const isRoleOk = validateRoles(RouterPathApp);

        if (!data || !isRoleOk) {
            return false;
          }
        dispatch(authSuccess(data));
        return true;
    }

  return validateDataCurrentUser() ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout image={image} imageStyle={imageStyle}  direction={direction}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to="/login/classic" />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  image: PropTypes.string,
  imageStyle: PropTypes.string,
  direction: PropTypes.string,
}
