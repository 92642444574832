import {toastr} from "react-redux-toastr";
import * as actionTypes from "./actionTypes";
import {appLoading, appSuccess, appError} from "./appActions";
import {claimEmail, postClaim, getClaims} from "../../api/claimApi";

export const getClaimsSuccess = (claims) => ({
  type: actionTypes.GET_ALL_CLAIMS_SUCCESS,
  payload: claims,
});

export const declareEmailClaim = (payload) => (dispatch) => {
  const {data} = payload;
  claimEmail(data)
    .then((response) => {
      dispatch(appSuccess);
      toastr.success("INFO", "Votre déclération de sinistre a bien été envoyée.");
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};

export const declareClaim = (payload) => (dispatch) => {
  const {data} = payload;
  postClaim(data)
    .then((response) => {
      dispatch(appSuccess);
      toastr.success("INFO", "Sinistre déclaré");
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};

export const getAllClaimForLoginUser = () => (dispatch) => {
  dispatch(appLoading());
  getClaims()
    .then((response) => {
      dispatch(appSuccess());
      dispatch(getClaimsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};
