// APP
export const APP_LOADING = "APP_LOADING";
export const APP_REDIRECT = "APP_REDIRECT";
export const APP_SUCCESS = "APP_SUCCESS";
export const APP_ERROR = "APP_ERROR";
export const APP_SUCCESS_MESSAGE = "APP_SUCCESS_MESSAGE";

// AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

// VALIDATE REGISTER IS
export const VALIDATE_REGISTER_IS_SUCCESS = "VALIDATE_REGISTER_IS_SUCCESS";
export const VALIDATE_REGISTER_IS_FAILED = "VALIDATE_REGISTER_IS_FAILED";

// CONFIRMATION REGISTER IS
export const CONFIRMATION_REGISTER_IS_SUCCESS =
  "CONFIRMATION_REGISTER_IS_SUCCESS";
export const CONFIRMATION_REGISTER_IS_FAILED =
  "CONFIRMATION_REGISTER_IS_FAILED";

//-------------------SCHOOL ACTION TYPE--------------------------------------------

// ADD SCHOOL
export const ADD_SCHOOL_SUCCESS = "ADD_SCHOOL_SUCCESS";
export const ADD_SCHOOL_FAILED = "ADD_SCHOOL_FAILED";

// EDIT SCHOOL
export const EDIT_SCHOOL_SUCCESS = "EDIT_SCHOOL_SUCCESS";
export const EDIT_SCHOOL_FAILED = "EDIT_SCHOOL_FAILED";

// LIST SCHOOL
export const LIST_SCHOOLS_SUCCESS = "LIST_SCHOOLS_SUCCESS";
export const LIST_SCHOOLS_FAILED = "LIST_SCHOOLS_FAILED";

// LIST STUDENTS
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
export const STUDENT_LIST_FAILED = "STUDENT_LIST_FAILED";

// DETAILS SCHOOL
export const DETAILS_SCHOOLS_SUCCESS = "DETAILS_SCHOOLS_SUCCESS";
export const DETAILS_SCHOOLS_FAILED = "DETAILS_SCHOOLS_FAILED";

// DETAILS MY SCHOOL
export const DETAILS_MYSCHOOL_SUCCESS = "DETAILS_MYSCHOOL_SUCCESS";
export const DETAILS_MYSCHOOL_FAILED = "DETAILS_MYSCHOOL_FAILED";

// LOAD SCHOOL STUDENTS FROM FILE
export const LOAD_SCHOOL_STUDENTS_SUCCESS = "LOAD_SCHOOL_STUDENTS_SUCCESS";
export const LOAD_SCHOOL_STUDENTS_FAILED = "LOAD_SCHOOL_STUDENTS_FAILED";

//-------------------CONTATC ACTION TYPE--------------------------------------------

// CONTACT FORM
export const SEND_CONTACT_SUCCESS = "SEND_CONTACT_SUCCESS";
export const SEND_CONTACT_FAILED = "SEND_CONTACT_FAILED";

//-------------------INSURANCE HEALTH MOBILITY ACTION TYPE--------------------------------------------

// LIST COUNTRIES
export const LIST_COUNTRIES_SUCCESS = "LIST_COUNTRIES_SUCCESS";
export const LIST_COUNTRIES_FAILED = "LIST_COUNTRIES_FAILED";

// CONTRACTS HEALTH MOBILITY ACTION TYPE
export const CONTRACTS_HEALTH_MOBILITY_SUCCESS =
  "CONTRACTS_HEALTH_MOBILITY_SUCCESS";
export const CONTRACTS_HEALTH_MOBILITY_FAILED =
  "CONTRACTS_HEALTH_MOBILITY_FAILED";

export const TOKEN_DIGITAL_FIRMA_HEALTH_MOBILITY_INSURANCE_SUCCESS = "TOKEN_DIGITAL_FIRMA_HEALTH_MOBILITY_INSURANCE_SUCCESS";
export const TOKEN_DIGITAL_FIRMA_HEALTH_MOBILITY_INSURANCE_FAILED = "TOKEN_DIGITAL_FIRMA_HEALTH_MOBILITY_INSURANCE_FAILED";

// CONTRACT MULTIMEDIA

export const CREATE_CONTRACT_HEALTH_MOBILITY_INSURANCE_SUCCESS = "CREATE_CONTRACT_HEALTH_MOBILITY_INSURANCE_SUCCESS";
export const CREATE_CONTRACT_HEALTH_MOBILITY_INSURANCE_FAILED = "CREATE_CONTRACT_HEALTH_MOBILITY_INSURANCE_FAILED";


//-------------------INSURANCE HOUSE ACTION TYPE--------------------------------------------
// PRICE
export const GET_PRICE_SUCCESS = "GET_PRICE_SUCCESS";
export const GET_PRICE_FAILED = "GET_PRICE_FAILED";

export const CREATE_ACCOUNT_HOME_INSURANCE_SUCCESS = "CREATE_ACCOUNT_HOME_INSURANCE_SUCCESS";
export const CREATE_ACCOUNT_HOME_INSURANCE_FAILED = "CREATE_ACCOUNT_HOME_INSURANCE_FAILED";

export const CREATE_CONTRACT_HOME_INSURANCE_SUCCESS = "CREATE_CONTRACT_HOME_INSURANCE_SUCCESS";
export const CREATE_CONTRACT_HOME_INSURANCE_FAILED = "CREATE_CONTRACT_HOME_INSURANCE_FAILED";

export const TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_SUCCESS = "TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_SUCCESS";
export const TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_FAILED = "TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_FAILED";
//-------------------CONTRACT ACTION TYPE--------------------------------------------

//LIST CONTRACTS
export const LIST_CONTRACTS_SUCCESS = "LIST_CONTRACTS_SUCCESS";
export const LIST_CONTRACTS_FAILED = "LIST_CONTRACTS_FAILED";
export const COUNT_RENEWING_CONTRACT = "COUNT_RENEWING_CONTRACT";

//HAS CURRENT CONTRACTS
export const HAS_CURRENT_HOME_CONTRACT = "HAS_CURRENT_HOME_CONTRACT";
export const HAS_CURRENT_MULTIMEDIA_CONTRACT = "HAS_CURRENT_MULTIMEDIA_CONTRACT";
export const HAS_CURRENT_HEALTH_MOBILITY_CONTRACT = "HAS_CURRENT_HEALTH_MOBILITY_CONTRACT";

//DOWNLOAD CONTRACT FILE
export const DOWNLOAD_CONTRACT_FILE_SUCCESS = "DOWNLOAD_CONTRACT_FILE_SUCCESS";
export const DOWNLOAD_CONTRACT_FILE_FAILED = "DOWNLOAD_CONTRACT_FILE_FAILED";

//DOWNLOAD GUARANTEES TABLE
export const DOWNLOAD_GUARANTEES_TABLE_SUCCESS =
  "DOWNLOAD_GUARANTEES_TABLE_SUCCESS";
export const DOWNLOAD_GUARANTEES_TABLE_FAILED =
  "DOWNLOAD_GUARANTEES_TABLE_FAILED";

// GET DOCUMENT
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILED = "GET_DOCUMENT_FAILED";

// CLAIMS
export const GET_ALL_CLAIMS_SUCCESS = "GET_ALL_CLAIMS_SUCCESS";

// DETAIL BY COUNTRY AND RAGE DATE HEALTH MOBILITY ACTION TYPE
export const DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_SUCCESS =
  "DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_SUCCESS";
export const DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_FAILED =
  "DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_FAILED";

// DETAIL MULTIMEDIA ACTION TYPE
export const DETAIL_MULTIMEDIA_SUCCESS =
"DETAIL_MULTIMEDIA_SUCCESS";
export const DETAIL_MULTIMEDIA_FAILED =
"DETAIL_MULTIMEDIA_FAILED";

// LIST PRICE MULTIMEDIA
export const LIST_PRICE_SUCCESS = "LIST_PRICE_SUCCESS";
export const LIST_PRICE_FAILED = "LIST_PRICE_FAILED";

// CONTRACT MULTIMEDIA

export const CREATE_CONTRACT_MULTIMEDIA_INSURANCE_SUCCESS = "CREATE_CONTRACT_MULTIMEDIA_INSURANCE_SUCCESS";
export const CREATE_CONTRACT_MULTIMEDIA_INSURANCE_FAILED = "CREATE_CONTRACT_MULTIMEDIA_INSURANCE_FAILED";

// LIST OFFERS
export const OFFER_LIST_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_LIST_FAILED = "OFFER_LIST_FAILED";
