import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  logo: {
    height: '60px',
    marginRight: '15px'
  },

}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [t, i18n] = useTranslation('common');

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="transparent"
      position="initial"
    >
      <Toolbar>
        <RouterLink to="/">
          <img className={classes.logo} alt="logo" src="/images/logos/logo-blue.png" />
        </RouterLink>

      </Toolbar>
      <div>
        {/*<h1>{t('login.title')}</h1>*/}
        {/*<button onClick={() => i18n.changeLanguage('fr')}>fr</button>
        <button onClick={() => i18n.changeLanguage('en')}>en</button>*/}
      </div>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
