import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validateRegisterCsUser } from "../../redux/actions/validateRegisterIsActions";
import PropTypes from "prop-types";

export const ValidateRegisterCs = (props) => {
  const { history } = props;

  const query = new URLSearchParams(useLocation().search);

  const dispatch = useDispatch();

  const processUrl = () => {
    const params = {
      token: query.get("token"),
    };
    if (validateParams(params)) {
      dispatch(validateRegisterCsUser({ userIs: params, history }));
    } else {
      history.push("/not-found");
    }
  };

  useEffect(function () {
    processUrl();
  });

  const validateParams = (params) => {
    return !!params.token;
  };

  return <React.Fragment></React.Fragment>;
};

ValidateRegisterCs.propTypes = {
  history: PropTypes.object,
};
