import { instance as axios } from './baseUrl'

export const addSchoolApi = (school) => axios.post('/schools', school);

export const editSchoolApi = (id, school) => axios.post(`schools/${id}/update`, school);

export const getSchoolByNameApi = (name) => axios.get( `/security/school/${name}`);

export const getSchoolByStudentApi = () => axios.get( `/schools/my`);

export const listSchoolsApi = () => axios.get('/schools');

export const loadSchoolStudentsApi = (id, file) => axios.post(`schools/${id}/students`, file);
