import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';

const diagonalImg =  {

};
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "266px",
    backgroundColor: "white",
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: "0px",
    },
  },
  content: {
    height: '92%',
    display: 'flex',
    zIndex: "1",
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto'
  },
  img: {
    display: "flex",
    height: "100%",
    width: "82%",
    zIndex: "-1",
    position: "absolute",
    opacity: "0.1",
    
  },
  diagonalImgLeft: {
    display: "flex",
    width: "80%",
    opacity: "0.16",
    height:  "86.9%",
    zIndex: "0",
    position: "absolute",
    marginTop: "100px",
    right: "0px",
    clipPath: "polygon(50% 0, 100% 0, 100% 100%, 0 100%)",
    bottom: "0",
    backgroundSize: "100%",
    backgroundPosition: "left bottom",
    [theme.breakpoints.between("xs", "sm")]: {
      display:"none",
      backgroundSize: "156vh",
      position: "fixed",
      height:  "80%",
    },
    
  },
  diagonalImgRight: {
    display: "flex",
    width: "80%",
    opacity: "0.16",
    height:  "86.9%",
    zIndex: "0",
    position: "absolute",
    marginTop: "100px",
    clipPath: "polygon(0% 0, 50% 0, 100% 100%, 0 100%)",
    left: "266px",
    bottom: "0",
    backgroundSize: "100%",
    backgroundPosition: "left bottom",
    [theme.breakpoints.between("xs", "md")]: {
      display:"none",
      backgroundSize: "156vh",
      left: "0px",
      position: "fixed",
      height:  "80%",
    },
  }
}));

const Main = props => {
  const { children, image, imageStyle, direction } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true
  });
  const isMobile = useMediaQuery('(min-width:1200px)');
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;


  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://js-na1.hs-scripts.com/5696105.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className={clsx({ [classes.root]: true, [classes.shiftContent]: isDesktop })}>
      {image != null && <div style={{backgroundImage: 'url('+image+')'}} className={imageStyle === 'diag' ?
        ( direction === 'left' ?  classes.diagonalImgLeft : classes.diagonalImgRight ) :
          classes.img} alt={""}/> }
      <Topbar onSidebarOpen={ handleSidebarOpen } />
      {isMobile ? (      
      <Sidebar onClose={ handleSidebarClose }
               open={ true }
               variant={ 'persistent'}/>
      ) : (
      <Sidebar onClose={ handleSidebarClose }
               open={ openSidebar }
              variant={ 'temporary'}/>
      )}

      <main className={classes.content}> {children}
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  imageStyle: PropTypes.string,
  direction: PropTypes.string,
};

export default Main;
