import { toastr } from "react-redux-toastr";
import * as actionTypes from "./actionTypes";
import { appLoading, appSuccess, appError } from "./appActions";
import { getCurrentContracts, getRenewingContracts, postRenewingContracts, contracts, getDoc, getDocByStudent, uploadContractHealthMobility, updateContractDocumentApi, updateContractAPI } from "../../api/contractApi";
import fileDownload from "js-file-download";

export const getContractsSuccess = (data) => ({
  type: actionTypes.LIST_CONTRACTS_SUCCESS,
  payload: data,
});

export const getContractsFailed = (error) => ({
  type: actionTypes.LIST_CONTRACTS_FAILED,
  payload: error,
});

export const getDocumentSuccess = (data) => ({
  type: actionTypes.GET_DOCUMENT_SUCCESS,
  payload: data,
});

export const getDocumentFailed = (error) => ({
  type: actionTypes.LIST_CONTRACTS_FAILED,
  payload: error,
});

export const getContracts = (payload) => (dispatch) => {
  contracts(payload.token)
    .then((response) => {
      const { data } = response;

      let listContractsNotArchived = [];
      data.data.map((value) => {
        if (value.document) {
          if (value.document.isArchived !== 1) {
            listContractsNotArchived.push(value);
          }
        } else {
          listContractsNotArchived.push(value);
        }
      });
      dispatch(appSuccess());
      dispatch(getContractsSuccess({ status: data.status, data: listContractsNotArchived }));
      // dispatch(getContractsSuccess(data));

    })
    .catch((error) => {
      dispatch(getContractsFailed(error));
      dispatch(appError());
      if (error && error.reponse && error.reponse.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
      }
    });
};

export const getContractsByStudents = (payload) => (dispatch) => {
  dispatch(appLoading());
  getDocByStudent({ student: payload })
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(getContractsSuccess(data));
    })
    .catch((error) => {
      dispatch(getContractsFailed(error));
      dispatch(appError());
      if (error && error.reponse && error.reponse.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
      }
    });
};

export const updateContract = (payload) => (dispatch) => {
  dispatch(appLoading());
  updateContractAPI(payload)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(getContractsSuccess(data));
    })
    .catch((error) => {
      dispatch(getContractsFailed(error));
      dispatch(appError());
      if (error && error.reponse && error.reponse.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
      }
    });
};


export const uploadContractHM = (payload) => (dispatch) => {
  dispatch(appLoading());
  const { data } = payload;
  const { currentStudent } = payload;

  uploadContractHealthMobility(data)
    .then((response) => {
      dispatch(getContractsByStudents(currentStudent));
      dispatch(appSuccess);
      toastr.success("INFO", "Contract enregistré");
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};

export const updateContractDocument = (payload) => (dispatch) => {
  dispatch(appLoading());
  const { data } = payload;
  const { currentStudent } = payload;

  updateContractDocumentApi(data)
    .then((response) => {
      dispatch(getContractsByStudents(currentStudent));
      dispatch(appSuccess);
      toastr.success("INFO", "Contract enregistré");
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};


export const getDocument = (payload) => (dispatch) => {
  const { id, filename } = payload;
  getDoc(id)
    .then((response) => {
      const { data } = response;
      dispatch(getDocumentSuccess(data));
      fileDownload(data, filename);
    })
    .catch((error) => {
      dispatch(getDocumentFailed(error));
      dispatch(appError());
      if (error && error.reponse && error.reponse.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
      }
    });
};

export const updateCurrentContracts = () => (dispatch) => {
  getCurrentContracts().then((response) => {
    const currentContracts = response.data.data;
    // dispatch(appSuccess());
    if (currentContracts[0] !== 'none') {
      currentContracts.forEach((contract) => {
        if (contract.insuranceType === "Multimédia") {
          dispatch({
            type: actionTypes.HAS_CURRENT_MULTIMEDIA_CONTRACT,
            payload: { hasCurrentMultimediaContract: true },
          });
        }
        if (contract.insuranceType === "Santé/Mobilité") {
          dispatch({
            type: actionTypes.HAS_CURRENT_HEALTH_MOBILITY_CONTRACT,
            payload: { hasCurrentHealthContract: true },
          })
        }
        else if (contract.insuranceType === "Habitation") {
          dispatch({
            type: actionTypes.HAS_CURRENT_HOME_CONTRACT,
            payload: { hasCurrentHomeContract: true },
          });
        }
      })
    }
  }).catch((error) => {
    dispatch(appError());
    if (error && error.reponse && error.reponse.data) {
      toastr.error(
        "ERROR",
        error.response.data.message + ". " + error.message
      );
    } else {
      toastr.error("ERROR", "Une erreur est survenue veuillez vous reconnecter");
    }
  });
};

export const getCountRenewingContract = () => (dispatch) => {
  dispatch(appLoading());

  getRenewingContracts().then((response) => {
    const renewingContracts = response.data.data;
    dispatch(appSuccess());

    let count = 0;
    if (renewingContracts[0] !== 'none') {
      renewingContracts.forEach((contract) => {
        count = count + 1;
      })
    }
    dispatch({
      type: actionTypes.COUNT_RENEWING_CONTRACT,
      payload: { countRenewingContract: count },
    });

  }).catch((error) => {
    dispatch(appError());
    if (error && error.reponse && error.reponse.data) {
      toastr.error(
        "ERROR",
        error.response.data.message + ". " + error.message
      );
    } else {
      toastr.error("ERROR", "Une erreur est survenue veuillez vous reconnecter");
    }
  });
};

export const renewingContract = () => (dispatch) => {
  dispatch(appLoading());

  postRenewingContracts().then((response) => {
    dispatch(appSuccess());
    dispatch(getCountRenewingContract());

  }).catch((error) => {
    dispatch(appError());
    if (error && error.reponse && error.reponse.data) {
      toastr.error(
        "ERROR",
        error.response.data.message + ". " + error.message
      );
    } else {
      toastr.error("ERROR", "Une erreur est survenue veuillez vous reconnecter");
    }
  });
};