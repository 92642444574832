import * as actionTypes from '../actions/actionTypes'

const initialState = {
   data: null
}

export const confirmationRegisterIs = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONFIRMATION_REGISTER_IS_SUCCESS:
            const { data } = action.payload
            return { ...state, data }
        case actionTypes.CONFIRMATION_REGISTER_IS_FAILED:
            return { ...state, userIs: null, error: action.payload }
        default:
            return state
    }
}

