import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

export const PublicRoute = (props) => {
  const { layout: Layout, component: Component, image, ...rest } = props

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout image={image}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  image : PropTypes.string,
}

export default PublicRoute
