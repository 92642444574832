import * as actionTypes from './actionTypes'
import { appLoading, appSuccess, appError } from './appActions'
import { confirmationRegisterIsApi } from '../../api/authApi'
import {toastr} from "react-redux-toastr";
import LocalStorageService from "../../services/LocalStorageService";
import {authSuccess} from "./authActions";

export const confirmationRegisterIsSuccess = (data) => ({
  type: actionTypes.CONFIRMATION_REGISTER_IS_SUCCESS,
  payload: data,
})

export const confirmationRegisterIsFailed = (error) => ({
  type: actionTypes.CONFIRMATION_REGISTER_IS_FAILED,
  payload: error,
})

export const confirmationRegisterIsUser = (payload) => (dispatch) => {
  const { user, history } = payload;
   dispatch(appLoading());
    confirmationRegisterIsApi(user)
    .then((response) => {
      const { data } = response;
      const { token, refresh_token } = data;
      const service = LocalStorageService.getService();
      service.setToken({ token, refresh_token });
      dispatch(appSuccess());
      dispatch(authSuccess(data));
      history.push('/home');
      toastr.success('INFO', 'Bienvenue chez StudCorp');
      dispatch(appSuccess());
      dispatch(confirmationRegisterIsSuccess(data));
      history.push('/home');
    })
    .catch((error) => {
      dispatch(confirmationRegisterIsFailed(error));
      dispatch(appError());
      if(error.response.data.message
          &&   error.response.data.errors.children.password.errors[0]){
         console.log('Error: ', error.response.data.message + ". "
             // iterar
             +  error.response.data.errors.children.password.errors[0]);
          toastr.error('ERROR', error.response.data.message + ". "
              // iterar
              +  error.response.data.errors.children.password.errors[0]);
        } else {
            console.log('Error: ', 'error connected to server');
             toastr.error('Error', 'Une erreur est survenue lors de la connexion au serveur');
            }
      // toastr.error('', error.message);
     //  history.push('/not-found');
    })
}