export const Roles  = {

  STUDENT_INCLUSIVE:  {text: 'Student Inclusive', value: 'ROLE_STUDENT_INCLUSIVE'},

  STUDENT_PARTNER:  {text: 'Student Partner', value: 'ROLE_STUDENT_PARTNER'},

  STUDENT_CLASSIC:  {text: 'Student Classic', value: 'ROLE_STUDENT_CLASSIC'},

  ADMIN:  {text: 'Administrator', value: 'ROLE_ADMIN'},
}
