import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {Grid, Typography, Slider, Button, Input} from "@material-ui/core";
import { useSelector } from 'react-redux';
import essential from "../../../assets/images/essential.png";
import essentialplus from "../../../assets/images/essentialplus.png";
import all from "../../../assets/images/all.png";
import allplus from "../../../assets/images/allplus.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import {detailMultimediaSuccess} from "../../../redux/actions/InsuranceActions";
import LocalStorageService from "../../../services/LocalStorageService";

import PropTypes from 'prop-types';



const useStyles = makeStyles(theme => ({
  container: {
     display: 'flex',
     padding: '50px',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly'
  },
  modalTitle : {
     textTransform: 'uppercase',
     color :'white',
     fontWeight: 'bold',
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
  },
  information : {
    color :'#4374a8',
    fontWeight: 'bold',
 },
  contentContainer: {
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.between("xs", "sm")]: {
      display: 'block',
    }
  },
  moreInformation:{
    color: "#4374a8",
    fontSize: "11px",
    fontStyle: "italic",
    textTransform: "none"
  },
  buttonOffer: {
    background: "#8ecece",
    height: "250px",
    width: "250px",
    borderRadius: "0px",
    fontSize: "20px",
    color: "white",
    "&:hover":{
      background: "#8ecece9e",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100px",
      width: "100px",
      "& .MuiTypography-h4":{
        fontSize: "12px!important",
      }
    },
 },
 sliderContent: {
  color: "#F7C548",
  "& .MuiSlider-markLabelActive":{
    color: "#F7C548",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    "& .MuiSlider-markLabel":{
      display: "none!important",
    }
  },
},
 priceElement: {
    color: "white",
    fontSize: "23px",
    lineHeight: "inherit",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "20px",
    },
  },
headerModal: {
    padding: "40px",
    background: "#F46036",
    position: "sticky",
    top: "0",
    zIndex: "9999",
    [theme.breakpoints.between("xs", "sm")]: {
      padding: '10px',
    }
  },
padding30: {
  paddingLeft: "30px",
  paddingRight: "30px",
},
paddingTop30: {
  paddingTop: "30px",
},
paddingTop20: {
  paddingTop: "20px",
  [theme.breakpoints.between("xs", "sm")]: {
    paddingTop: "0px",
  },
},
categoryTitle : {
  color :'#000000c7',
  "& h4":{
    fontWeight: "600",
    wordBreak: "break-all",
  }
},
categoryContent: {
  display: 'inline-flex',
  marginTop: "10px",
  marginBottom: "10px",
  borderRadius: "0px",
  height: "113px",
  paddingTop: "3%!important",
  paddingBottom: "3%!important",
  overflowY: "auto",
  "& h6": {
    height: "auto",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    marginTop: "0px",
    marginBottom: "0px",
    height: "80px",
  },
},
categoryContentButton: {
  marginTop: "10px",
  marginBottom: "20px",
},
submit: {
  padding: "10px",
  background: "white",
  color: "#F46036",
  fontSize: "18px",
  fontWeight: "500",
  boxShadow: "none",
  "&:hover":{
    background: "#F46036",
    color: "white",
  },
  [theme.breakpoints.between("xs", "md")]: {
    width: "100%",
    padding: "7px",
  },
},
colorDarkBlue: {
  background: "#2F6472",
  "& h6":{
    color: "white",
  },
  borderRight: "solid white 5px",
  borderLeft: "solid white 5px",
  [theme.breakpoints.between("xs", "sm")]: {
    borderRight: "solid white 0px",
    borderLeft: "solid white 0px",
  },
},
colorLightBlue: {
  background: "#8ECECE",
  "& h6":{
    color: "white",
  }
},
purchasePriceInput: {
  textAlign: "center",
  marginTop: "-20px",
  "& div": {
    width: "70px",
  }
},
cardMultimedia: {
  cursor: "pointer",
  border: "solid 1px #27343a",
  "&:hover":{
    mozTransform: "scale(1.06)",
    webkitTransform: "scale(1.06)",
    transform: "scale(1.06)",
    transition: "all 0.1s",

  }
},
cardMultimediaActive: {
  "& div":{
    background: "#F46036",
    border: "2px solid #F46036",
    "& h6":{
      color: "white",
    }
  }
},
contentCardMultimedia: {
  margin: "6px",
  padding: "10px",
},
priceCardMultimedia: {
  margin: "6px",
  padding: "10px",
  fontWeight: "600",
},
img: {
  width: "100%",
  height: "120px",
},
titleCardMultimedia: {
  margin: "6px",
  padding: "10px",
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: "12px",
},
noPaddingTop: {
  paddingTop: "0px!important",
},
middleItem:{
  display: "flex",
},
buttonContainer: {
  [theme.breakpoints.between("xs", "md")]: {
    padding: "7px",
    width: "100%",
  },
}
}));

 export const MultimediaOfferStep1 = ({setOpen, setContextOfferSelectionned}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {t} = useTranslation('common');
  const [value, setValue] = useState(300.00);
  const [valueLimit, setValueLimit] = useState([]);
  const [price, setPrice] = useState();
  const service = LocalStorageService.getService();

  const listMultimediaInsurance = useSelector((state) => state.multimediaInsurance.data);
  const multimediaInsurance = useSelector((state) => state.multimediaInsurance.dataDetails);


  const offerSelected = (insuranceProduct) => {
    dispatch(detailMultimediaSuccess({...insuranceProduct, active: true}));
  };

  const goMultimediaOffer = () =>{
    setContextOfferSelectionned("");
    const multimediaContractService = { ...multimediaInsurance, insuredDevice: {purchasePriceTTC: value }};
    service.setMultimediaContract( multimediaContractService );
    setOpen(false);
  }

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 350) {
      setValue(350);
    }
  };
  return (
    <>
      <Grid container className={classes.headerModal}>
          <Grid item align="center" justify="center" alignItems="center" className={[classes.middleItem]} xs={4}>
            <Typography variant="h2" className={classes.priceElement}>
            {t('insurance.multimedia.totalCost')} {multimediaInsurance.unit_amount / 100} {t('insurance.multimedia.€perMonth')} 
            </Typography>
          </Grid>
          <Grid item xs={5} className={[classes.middleItem]} align="center" justifyContent="center" alignItems="center">
            <Typography variant="h2" className={classes.modalTitle}>
              {t('insurance.multimedia.multimediaOffer')}
            </Typography>
          </Grid>
          <Grid className={[classes.middleItem, classes.buttonContainer]} item align="center" justify="center" alignItems="center" md={12} lg={3}>
            <Button className={classes.submit} color="primary"
              disabled={listMultimediaInsurance.length === 0 ? true : false}
              size="large"
              type="button"
              onClick={goMultimediaOffer}
              variant="contained" >
              {t('insurance.multimedia.subscribe')}
            </Button>
          </Grid>
       </Grid>
       <Grid container className={[classes.padding30, classes.paddingTop30, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.formula')}
          </Typography>
        </Grid>
      </Grid>
      {multimediaInsurance !== null && 
      <>
      <Grid container className={classes.padding30} spacing={2} alignItems="center">
      <Grid item xs={12} sm={2} lg={3} >
      </Grid>
      {listMultimediaInsurance.map((insuranceProduct) => {

       return (
       <Grid onClick={() => offerSelected(insuranceProduct) } item xs={12} sm={4} lg={3} className={multimediaInsurance.condition === insuranceProduct.condition ? classes.cardMultimediaActive : ""} >
         <div className={[classes.cardMultimedia]}>
           <div>
             {insuranceProduct.condition === "Essentiels hors tel" && <img className={classes.img} src={essential}></img>}
             {insuranceProduct.condition === "Tous produit hors tel" && <img className={classes.img} src={all}></img>}
             {insuranceProduct.condition === "Essentiels + tel" && <img className={classes.img} src={essentialplus}></img>}
             {insuranceProduct.condition === "Tous produit + tel" && <img className={classes.img} src={allplus}></img>}
           </div>
           <Typography variant="h6" className={classes.priceCardMultimedia}>
             {(insuranceProduct.unit_amount / 100)} {t('insurance.multimedia.€perMonth')} {t('insurance.multimedia.WithoutLimit')}
           </Typography>
           <Typography variant="h6" className={classes.titleCardMultimedia}>
             {insuranceProduct.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.title')}
             {insuranceProduct.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.title')}
             {insuranceProduct.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.title')}
             {insuranceProduct.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.title')}
           </Typography> 
         </div>
       </Grid>)
      })}   
      </Grid> 
      {multimediaInsurance.condition === "Essentiels + tel" || multimediaInsurance.condition === "Tous produit + tel" ? 
      (
        <>
          <Grid container className={[classes.padding30, classes.paddingTop30, classes.categoryTitle]} spacing={2} alignItems="center">
            <Grid item align="left" justify="center" alignItems="left" xs={12}>
              <Typography variant="h4">
                {t('insurance.multimedia.purchasePrice')} {': ' + parseFloat(value).toFixed(2) + ' €'} 
              </Typography>
            </Grid>
            <Grid item align="left" className={classes.noPaddingTop} justify="center" alignItems="left" xs={12}>
              <Typography variant="h7">
                {t('insurance.multimedia.offer350')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.padding30} spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Slider
                className={classes.sliderContent}
                value={typeof value === 'number' ? value : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                max={350}
                min={0}
                marks={valueLimit}
              />
            </Grid>
            <Grid item xs={2} className={classes.purchasePriceInput}>
              <Input
                value={value}
                size="small"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }} 
              /> €
            </Grid>
          </Grid>
        </>
      ):(null)}
         </>
      }
      <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.listFurniture')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={[classes.padding30, classes.noPaddingTop]} spacing={2} alignItems="center">
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={12} xs={12}>
          <Typography variant="h6">
             {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.listFurniture')}
             {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.listFurniture')}
             {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.listFurniture')}
             {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.listFurniture')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.guarantee')}
          </Typography>
        </Grid>
      </Grid>
       <Grid container className={classes.padding30} spacing={2} alignItems="center">
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
             {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.guarantee1')}
             {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.guarantee1')}
             {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.guarantee1')}
             {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.guarantee1')}
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
          {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.guarantee2')}
             {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.guarantee2')}
             {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.guarantee2')}
             {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.guarantee2')}    
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.guarantee3')}
            {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.guarantee3')}
            {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.guarantee3')}
            {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.guarantee3')}    
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={[classes.padding30, classes.categoryTitle]} spacing={2} alignItems="center">
        <Grid item align="left" justify="center" alignItems="left" xs={12}>
          <Typography variant="h4">
            {t('insurance.multimedia.condition')}
          </Typography>
        </Grid>
      </Grid>
       <Grid container className={classes.padding30} spacing={2} alignItems="center">
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.condition1')}
            {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.condition1')}
            {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.condition1')}
            {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.condition1')} 
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.condition2')}
            {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.condition2')}
            {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.condition2')}
            {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.condition2')}
          </Typography>
        </Grid>
        <Grid className={[classes.categoryContent, classes.colorDarkBlue]} item align="center" justify="center" alignItems="center" sm={4} xs={12}>
          <Typography variant="h6">
            {multimediaInsurance.condition === "Essentiels hors tel" && t('insurance.multimedia.multimedia.essential.condition3')}
            {multimediaInsurance.condition === "Tous produit hors tel" && t('insurance.multimedia.multimedia.all.condition3')}
            {multimediaInsurance.condition === "Essentiels + tel" && t('insurance.multimedia.multimedia.essentialplus.condition3')}
            {multimediaInsurance.condition === "Tous produit + tel" && t('insurance.multimedia.multimedia.allplus.condition3')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">

      </Grid>
    </>
  );
 };

 MultimediaOfferStep1.propTypes = {
  setOpen: PropTypes.func,
  setContextOfferSelectionned: PropTypes.func,
};

 export default MultimediaOfferStep1;