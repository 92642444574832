import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles';
import {Grid, Typography, Button, Modal, Box} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import PhoneOfferStep1 from "./components/PhoneOfferStep1";
import MultimediaOfferStep1 from "./components/MultimediaOfferStep1";
import {getListPriceMultimediaInsurance, detailMultimediaSuccess} from "../../redux/actions/InsuranceActions";
import { useDispatch,useSelector } from 'react-redux';
import { CardLink, CardLinksData } from './components/Cards';
import OfferStep2 from './components/OfferStep2';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 55px 0 55px',
    flexGrow: 1,
  },
  styleBox: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "65%",
    height: "90%",
    background: 'white',
    boxShadow: "24",
    overflowY: "auto",
    overflowX: "hidden",
    p: "4",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  container: {
     display: 'flex',
     padding: '50px',
     flexWrap: 'wrap',
     flexDirection: 'row',
     justifyContent: 'space-evenly',
     marginTop: "50px",
     [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "0px",
    },
  },
  categoryTitle : {
     textTransform: 'uppercase',
     color :'#d5ad19',
     fontWeight: 'bold',
     wordBreak: "break-all",
  },
  contentContainer: {
  },
  itemContent: {
    display: 'flex',
    marginTop: theme.spacing(6),
    width: '100%',
    [theme.breakpoints.between("xs", "sm")]: {
      display: 'block',
    }
  },
  moreInformation:{
    color: "#4374a8",
    fontSize: "11px",
    fontStyle: "italic",
    textTransform: "none"
  },
  buttonOffer: {
    background: "#8ecece",
    height: "250px",
    width: "250px",
    borderRadius: "0px",
    fontSize: "20px",
    color: "white",
    "&:hover":{
      background: "#8ecece9e",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "100px",
      width: "100px",
      "& .MuiTypography-h4":{
        fontSize: "12px!important",
      }
    },
 },
 selectedButton: {
  border: "solid 3px #4374A8",
},
noPadding: {
  padding: "0px",
},
}));

export const MultimediaInsurance = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const {t} = useTranslation('common');
  const [typeOfferSelectionned,setTypeOfferSelectionned] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const listMultimediaInsurance = useSelector((state) => state.multimediaInsurance.data);

  const onChangePhone = async () =>{
    setTypeOfferSelectionned("phone");
    await dispatch(getListPriceMultimediaInsurance({ type: "Telephonie", school: -999, recurring: "mensuel" }));
  }

  const onChangeMultimedia = async () =>{
    setTypeOfferSelectionned("multimedia");
    await dispatch(getListPriceMultimediaInsurance({ type: "Multimedia", school: -999, recurring: "mensuel" }));
    
  }

  useEffect(() => {
    dispatch(detailMultimediaSuccess({...listMultimediaInsurance[0], active: true}));
    if(typeOfferSelectionned !== null){
      setOpen(true);
    }
  }, [listMultimediaInsurance]);

  const [contextOfferSelectionned, setContextOfferSelectionned] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const getCardFilter = () => {
    let listCard = [];
    CardLinksData.map((cardLink) => {
      switch (cardLink.text) {
        case 't("app.offer-multimedia-card")':
          cardLink = { ...cardLink, text: t("app.offer-multimedia-card")};
          break; 
        case 't("app.offer-smartphone-card")':
          cardLink = { ...cardLink, text: t("app.offer-smartphone-card")};
          break; 
        default:
          break;                
      }      
      listCard.push(cardLink);
    }
    )
    return listCard;
  }
  return (
      <div className={classes.root}>
        {contextOfferSelectionned === null ? (

      <Grid container spacing={8}>
        <Grid item xs={12} align="center" justify="center" alignItems="center">
          <Typography variant="h2" className={classes.categoryTitle}>
            {t('insurance.multimedia.title')}
          </Typography>
        </Grid>     
        <Grid container spacing={4} className={classes.container}>
        {getCardFilter().map((card) =>
          ( <CardLink text={card.text}
                      image={card.image}
                      color={card.color}
                      icon={card.icon}
                      displayForCS={card.displayForCS}
                      key={card.link}
                      onClick={card.onClick === "onChangePhone" ? onChangePhone : onChangeMultimedia}
            /> )
        )}
        </Grid>
      </Grid>
        ) : (
          <OfferStep2 context={contextOfferSelectionned}/>
        )}
           <Modal
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={[classes.noPadding, classes.styleBox]} >
                {typeOfferSelectionned === "phone" && 
                  <PhoneOfferStep1 setOpen={setOpen} setContextOfferSelectionned={setContextOfferSelectionned} /> 
                }
                {typeOfferSelectionned === "multimedia" && 
                  <MultimediaOfferStep1 setOpen={setOpen} setContextOfferSelectionned={setContextOfferSelectionned} /> 
                }
              </Box>
            </Modal>
      </div>
  );
 };
