import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import {Modal, Box} from '@material-ui/core';
import validate from "validate.js";
import {resettingPasswordEmail} from "../../../redux/actions/studentsActions";

const constraints = (t) => ({
    email: {
      presence: { allowEmpty: false, message: t("validation.field.required") },
    },
  });

  
const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: theme.spacing(2),
    },
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "400",
        bgcolor: "background.paper",
        border: "2px solid white",
        background: "white",
        padding: "20px",
    },
}));

const ResetPasswordModal = (props) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const { open, setOpen } = props;
  const handleClose = () => setOpen(false);
  const schema = constraints(t);
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      email: "",
    },
    touched: {},
    errors: {},
    flag: true,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleResettingPassword = (event) => {

    event.preventDefault();
    setFormState((formState) => ({
      ...formState,
      touched: {
        email: true,
      },
    }));
    const {values} = formState;
    dispatch(resettingPasswordEmail(values));
    handleClose();
  };
  return (
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <form onSubmit={handleResettingPassword}>
        <Typography id="modal-modal-title" variant="h2" component="h2">
          {t("login-school.form.forgotpassword")}
        </Typography>
        <div className={classes.emailDomain}>
          <TextField
            className={classes.textField}
            error={hasError("email")}
            fullWidth
            helperText={hasError("email") ? formState.errors.email[0] : null}
            margin="normal"
            label={t("login-school.form.inputs.email")}
            name="email"
            onChange={handleChange}
            type="text"
            value={formState.values.email}
            variant="outlined"
            autoFocus
          />
        </div>
        <Button
          className={classes.submit}
          color="primary"
          disabled={!formState.isValid}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t("login-school.form.send-email")}
        </Button>
      </form>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
