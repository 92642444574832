import React, {useEffect, useState, setState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useLocation} from "react-router-dom";
import validate from "validate.js";
import Container from "@material-ui/core/Container";
import {useDispatch, useSelector} from "react-redux";
import {confirmationRegisterIsUser} from "../../redux/actions/confirmationRegisterIsActions";
import Paper from "@material-ui/core/Paper";
import {Topbar} from "../../components/layouts/Minimal/components";
import {AppBar, Toolbar} from "@material-ui/core";
import {useTranslation} from "react-i18next";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
//
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import DatePicker from '@material-ui/lab/DatePicker';

import Footer from "../../components/layouts/Main/components/Footer/Footer";
import moment from "moment";
import AutoCompleteAddress from "../../components/AutoCompleteAddress/AutoCompleteAddress";

const constraints = (t) => ({
  password: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
    format: {
      pattern: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      message: t('validation.field.password.incorrect')
    }
  },
  confirmPassword: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
    equality: {
      attribute: "password",
      message: t("validation.field.password.must.match"),
      comparator: function (v1, v2) {
        return v1 === v2;
      },
    },
  },
  civilite: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },
  birthday: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  },
  phoneNumber: {
    presence: {allowEmpty: false, message: t("validation.field.required")},
  }

});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  fieldset: {
    width: "100%",
  },
  date: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "15px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paperC: {
    padding: "5%",
    marginTop: "40px",
    boxShadow:
      "13px 8px 15px 1px rgba(101,168,130,0.5)",
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.green,
    fontWeight: "bold",
    width: "342px",
    textAlign: "center",
  },
  subtitle: {
    color: theme.palette.orange,
    fontWeight: "bold",
  },
  titleName: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center",
  },
  subtitleName: {
    marginTop: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center",
  },
  logo: {
    height: "60px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: "18px",
  },
  space: {
    width: "65px",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: "red",
  },
  helperError: {
    marginTop: "-10px",
    marginLeft: "14px",
  }
}));

export const ConfirmationRegisterIs = (props) => {
  const {history} = props;
  const [helperCivility, setHelperCivility] = useState('');
  const [helperAddress, setHelperAddress] = useState('');
  const [helperPhoneNumber, setHelperPhoneNumber] = useState('');
  const [address, setAddress] = useState({});

  const classes = useStyles();

  const dispatch = useDispatch();

  const {t} = useTranslation("common");

  const schema = constraints(t);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const userIsData = useSelector((state) => state.validateRegisterIs.data);
  const tokenValidate = useSelector(
    (state) => state.validateRegisterIs.tokenValidate
  );

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const getDataUserValidateSchool = () => {
    if (userIsData && userIsData.school) {
      return ' X ' + userIsData.school.name;
    } else {
      return history.push('/not-found');
    }
  };

  const getDataUserValidateStudentsSchoolName = () => {
    if (userIsData && userIsData.school) {
      const user = userIsData.user;
      return ' ' + userIsData.school.studentsSchoolName;
    } else {
      return history.push('/not-found');
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema, {fullMessages: false});
    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {...formState.values, [event.target.name]: event.target.value},
      touched: {...formState.touched, [event.target.name]: true},
    }));
  };

  useEffect(() => {
    if (hasError('civilite')) {
      setHelperCivility(t("validation.field.required"));
    } else {
      setHelperCivility('');
    }
    if (hasError('phoneNumber')) {
      setHelperPhoneNumber(t("validation.field.required"));
    } else {
      setHelperPhoneNumber('');
    }


  }, [formState.touched, formState.errors]);

  const hasError = (field) => {
    if (formState.errors[field]) {
      if (formState.touched[field]) {
        return true;
      }
    }
    return false;
  }

  const handleRegisterIs = (event) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      touched: {
        password: true,
        confirmPassword: true,
        birthday: true,
        phoneNumber: true,
        civilite: true,
        numberAndStreet: true,
        postalCode: true,
        city: true,
        country: true
      },
    }));
    hasError('civilite');

    const addressValid = validateAddress();
    if (addressValid) {
      setHelperAddress('');
    } else {
      setHelperAddress(t("validation.field.required"));
    }

    if (Object.keys(formState.errors).length == 0 && addressValid) {
      const {values} = formState;
      const formatDate = moment(values.birthday.replace(/-/g, "/")).format(
        "DD/MM/YYYY"
      ) || '';

      const params = {
        token: userIsData.token,
        password: values.password,
        civility: values.civilite,
        birthday: formatDate,
        phoneNumber: values.phoneNumber
      };

      params.address = address;
      dispatch(confirmationRegisterIsUser({user: params, history}));
    }
  };

  const validateAddress = () => {
    let valid = false;
    if (address.hasOwnProperty('street') && address.hasOwnProperty('postalCode')
      && address.hasOwnProperty('city') && address.hasOwnProperty('country')) {
      return address.street.length > 0 && address.postalCode.length > 0
        && address.city.length > 0 && address.country.length > 0;
    }

    return valid;
  }

  useEffect(() => {
    if (validateAddress()) {
      setHelperAddress('');
    }
  }, [address]);

  return (
    <Container component="main" maxWidth="lg">
      <Toolbar className={classes.headerContainer}>
        <RouterLink to="/">
          <img
            className={classes.logo}
            alt="logo"
            src="/images/logos/logo-blue.png"
          />
        </RouterLink>
        <div>
          <Typography className={classes.title} variant="h1">
            {t("app.name")}
            {getDataUserValidateSchool()}
          </Typography>
          <Typography className={classes.subtitle} variant="h3" gutterBottom>
            {t("confirmation-pass.subtitle")}
          </Typography>
        </div>
        <div className={classes.space}/>
      </Toolbar>

      <div className={classes.paper}>
        <Paper elevation={5} className={classes.paperC}>
          <Typography className={classes.titleName} variant="h1" gutterBottom>
            {t("confirmation-pass.form.title")}
            {getDataUserValidateStudentsSchoolName()}
          </Typography>
          <Typography
            className={classes.subtitleName}
            variant="h1"
            gutterBottom
          >
            {t("confirmation-pass.form.subtitle")}
          </Typography>
          <Container component="main" maxWidth="xs">
            <form className={classes.form} onSubmit={handleRegisterIs}>
              <FormLabel component="legend">
                {t("confirmation-pass.form.inputs.civilite")}
              </FormLabel>
              <RadioGroup
                aria-label={t("confirmation-pass.form.inputs.civilite")}
                name="civilite"
                value={formState.values.civilite || ""}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio/>}
                  label={t("confirmation-pass.form.inputs.civilite-option1")}
                />
                <FormControlLabel
                  value="Mme"
                  control={<Radio/>}
                  label={t("confirmation-pass.form.inputs.civilite-option2")}
                />
              </RadioGroup>
              <FormHelperText className={classes.error}>{helperCivility}</FormHelperText>


              <FormLabel component="legend">
                {t("confirmation-pass.form.inputs.brithday")}
              </FormLabel>
              <TextField
                id="birthday"
                name="birthday"
                error={hasError("birthday")}
                helperText={
                  hasError("birthday")
                    ? formState.errors.birthday[0]
                    : null
                }
                value={formState.values.birthday}
                type="date"
                className={classes.date}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                  placeholder={t("registration-partner.form.inputs.phoneNumber")}
                  className={classes.textField1}
                  error={hasError("phoneNumber")}
                  country="FR"
                  fullWidth
                  helperText={hasError("phoneNumber") ? formState.errors.phoneNumber[0] : null}
                  margin="normal"
                  label={t("registration-partner.form.inputs.phoneNumber")}
                  name="phoneNumber"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phoneNumber || ""}
                  variant="outlined"
                />
              <FormHelperText className={classes.error}>{helperPhoneNumber}</FormHelperText>
              <AutoCompleteAddress
                setAddress={setAddress}/>
              <FormHelperText className={[classes.error, classes.helperError]}>{helperAddress}</FormHelperText>

              <TextField
                className={classes.textField}
                error={hasError("password")}
                fullWidth
                helperText={
                  hasError("password") ? formState.errors.password[0] : null
                }
                margin="normal"
                label={t("confirmation-pass.form.inputs.password")}
                name="password"
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                value={formState.values.password || ""}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                className={classes.textField}
                error={hasError("confirmPassword")}
                fullWidth
                helperText={
                  hasError("confirmPassword")
                    ? formState.errors.confirmPassword[0]
                    : null
                }
                margin="normal"
                label={t("confirmation-pass.form.inputs.passwordConfirm")}
                name="confirmPassword"
                onChange={handleChange}
                type={showPassword2 ? "text" : "password"}
                value={formState.values.confirmPassword || ""}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                className={classes.submit}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t("confirmation-pass.form.confirmBtn")}
              </Button>
            </form>
          </Container>
        </Paper>
      </div>
      <Footer/>
    </Container>
  );
};
