import { instance as axios } from "./baseUrl";

export const login = (user) => axios.post("/security/login", { ...user });

export const validateRegisterIsApi = (userIs) =>
  axios.post("security/email/confirm", { ...userIs });

export const validateRegisterPsApi = (userIs) =>
  axios.post("security/email/confirm", { ...userIs });

export const validateRegisterCsApi = (userIs) =>
  axios.post("security/email/confirm", { ...userIs });

export const confirmationRegisterIsApi = (userIs) =>
  axios.post("security/student/password", { ...userIs });

export const resettingPasswordApi= (values) => {
  return axios.post("/forgot_password/" + values.token, { ...values }, {
    headers: { "Content-Type": "application/json"
  },
  });
}
