import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { app } from "./appReducer";
import { auth } from "./authReducer";
import { validateRegisterIs } from "./validateRegisterIsReducer";
import { confirmationRegisterIs } from "./confirmationRegisterIsReducer";
import { schoolsReducer } from "./schoolsReducer";
import { studentsReducer } from "./studentsReducer";
import { insuranceReducer, houseInsuranceReducer, multimediaInsuranceReducer } from "./insuranceReducer";
import { contracts } from "./contractsReducer";
import { claims } from "./claimsReducer";
import { offersReducer } from "./offersReducer";

const rootReducer = combineReducers({
  app,
  auth,
  validateRegisterIs,
  confirmationRegisterIs,
  school: schoolsReducer,
  students: studentsReducer,
  insurance: insuranceReducer,
  toastr: toastrReducer,
  contracts,
  claims,
  houseInsurance: houseInsuranceReducer,
  multimediaInsurance: multimediaInsuranceReducer,
  offersInsurance: offersReducer,

});

export default rootReducer;
