import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from 'i18next';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from "./Language";
import common_fr from './assets/translations/fr/common.json';
import common_en from './assets/translations/en/common.json';
import common_ch from './assets/translations/ch/common.json';
import common_ar from './assets/translations/ar/common.json';

let defaultLanguage = Language.FR;
 
// the translations
const resources = {
  en: {
    common: common_en
  },
  fr: {
    common: common_fr
  },
  ch: {
    common: common_ch
  },
  ar: {
    common: common_ar
  }
};

i18next
.use(LanguageDetector)
.use(initReactI18next).init({
  interpolation: {escapeValue: false},  // React already does escaping
  lng: defaultLanguage,  // language to use
  resources
}).then(r => true);

const tagManagerArgs = {
  gtmId: 'GTM-KBD39ZT9'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App/>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
