import React, { useState, useEffect } from "react";
import { useRouteMatch, NavLink as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { Toolbar } from "@material-ui/core";
import Login from "./Form/Login";
import Registration from "./Form/Registration";
import Footer from "../../../../components/layouts/Main/components/Footer";
import Link from "@material-ui/core/Link";
import Lang from "../../../../Lang";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  paperC: {
    width:"70%",
    [theme.breakpoints.between("xs", "sm")]: {
      width:"100%",
      "& form":{
        width: "100%",
        borderLeft: "none",
        paddingLeft: "0px",
      },
      "& h1":{
        fontSize: "22px",
        marginBottom: "15px",
      },
    },
    padding: "5%",
    marginTop: "40px",
    boxShadow:
      "13px 8px 15px 1px rgba(101,168,130,0.5)",
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.green,
    fontWeight: "bold",
  },
  subtitle: {
    color: theme.palette.orange,
    fontWeight: "bold",
  },
  titleName: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center",
  },
  subtitleName: {
    marginTop: theme.spacing(4),
    fontWeight: "bold",
    color: theme.palette.green,
    textAlign: "center",
  },
  headerContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      "& h3":{
        fontSize: "22px",
      },
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: "18px",
  },
  logo: {
    height: "60px",
  },
}));

const LoginRegistrationPageClasic = (props) => {
  const [schoolNameParam, setSchoolNameParam] = useState("");
  const { school, dispatch, history } = props;

  const classes = useStyles();

  const match = useRouteMatch();

  const { t } = useTranslation("common");

  const processUrl = () => {};

  const getWithSchoolName = () => {
    if (school.dataDetails && school.dataDetails.name) {
      return " X " + school.dataDetails.name;
    }
    return "";
  };

  const getWithStudentsSchoolName = () => {
    if (school.dataDetails && school.dataDetails.studentsSchoolName) {
      return " " + school.dataDetails.studentsSchoolName;
    }
    return "";
  };

  return (
    <Container component="main" maxWidth="xl">
      <div style={{position: "absolute",right: "3px", top: "3px"}}>
        <Lang />
      </div>
      <Toolbar className={classes.headerContainer}>
        <RouterLink to="/">
          <img
            className={classes.logo}
            alt="logo"
            src="/images/logos/logo-blue.png"
          />
        </RouterLink>
        <div>
          <Typography className={classes.title} variant="h1">
            {t("app.name")}
          </Typography>
          <Typography className={classes.subtitle} variant="h3" gutterBottom>
            {t("login-school.subtitle")}
          </Typography>
        </div>
        <div className={classes.space} />
      </Toolbar>
      <div className={classes.paper}>
        <Paper elevation={5} className={classes.paperC}>
          <Login {...props} />
          <Registration />
        </Paper>
      </div>
      <Footer/>
    </Container>
  );
};

LoginRegistrationPageClasic.propTypes = {
  schoolDetails: PropTypes.func,
  school: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  school: state.school,
});

export default connect(mapStateToProps)(LoginRegistrationPageClasic);
