import decode from 'jwt-decode'
import React from "react";
import {redirectStripeAccountManagement, redirectPaymentHealth_mobility, redirectPaymentHome_insurance, redirectPaymentMultimedia_insurance} from "../api/InsuranceApi";
import {toastr} from "react-redux-toastr";
import { appLoading, appSuccess, appError } from "../redux/actions/appActions";

export const isAuthenticated = () => {
  const token = localStorage.getItem('token')
  let isValid = true
  try {
    isValid = decode(token);
    isValid = {
       data: isValid,
       token: token
    }
  } catch (e) {
    return false
  }
  return isValid
}

export const isAdminUser = (user) => {
  if (user.roles) {
      return user.roles[0] === 'ROLE_ADMIN';
  } else {
     return false;
  }
}

export const validateAccessUser = (view, rol) => {
  return view.roles.some(item => item === rol);
}

export const redirectPaymentPageMobility = (data) => {

  redirectPaymentHealth_mobility(data)
    .then((response) => {
      const { data } = response;
      window.location.href = data;
    })
    .catch((error) => {
      if(error && error.response && error.response.data){
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ', error.response.data.message);
      } else {
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ','error connected to server');
      }
    })
}

export const redirectPaymentPageMultimedia = (data, dispatch) => {
  dispatch(appLoading());

  redirectPaymentMultimedia_insurance(data)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      window.location.href = data;
    })
    .catch((error) => {
      dispatch(appError());
      if(error && error.response && error.response.data){
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ', error.response.data.message);
      } else {
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ','error connected to server');
      }
    })
}

export const redirectPaymentPageHome = (data) => {
  console.log(data)
  redirectPaymentHome_insurance(data)
    .then((response) => {
      const { data } = response;
      window.location.href = data;
    })
    .catch((error) => {

      if(error && error.response && error.response.data){
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ', error.response.data.message);
      } else {
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ','error connected to server');
      }
    })
}

export const redirectAccountStripeManagement = (data, dispatch) => {
  dispatch(appLoading());

  redirectStripeAccountManagement(data)
    .then((response) => {
      dispatch(appSuccess());
      const { data } = response;
      window.location.href = data;
    })
    .catch((error) => {
      dispatch(appError());
      if(error && error.response && error.response.data){
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ', error.response.data.message);
      } else {
        toastr.error('ERROR', error.response.data.message);
        console.log('ERROR: ','error connected to server');
      }
    })
}
