import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { useDispatch } from "react-redux";
import { getDocument } from "../../../redux/actions/contractsActions";

const useStyles = makeStyles((theme) => ({
  docIcon: {
    fontSize: "4rem",
  },
  docContainer: {
    float: "left",
    marginTop: "1%",
    marginRight: "10px",
    display: "table",
    width: "150px",
    wordBreak: "break-all",
  },
}));

export const Document = (props) => {
  const { document } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClick(id, name) {
    dispatch(getDocument({ id: id, filename: name }));
  }

  return (
    <span>
      {document.name !== "" ? (
        <Grid
        container
        spacing={0}
        xs={2}
        className={classes.docContainer}
      >
        <Grid
          item
          onClick={() => handleClick(document.documentId, document.name)}
        >
          <Grid align="center">
            <DescriptionIcon className={classes.docIcon} />
          </Grid>
          <Grid align="center">
            <Typography variant="subtitle2">
              {document.name}
            </Typography>
          </Grid>
        </Grid>
        </Grid>
      ) : (
        <span></span>
      )}</span>
  );
};
