import React, { useEffect, useState } from "react";
import {makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import LocalStorageService from "../../services/LocalStorageService";

import {finishMultimediaDigitalFirm, finishHealthMobilityDigitalFirm} from "../../redux/actions/InsuranceActions";
import { useHistory } from "react-router";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
  },
  root2: {
    padding: "20px"
  },
  paper: {
    marginTop: theme.spacing(0),
    // display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    padding: "50px",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  paperC: {
    padding: "5%",
    marginTop: "40px",
  },
  stepTitle: {
    textTransform: "uppercase",
    color: "#2F6472",
    fontWeight: "bold",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  stepOneSutitle: {
    color: "#2F6472",
    fontStyle: "italic",
  },
  square: {
    border: "1px solid #2F6472",
  },
  icon: {
    width: 35,
    height: 35,
  },
  squareContainer: {
    width: 128,
    height: 128,
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    paddingTop: "10px"
  },
  break: {
    flexBasis: "100%",
    height: 0
  },
  squareLineTwo: {
    marginTop: "0.7%",
  },
  formText: {
    color: "#2F6472",
  },
  priceContainer: {
    marginTop: "2%",
  },
  incidentTable: {
    marginTop: "2%",
  },
  accountCreationOneLineContainer: {
    marginTop: "10px"
  },
  stepFourDivider: {
    backgroundColor: "#2F6472",
    height: "5px",
    marginTop: "20px",
    marginBottom: "20px"
  },
  categoryTitle: {
    color: "#2F6472",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginBottom: "45px",
  },
  formItem: {
    marginTop: "10px"
  },
  formCTA: {
    marginTop: "20px"
  },
  error: {
    color: "red"
  },
  stepSubtitle: {
    fontSize: "22px"
  },
  stepTwoSubtitle: {
    color: "#2F6472",
    fontStyle: "italic",
    textAlign: "center",
    width: "100%",
    marginTop: "10px"
  },
  step2Grid: {
    width: "100%"
  },
  step2Submit: {
    width: "48%"
  },
  step2Submit2: {
    width: "48%",
    marginLeft: "2%"
  },
  step2SubmitContainer: {
    marginTop: "20px"
  },
  datePickerInline: {
    marginTop: "20px",
    marginLeft: "10px !important",
    "& .MuiTextField-root": {
      margin: 0
    },
    "& .MuiInputBase-root": {
      padding: 0,
      margin: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10
      },
      "& .MuiInputBase-input": {
        padding: 15,
        paddingLeft: 0
      }
    }
  },
  datePickerContainer:{
    margin: "0px !important"
  },
  image:{
    width: "60px"
  },
  guaranteeTooltip:{
    fontSize: "11px !important"
  },
  ShowPricePerRoomate: {
    display: "block"
  },
  HidePricePerRoomate:{
    display: "none"
  },
  submitStep3:{
    width: "100%"
  },
  roomateFormContainer:{
    marginTop: "20px"
  },
  infoOnRoomateTitle:{
    marginBottom: "10px"
  },
  step4PayButtonContainer:{
    marginTop: "20px"
  },
  textFieldStep4:{
    marginTop: "10px"
  },
  accountCreationContainer:{
    marginTop: "10px"
  },
  accountCreationGender:{
    display: "block"
  }

}));
var alreadyEventListener = false;



export const SignView = () => {
  const query = new URLSearchParams(window.location.search);

  const {t} = useTranslation("common");
  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const service = LocalStorageService.getService();

  const dataMultimedia = service.getMultimediaContract();
  const [countApiCall, setCountApiCall] = useState(0);
  const dataHealth = service.getHealthMobilityContract();

  // if(!alreadyEventListener){
  //   window.addEventListener("message", function(data){
  //     let signedState = false;
  //     if(!data.data.hello){
  //       const dataJson = JSON.parse(data.data);
  //       signedState = dataJson.signed;
  //     }
  //     if(signedState){
  //       window.removeEventListener('message', function(data){ }, false);
  //       if(query.get("context") === "multimedia"){
  //           dispatch(finishMultimediaDigitalFirm({fields : {contractId: dataFirma.contractId, certificateId: dataFirma.certificateId}, history }));
  //       }
  //     }
  //   }, false);
  //   alreadyEventListener = true;
  // }

  useEffect(() => {
    // dispatch(getTokenDigitalFirmaHome_insuranceApi());
    if(query.get("context") === "multimedia" && countApiCall === 0){
      dispatch(finishMultimediaDigitalFirm({fields : {contractId: dataMultimedia.contractId, certificateId: dataMultimedia.certificateId}, history }));
      setCountApiCall(1);
    } else if (query.get("context") === "health" && countApiCall === 0) {
      dispatch(finishHealthMobilityDigitalFirm({fields : {contractId: dataHealth.contractId,healthMobilityContractId: dataHealth.healthMobilityContractId, healthMobilityCertificateId: dataHealth.healthMobilityCertificateId}, history }));
      setCountApiCall(1);
    }
  }, [dataMultimedia, dataHealth]);

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%'}}>
    </div>
  )
}
