import { instance as axios } from "./baseUrl";

export const registerPs = (values) =>
  axios.post("/security/register/student", values, {
    headers: { "Content-Type": "application/json" },
  });

export const registerCs = (values) =>
  axios.post("/security/register/student", values, {
    headers: { "Content-Type": "application/json" },
  });

export const resettingPasswordEmail = (values) =>
  axios.post("/forgot_password/", values, {
    headers: { "Content-Type": "application/json" },
  });

export const studentListApi = () => axios.get('/students/studentlist');

export const sendConfirmationMailApi = (values) =>
  axios.post("/students/sendregistrationmail", values, {
    headers: { "Content-Type": "application/json" },
  });